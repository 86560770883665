import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FirebaseService } from './firebase.service';
import {
    BehaviorSubject,
    Observable,
    Subject,
    catchError,
    combineLatest,
    first,
    from,
    map,
    of,
    shareReplay,
    switchMap,
    take,
    tap,
    timer,
} from 'rxjs';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { CustomerTrasformerService } from './customer-trasformer.service';
import { User } from '../model/user.modal';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { StripeService } from './stripe.service';
import { Customer } from '../model/customer.modal';
import { EventTrasformerService } from './event-trasformer.service';
import { BrandKit } from '../model/brandKit.modal';
import { parsePhoneNumber } from 'libphonenumber-js';
import { AttendeeTransformerService } from './attendee-transformer.service';
import { SelectItem } from '../shared/components/auto-complete/auto-complete.component';
import { Page } from '../model/page.modal';
import { EventData } from '../model/event-data.modal';
// import { AppEvent } from '../model/app.modal';
import { SessionList } from '../model/session.modal';
import { SessionModalService } from './session-modal.service';
import { FilestackService } from './filestack.service';
import { ScavengerService } from './scavenger.service';
import { PairingService } from './pairing.service';
import { CheckoutService } from './checkout.service';
import { DomainSettings } from '../model/senderSignature.modal';

const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 3600 * 1000;

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    allCustomers = [];
    saCustomerName$ = new BehaviorSubject('');
    $connectedEloqua = new Subject();
    isOwner!: boolean;
    currency: any;
    userId = localStorage.getItem('user_id') || this.Cookies.get('user_id');
    customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
    speakerList: any = [];
    attendeeList: any = [];
    public cacheSubscription$!: Observable<any> | null;
    allContributorList: any[] = [];

    constructor(
        private db: AngularFireDatabase,
        private Firebase: FirebaseService,
        private http: HttpClient,
        private Utilities: UtilityService,
        private customerTransformer: CustomerTrasformerService,
        private Cookies: CookieService,
        private stripe: StripeService,
        private utilityService: UtilityService,
        private eventTransformer: EventTrasformerService,
        private attendeeTrasnformer: AttendeeTransformerService,
        private sessionModalService: SessionModalService,
        private fileStack: FilestackService,
        private scavenger: ScavengerService,
        private paring: PairingService,
        private checkoutService: CheckoutService
    ) {}

    privateFields = [
        'is_paid',
        'is_professional_services_available',
        'is_professional_services_enabled',
        'is_professional_services_paid',
        'event_sec_password',
        'pairing_key',
        'promo_emailer_text',
        'promo_emailer_footer_text',
    ];

    getAllCustomers() {
        // const customersRef = `/customers`
        // return this.Firebase.getByRef(customersRef).pipe(first(), map((res: any) => {
        //     const retArr: any = [];
        //     if (res) {
        //         _.forOwn(res, (val, key) => {
        //             retArr.push({ ...val, id: key })
        //         })
        //     }
        //     return retArr;
        // }))
        if (!this.allCustomers.length) {
            return (
                this.http
                    .get(`${environment.fcfApiUrl}/v2api/customers-list`)
                    // return this.http.get(`http://s/dev-hellocrowd/us-central1/api/customers-list`)
                    .pipe(
                        tap((data: any) => {
                            this.allCustomers = data;
                        })
                    )
            );
        } else {
            return of(this.allCustomers);
        }
    }

    getEvent(customerId: string, eventId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/events/${eventId}`).pipe(
            take(1),
            map((value: any) => {
                // const privateData = _.pick(value, 'private_data');
                // const publicData = _.omit(value, ['private_data', 'app_id']);
                // const event: any = _.merge(publicData, privateData.private_data);
                // event.id = eventId;

                // return event;

                return value
                    ? this.customerTransformer.transformSingle(
                          this.customerTransformer.transform(value, customerId, eventId)
                      )
                    : null;
            })
        );
    }

    getCommonEventData(customerId: string, eventId: string) {
        return combineLatest([
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/time_format`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/time_zone`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/title`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/user_id`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/status`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/start_date_time_unix`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/end_date_time_unix`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/app_id`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/date_format`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/isPublished`),
        ]).pipe(
            take(1),
            map((resp: any) => {
                const value = {
                    time_format: resp[0],
                    time_zone: resp[1],
                    title: resp[2],
                    user_id: resp[3],
                    status: resp[4],
                    start_date_time_unix: resp[5],
                    end_date_time_unix: resp[6],
                    app_id: resp[7],
                    date_format: resp[8],
                    isPublished: resp[9],
                };
                // const privateData = _.pick(value, 'private_data');
                // const publicData = _.omit(value, ['private_data', 'app_id']);
                // const event: any = _.merge(publicData, privateData.private_data);
                // event.id = eventId;

                // return event;

                return value
                    ? this.customerTransformer.transformSingle(
                          this.customerTransformer.transform(value, customerId, eventId)
                      )
                    : null;
            })
        );
    }

    getSubscription(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/subscription`);
    }

    getStripeId(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/stripeId`);
    }
    getDefaultTimeZone(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/defaultTimeZone`);
    }

    getDefaultCurrency(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/defaultCurrency`) as Observable<string>;
    }
    updateEventFb(data: any, customerId: string, eventId: string) {
        const eventData = this.customerTransformer.prepareData(data);

        return this.updateEventData(eventData, data, customerId, eventId);
    }

    updateEventData(eventData: any, data: any, customerId: string, eventId: string) {
        const listRef = `customers/${customerId}/events/${eventId}`;
        return new Promise((resolve, reject) => {
            this.db
                .object(listRef)
                .update(eventData)
                .then(() => {
                    resolve({ data: data });
                })
                .catch((e) => {
                    console.log('ERROR', Error);
                    reject(e);
                });
        });
    }

    updateEventDate(data: any, customerId: string, eventId: string) {
        const eventData = this.eventTransformer.prepareEventDate(data);
        return this.updateEventData(eventData, data, customerId, eventId);
    }

    getCustomer(customerId: string) {
        const ref = `customers/${customerId}`;
        if (customerId) {
            return this.Firebase.getByRef(ref) as Observable<any>;
        }

        return of(null);
    }

    getCommonCustomerData(customerId: string) {
        //  stripeId
        // companyLogo
        // defaultTimeZone
        // companyName
        // customerName
        // address
        // noOfEmployees
        // defaultTimeFormate
        // defaultDateFormate
        // eventType
        // eventCategory
        return combineLatest([
            this.getStripeId(customerId),
            this.getCustomerLogo(customerId),
            this.getDefaultTimeZone(customerId),
            this.getCustomerCompanyName(customerId),
            this.getCustomerAddress(customerId),
            this.Firebase.getByRef(`customers/${customerId}/noOfEmployees`),
            this.Firebase.getByRef(`customers/${customerId}/defaultTimeFormate`),
            this.Firebase.getByRef(`customers/${customerId}/defaultDateFormate`),
            this.Firebase.getByRef(`customers/${customerId}/eventType`),
            this.Firebase.getByRef(`customers/${customerId}/eventCategory`),
        ]).pipe(
            map((val) => {
                return {
                    stripeId: val[0],
                    companyLogo: val[1],
                    defaultTimeZone: val[2],
                    companyName: val[3],
                    address: val[4],
                    noOfEmployees: val[5],
                    defaultTimeFormate: val[6],
                    defaultDateFormate: val[7],
                    eventType: val[8],
                    eventCategory: val[9],
                };
            })
        );
    }

    getSecurityData(customerId: string) {
        // authenticationMethod;
        // securityContact;
        // idPlatform;
        // teamMembers;
        return combineLatest([
            this.Firebase.getByRef(`customers/${customerId}/authenticationMethod`),
            this.Firebase.getByRef(`customers/${customerId}/securityContact`),
            this.Firebase.getByRef(`customers/${customerId}/idPlatform`),
            this.Firebase.getByRef(`customers/${customerId}/teamMembers`),
            this.Firebase.getByRef(`customers/${customerId}/tenantandSamlSso`),
        ]).pipe(
            map((val) => {
                return {
                    authenticationMethod: val[0],
                    securityContact: val[1],
                    idPlatform: val[2],
                    teamMembers: val[3],
                    tenantandSamlSso: val[4],
                };
            })
        );
    }

    getCustomerDefaultTimeZone(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/defaultTimeZone`);
    }

    getCustomerAddress(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/address`);
    }

    getCustomerCompanyName(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/companyName`);
    }
    getCustomerCompanyLogo(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/companyLogo`);
    }

    getCustomerName(userId: string) {
        return combineLatest([
            this.Firebase.getByRef(`users/${userId}/first_name`),
            this.Firebase.getByRef(`users/${userId}/last_name`),
        ]).pipe(map(([firstName, lastName]) => `${firstName || ''} ${lastName || ''}`));
    }

    getCustomerVatTaxNumber(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/vatTaxNumber`);
    }

    getCustomerEventsAddressAndDefaultTimeZone(customerId: string) {
        // const ref = `customers/${customerId}`;
        if (customerId) {
            return combineLatest([
                // this.Firebase.getCustomerEvents(customerId),
                this.getCustomerDefaultTimeZone(customerId),
                this.getCustomerAddress(customerId),
                this.getCustomerCompanyName(customerId),
            ]).pipe(
                // take(1),
                map((values: any) => {
                    return values;
                    // return {
                    //     events: values[0],
                    //     defaultTimeZone: values[1],
                    // };
                })
            );
        }

        return of(null);
    }

    getDashBoardEventsData(customerId: string) {
        return combineLatest([
            this.Firebase.getByRef(`customers/${customerId}/teamMembers`),
            this.Firebase.getCustomerEvents(customerId),
            this.Firebase.getCustomerOwnApp(customerId),
            this.Firebase.getCustomerHelloCrowdEvent(customerId),
        ]).pipe(
            map((values: any) => {
                return values;
            })
        );
    }

    getContributores(customerId: string, eventId: string) {
        // console.log('=========customerId', customerId);
        if (customerId) {
            // if (this.allContributorList && this.allContributorList.length) {
            //     return of(this.allContributorList);
            // } else {

            // }

            if (eventId) {
                const teamMembersRef = `customers/${customerId}/teamMembers`;
                const contributorRef = `customers/${customerId}/contributors`;
                const contributorsDataRef = `contributors`;
                const eventUser = `customers/${customerId}/events/${eventId}/user_id`;

                const promise = new Promise((resolve, reject) => {
                    combineLatest([
                        // this.Firebase.getByRef(teamMembersRef) as Observable<any>,
                        // this.Firebase.getByRef(contributorRef) as Observable<any>,
                        this.Firebase.getByRef(contributorsDataRef) as Observable<any>,
                        this.Firebase.getByRef(eventUser) as Observable<any>,
                    ])
                        .pipe(take(1))
                        .subscribe((values: any) => {
                            // const teamMembers = values[0];
                            // const contributors = values[0] ? _.keys(values[0]) : null;
                            let contributorsList: any[] = [];
                            const contributorsData = values[0];
                            const eventOwner = values[1];

                            // console.log('=================contributors', contributors);
                            // console.log('===================contributorsData', contributorsData);

                            const userId = eventOwner?.startsWith('user') ? eventOwner?.split('user')?.[1] : eventOwner;

                            const contributors$: any[] = [];

                            const accOwnerId = userId;

                            // console.log('========forEach', contributorsData);

                            const contributorUsersId = _.keys(contributorsData);
                            // const newContributoreData = {}

                            // contributorUsersId.forEach((id) => {
                            //     if(contributorsData?.[id]?.[])
                            // })

                            // const filteredByValue = Object.fromEntries(
                            //     Object.entries(contributorsData).filter(([key, value]) => {
                            //         returnObject.entries(contributorsData[key]).filter(([key1, value1]) => {
                            //             // console.log('========', contributorsData[key]);
                            //             return (key1 = customerId);
                            //         });
                            //     })
                            // );

                            if (contributorUsersId?.length) {
                                contributorUsersId.forEach((id: string) => {
                                    if (contributorsData?.[id]?.[customerId]?.events?.[eventId]) {
                                        contributors$.push(this.Firebase.getDbUserByUserId(id));
                                    }
                                });
                            }

                            if (accOwnerId) {
                                contributors$.push(this.Firebase.getDbUserByUserId(accOwnerId));
                            }

                            if (contributors$.length) {
                                combineLatest(contributors$)
                                    .pipe(take(1))
                                    .subscribe((users: any) => {
                                        // if (users) {
                                        users.forEach((user: any) => {
                                            if (user) {
                                                if (user.id === accOwnerId) {
                                                    const name = `${user.first_name ? user.first_name : ''} ${
                                                        user.last_name ? user.last_name : ''
                                                    }`;
                                                    contributorsList.push({
                                                        name: name,
                                                        profileImg: user.icon_url || user.icon_small,
                                                        role: 'Owner',
                                                        id: user.id,
                                                        email: user.email,
                                                    });
                                                } else {
                                                    const name = `${user.first_name ? user.first_name : ''} ${
                                                        user.last_name ? user.last_name : ''
                                                    }`;

                                                    // const contributorData = contributorsData[user.id];

                                                    contributorsList.push({
                                                        name: name,
                                                        profileImg: user.icon_url || user.icon_small,
                                                        role:
                                                            contributorsData?.[user.id]?.[customerId]?.events?.[
                                                                eventId
                                                            ]?.status?.toLowerCase() || 'Invited',
                                                        id: user.id,
                                                        email: user.email,
                                                    });
                                                }
                                            }
                                        });
                                        this.allContributorList = contributorsList;
                                        resolve(contributorsList);
                                        // }
                                    });
                            } else {
                                resolve(null);
                            }
                        });
                });

                return from(promise);
            } else {
                return of([]);
            }
        } else {
            return of([]);
        }
    }

    updateCustomer(data: any, customerId: string) {
        return new Promise((resolve, reject) => {
            // if(
            const ref = `customers/${customerId}`;
            this.Firebase.updateByRef(ref, data)
                .then(() => resolve(true))
                .catch((erorr) => {
                    console.log('error', erorr);
                    reject(erorr);
                });
        });
    }

    createEvent(customerId: string, userId: string, time_zone: string) {
        // const user =
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/brandKit`)
                .pipe(first())
                .subscribe((brandKit: BrandKit) => {
                    const prepareData: any = {
                        user_id: `user${userId}`,
                        colour_scheme: '#3748ac',
                        date_format: 'd/m/Y',
                        time_format: 'H:i',
                        // created_at: Math.floor(Date.now() / 1000),
                        created_at: moment.utc().toISOString(),
                        time_zone: time_zone,
                        status: 'DRAFT',
                    };
                    if (brandKit) {
                        prepareData.app_icon = brandKit.icon || null;
                        prepareData.colour_scheme = brandKit.colours?.button || '#3748ac';
                        prepareData.colours = brandKit.colours || null;
                        prepareData.bodyFonts = brandKit.bodyFonts || null;
                        prepareData.headerFonts = brandKit.headerFonts || null;
                        prepareData.style = brandKit.style || null;
                        prepareData.lightCustomerLogo = brandKit.lightCustomerLogo || null;
                        prepareData.darkCustomerLogo = brandKit.darkCustomerLogo || null;
                    }
                    this._generateEventId(customerId).then((key) => {
                        console.log(prepareData.created_at);
                        this.Firebase.setByRef(`customers/${customerId}/events/${key}`, prepareData)
                            .then(() => {
                                resolve(key);
                            })
                            .catch((error) => {
                                console.log('Error', error);
                                reject(error);
                            });
                    });
                });
        });
    }

    _generateEventId(customerId: string) {
        console.log('appId', customerId);
        return new Promise((resolve) => {
            this.getAllEventIds(customerId).then((ids) => {
                let eventId = this.Utilities.generateUID(5);
                while (ids.indexOf(eventId) !== -1) {
                    eventId = this.Utilities.generateUID(5);
                }
                resolve(eventId);
            });
            // const url = `${environment.firebaseConfig.databaseURL}/customers/${customerId}/events.json?auth=${environment.firebaseConfig.secret}&shallow=true`;
            // this.http.get(url).subscribe((items: any) => {
            //     const ids = Object.keys(items);
            // });
        });
    }

    getAllEventIds(customerId: string) {
        return new Promise((resolve) => {
            // if()
            const url = `${environment.firebaseConfig.databaseURL}/customers/${customerId}/events.json?auth=${environment.firebaseConfig.secret}&shallow=true`;
            this.http.get(url).subscribe((items: any) => {
                if (items) {
                    const ids = Object.keys(items);
                    resolve(ids);
                }
                resolve([]);
            });
        }) as Promise<string[]>;
    }

    convertToCustomerEvent(user: User) {
        return new Promise((resolve, reject) => {
            console.log('convertToCustomerEvent', user);
            const promise = [];
            if (_.keys(user.app) && user.app) {
                const appIds = _.keys(user.app);
                if (!appIds.includes('HELLOCROWD')) {
                    appIds.push('HELLOCROWD');
                }

                for (const id of appIds) {
                    promise.push(this.Firebase.getEventByUser(id, user.id));
                }

                Promise.all(promise).then((events) => {
                    resolve(events);
                });
            } else {
                resolve([]);
            }
        });
    }

    copyEventToCustomer(appId: any, event: any, menuItems: Page[] = [], sorceEventId = '') {
        // console.log("==============",appId)

        return new Promise((resolve, reject) => {
            console.log('event', event);
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            const prepareData = this.customerTransformer.prepareCopyEventData(event);
            const eventId = event.id;
            if (appId) {
                prepareData.app_id = appId;
            }

            prepareData.title = this._createEventTitle(event.title);
            prepareData.status = 'DRAFT';
            prepareData.created_at = Math.floor(Date.now() / 1000);

            // console.log('================prepareData', prepareData);
            let timeZone = 'Africa/Johannesburg';

            if (prepareData.time_zone) {
                if (prepareData.time_zone.includes('UTC')) {
                    timeZone = event.time_zone.split(' ')[1];
                } else {
                    timeZone = event.time_zone;
                }
            }

            this.Firebase.setByRef(`customers/${customerId}/events/${eventId}`, prepareData)
                .then(() => {
                    if (
                        prepareData.isCausesEnable ||
                        prepareData.isTicketAndRegistrationEnable ||
                        prepareData.isEventFeedbackEnable ||
                        prepareData.isEventWebSiteEnable ||
                        prepareData.isMarketingEnable
                    ) {
                        if (sorceEventId) {
                            this.copyCustomerEventsToolsData(customerId, sorceEventId, eventId, timeZone, appId).then(
                                () => {
                                    console.log('=====PROCESS FOR APP=====');
                                    if (appId) {
                                        this.copyEventToApp(
                                            prepareData,
                                            menuItems,
                                            appId,
                                            sorceEventId,
                                            eventId,
                                            customerId,
                                            event.firstSessionDate
                                        ).then(() => {
                                            if (appId === 'HELLOCROWD') {
                                                this.Firebase.updateByRef(`customers/${customerId}/hellocrowdEvents`, {
                                                    [`${eventId}`]: true,
                                                });
                                            }
                                            resolve(eventId);
                                        });
                                    } else {
                                        resolve(eventId);
                                    }
                                }
                            );
                        } else {
                            if (appId) {
                                this.copyEventToApp(
                                    prepareData,
                                    menuItems,
                                    appId,
                                    sorceEventId,
                                    eventId,
                                    customerId,
                                    event.firstSessionDate
                                ).then(() => {
                                    if (appId === 'HELLOCROWD') {
                                        this.Firebase.updateByRef(`customers/${customerId}/hellocrowdEvents`, {
                                            [`${eventId}`]: true,
                                        });
                                    }
                                    resolve(eventId);
                                });
                            } else {
                                resolve(eventId);
                            }
                        }
                    } else {
                        if (appId) {
                            this.copyEventToApp(
                                prepareData,
                                menuItems,
                                appId,
                                sorceEventId,
                                eventId,
                                customerId,
                                event.firstSessionDate
                            ).then(() => {
                                if (appId === 'HELLOCROWD') {
                                    this.Firebase.updateByRef(`customers/${customerId}/hellocrowdEvents`, {
                                        [`${eventId}`]: true,
                                    });
                                }
                                resolve(eventId);
                            });
                        } else {
                            resolve(eventId);
                        }
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                    reject(error);
                });
        });
    }

    copyEventToApp(
        sourceEvent: any,
        menuItems: Page[],
        appId: string,
        oldEvId: string,
        eventId: string,
        customerId: string,
        startDate: any
    ) {
        // console.log('=================sourceEvent', sourceEvent);
        // console.log('==================menuItems', menuItems);
        // console.log('==================appId', appId);
        // console.log('==================oldEvId', oldEvId);
        // console.log('==================eventId', eventId);
        // console.log('==================customerId', customerId);

        return new Promise((resolve, reject) => {
            let targetAppId = appId;
            const newEventData = {
                // type:
                //     sourceApp.app_id !== 'HELLOCROWD' || sourceEvent.is_professional_enabled
                //         ? 'Professional' // jshint ignore:line
                //         : 'Starter',
                firstSessionDate: startDate || null,
            };

            // console.log('==============newEventData', newEventData);

            const menu_items: any[] = menuItems.filter((item) => item.copy).map((item) => item.id);

            // console.log(targetAppId, newEventData, menu_items, copyMesages, copyLivePolls, seaAppTitle);

            combineLatest([
                this.Firebase.getByRef(`apps/${appId}/public/events_data/${oldEvId}`) as Observable<EventData>,
                // this.userService.getCurrent() as Observable<User>,
            ])
                .pipe(first())
                .subscribe(async (values) => {
                    const eventList = sourceEvent;
                    const eventData = values[0];
                    // const user = values[2];

                    const copyItems = this._processMenuItems(menu_items, eventData.pages);
                    console.log('.......>!!!!!');
                    // console.log(copyItems);
                    console.log(':: CREATE EVENT ::', targetAppId);

                    sourceEvent.customer_id = customerId;
                    sourceEvent.status = 'BUILD';

                    this.Firebase.getByRef(`apps/${appId}/public/events_list/${oldEvId}`)
                        .pipe(take(1))
                        .subscribe((oldEv: any) => {
                            sourceEvent = {
                                ...oldEv,
                                ...sourceEvent,
                                status: 'BUILD',
                                total_attendees: 0,
                                total_speakers: 0,
                            };

                            this.Firebase.updateByRef(`apps/${appId}/public/events_list/${eventId}`, sourceEvent).then(
                                () => {
                                    if (!menu_items.length) {
                                        console.log(':    NO NEED TO COPY DATA    :');

                                        // Schedule.event(targetAppId, newEventId);
                                        this.updateDates(targetAppId, eventId, newEventData.firstSessionDate, oldEvId);
                                        resolve({
                                            success: true,
                                            eventId,
                                        });
                                    } else {
                                        console.log(':    NEED TO COPY DATA    :');
                                        const data = this._pickDataToCopy(eventData, copyItems, newEventData);

                                        // console.log('============data-to-copy===============', data);
                                        if (data.sessions && !data.speakers) {
                                            data.sessions_speakers = null;
                                            data.speakers_sessions = null;
                                        }

                                        // if()

                                        this._setCopiedData(targetAppId, eventId, data, copyItems).catch(
                                            (error: any) => {
                                                console.log('SetCopiedData Error', error);
                                                reject(error);
                                            }
                                        );

                                        if (data.sponsors || data.exhibitors) {
                                            const promises: any[] = [];
                                            if (data.sponsors) {
                                                _.forOwn(data.sponsors, (val, sponsorId) => {
                                                    if (val.email_address) {
                                                        promises.push(
                                                            this.getAndStoreSponsorAndExhibitoreAtRootLevel(
                                                                val.email_address,
                                                                targetAppId,
                                                                eventId
                                                            )
                                                        );
                                                    }
                                                });
                                            }

                                            if (data.exhibitors) {
                                                _.forOwn(data.exhibitors, (val, exhibitorId) => {
                                                    if (val.email_address) {
                                                        promises.push(
                                                            this.getAndStoreSponsorAndExhibitoreAtRootLevel(
                                                                val.email_address,
                                                                targetAppId,
                                                                eventId
                                                            )
                                                        );
                                                    }
                                                });
                                            }

                                            if (promises.length) {
                                                Promise.all(promises).then();
                                            }
                                        }

                                        // if (data.scavenger_hunts) {
                                        //     console.log(':-- need to regenerate scavenger hunts', eventList);

                                        //     const eventIcon = this.fileStack.trasformImage(
                                        //         eventList?.app_icon ? eventList.app_icon : eventList?.app_icon_small,
                                        //         300,
                                        //         300,
                                        //         10
                                        //     );

                                        //     _.forOwn(data.scavenger_hunts, (val, scavengerHuntId) => {
                                        //         this.Firebase.pushByRef('qr_pairing', {
                                        //             app_id: appId,
                                        //             event_id: eventId,
                                        //             scavenger_hunt_id: scavengerHuntId,
                                        //         }).then((uniqueId: any) => {
                                        //             this.scavenger
                                        //                 .generate(
                                        //                     appId,
                                        //                     eventId,
                                        //                     scavengerHuntId,
                                        //                     uniqueId,
                                        //                     eventIcon,
                                        //                     eventList.colour_scheme
                                        //                 )
                                        //                 .then((response: any) => console.log(response));
                                        //         });
                                        //     });
                                        // }

                                        this.updateDates(targetAppId, eventId, newEventData.firstSessionDate, oldEvId);
                                        console.log('::::: EVENT WAS COPIED SUCCESSFULLY :::::');
                                        resolve({
                                            success: true,
                                            eventId,
                                        });
                                    }
                                }
                            );
                        });
                });
        });
    }

    getAndStoreSponsorAndExhibitoreAtRootLevel(email: string, appId: string, eventId: string) {
        // console.log('==========AppId', appId);
        // console.log('===========eventId', eventId);
        return new Promise((resolve, reject) => {
            if (!this.checkIfEmailisValid(email)) {
                resolve(true);
            } else {
                this.Firebase.getByRefWhere(`sponsorsAndExhibitors`, 'email', email).then((val: any) => {
                    if (val) {
                        const id = _.keys(val)[0];
                        const eventsIds = _.keys(val[id].events);
                        if (eventsIds.length > 0) {
                            if (!eventsIds.includes(eventId)) {
                                this.Firebase.updateByRef(`sponsorsAndExhibitors/${id}/events`, {
                                    ...val[id].events,
                                    [eventId]: {
                                        appId: appId,
                                    },
                                }).then(() => {
                                    resolve(true);
                                });
                            }
                        } else {
                            this.Firebase.updateByRef(`sponsorsAndExhibitors/${id}/events`, {
                                [eventId]: {
                                    appId: appId,
                                },
                            }).then(() => {
                                resolve(true);
                            });
                        }
                    } else {
                        const prepareData = {
                            email: email,
                            events: {
                                [eventId]: {
                                    appId: appId,
                                },
                            },
                        };

                        this.Firebase.pushByRef(`sponsorsAndExhibitors`, prepareData).then(() => {
                            resolve(true);
                        });
                    }
                });
            }
        });
    }

    checkIfEmailisValid(email: string) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    _setCopiedData(
        targetAppId: string,
        newEventId: string,
        newData: EventData,
        copyItems: {
            items: string[];
            pagesToCopy: EventData['pages'];
            agendaPageId: string | undefined;
            copySessions: boolean;
            copyAttendees: boolean;
        }
    ) {
        return new Promise((resolve) => {
            const sessions = newData.sessions;
            const data = _.omit(newData, 'sessions');
            this.Firebase.updateByRef(`apps/${targetAppId}/public/events_data/${newEventId}`, data).then(() => {
                if (copyItems.copySessions && sessions) {
                    this._copySessions(targetAppId, newEventId, _.values(sessions)).then((newSessions: any) => {
                        this.sessionModalService._scheduleSessions(targetAppId, newEventId, newSessions);
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            });
        });
    }

    _copySessions(appId: string, eventId: string, sessions: SessionList[]) {
        return new Promise((resolve) => {
            const newSessions: any = {};
            const promises: any[] = [];
            sessions.forEach((s: any) => {
                if (!s.type) {
                    s.type = 'optional';
                }
                promises.push(this.Firebase.pushByRef(`apps/${appId}/public/events_data/${eventId}/sessions`, s));
            });

            Promise.all(promises).then((ids: any) => {
                ids.forEach((id: string, i: number) => {
                    newSessions[id] = sessions[i];
                });

                resolve(newSessions);
            });
        });
    }

    _pickDataToCopy(
        data: EventData,
        copyItems: {
            items: string[];
            pagesToCopy: EventData['pages'];
            agendaPageId: string | undefined;
            copySessions: boolean;
            copyAttendees: boolean;
        },
        newEventData: any
    ) {
        let pickOptions: string[] = ['documents'];
        _.forEach(copyItems.items, (type) => {
            switch (type) {
                case 'sessions':
                    pickOptions.push('sessions', 'tracks');
                    break;
                // case 'attendees':
                //     pickOptions.push('attendees');
                //     break;
                // case 'speakers':
                //     pickOptions.push('speakers');
                //     break;
                case 'exhibitors':
                    pickOptions.push('exhibitors', 'exhibitor_categories');
                    break;
                case 'maps':
                    pickOptions.push('maps');
                    break;
                case 'sponsors':
                    pickOptions.push('sponsors', 'sponsor_categories', 'sessions_sponsor');
                    break;
                case 'contactus':
                    pickOptions.push('company_contacts');
                    break;
                case 'infobooth':
                    pickOptions.push('infobooths');
                    break;
                case 'webview':
                    pickOptions.push('webviews');
                    break;
                case 'scavenger_hunt':
                    pickOptions.push('scavenger_hunts');
                    break;
                // case 'feedback':
                //     pickOptions.push('feedback_forms', 'feedback_choices');
                //     break;
            }
        });
        pickOptions = _.uniq(pickOptions);
        const newData: any = _.pick(data, pickOptions);
        newData.pages = copyItems.pagesToCopy;
        // UPDATE SESSIONS TIMES
        if (copyItems.copySessions && newData.sessions && newEventData.firstSessionDate) {
            const firstSession = _.values(newData.sessions)[0];
            const date1 = moment(firstSession.start_date_time_unix).toDate().getTime();
            const date2 = moment(newEventData.firstSessionDate).toDate().getTime();
            const diff = Math.abs(date2 - date1);
            _.forOwn(newData.sessions, (val, key) => {
                newData.sessions[key].start_date_time_unix = moment(val.start_date_time_unix)
                    .add(diff, 'ms')
                    .format('YYYY-MM-DD HH:mm:ss');
            });
        }
        return newData;
    }

    updateDates(appId: string, eventId: string, start_date: Date, oldEvId: string) {
        console.log('[updateDates]', appId, eventId, start_date);
        return new Promise((resolve) => {
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            (this.sessionModalService.getAllSession(appId, eventId) as Promise<SessionList[]>).then((sessions) => {
                if (sessions && sessions.length) {
                    const start = _.minBy(sessions, (s) => s.start_date) as any;
                    const end = _.maxBy(sessions, (s) => s.end_date) as any;
                    const data = {
                        start_date_time_unix: moment(start.start_date).toDate(),
                        end_date_time_unix: moment(end.end_date).toDate(),
                    };
                    this.update(appId, eventId, data, oldEvId).then(() => console.log(eventId, 'was updated'));

                    this.updateEventData(data, data, customerId, eventId);
                    // this.scheduleService.event(appId, eventId);
                    resolve(data);
                } else {
                    const data = {
                        start_date_time_unix: moment(start_date).toDate(),
                        end_date_time_unix: moment(start_date).add('day', 1).toDate(),
                    };
                    this.updateEventData(data, data, customerId, eventId);
                    this.update(appId, eventId, data, oldEvId).then(() => console.log(eventId, 'was updated'));
                    // this.scheduleService.event(appId, eventId);
                    resolve(data);
                }
            });
        });
    }

    update(appId: string, eventId: string, data: any, oldEvId: string) {
        return new Promise((resolve, reject) => {
            const privateData = _.pick(data, this.privateFields);
            const publicData = _.omit(data, this.privateFields);
            const promises: any[] = [];
            this.Firebase.getByRef(`apps/${appId}/private/events/${oldEvId}`)
                .pipe(take(1))
                .subscribe((oldEv: any) => {
                    console.log('===============oldEv', oldEv);
                    this.paring.create(appId, eventId).then((key: any) => {
                        const preparePrivateData = {
                            event_sec_password: oldEv.event_sec_password,
                            is_paid: oldEv.is_paid || false,
                            pairing_key: key,
                        };

                        promises.push(
                            this.Firebase.updateByRef(`apps/${appId}/private/events/${eventId}`, preparePrivateData)
                        );

                        if (publicData && _.size(publicData)) {
                            promises.push(
                                this.Firebase.updateByRef(`apps/${appId}/public/events_list/${eventId}`, publicData)
                            );
                        }
                    });

                    if (promises.length) {
                        Promise.all(promises)
                            .then(() => {
                                resolve(true);
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve(true);
                    }
                });
            // if (privateData && _.size(privateData)) {
            //     promises.push(this.Firebase.updateByRef(`apps/${appId}/private/events/${eventId}`, privateData));
            // }
            // if (publicData && _.size(publicData)) {
            //     promises.push(this.Firebase.updateByRef(`apps/${appId}/public/events_list/${eventId}`, publicData));
            // }
        });
    }

    _processMenuItems(menu_items: string[], pages: EventData['pages']) {
        const pagesToCopy: EventData['pages'] = {};
        menu_items.forEach((id: string) => {
            if (pages && pages[id]) {
                pagesToCopy[id] = pages[id];
            }
        });
        let agendaPageId;
        const pages_types: string[] = [];

        _.forOwn(pagesToCopy, (val, key) => {
            if (val.page_meta === 'page_agenda') {
                agendaPageId = key;
            }
            pages_types.push(val.page_meta.replace('page_', '').replace('agenda', 'sessions'));
        });

        return {
            items: pages_types,
            pagesToCopy,
            agendaPageId,
            copySessions: pages_types.indexOf('sessions') !== -1,
            copyAttendees: pages_types.indexOf('attendees') !== -1,
        };
    }

    copyCustomerEventsToolsData(customerId: string, eventId: string, newEvId: string, timeZone: string, appId = '') {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/eventsData/${eventId}`)
                .pipe(take(1))
                .subscribe((evData: any) => {
                    console.log('=======evData', evData);
                    if (evData) {
                        Promise.all([
                            this.copyEventAndTicktesColumnsSettings(evData, newEvId, customerId),
                            this.copyEventSetup(evData, newEvId, customerId, timeZone, appId),
                        ])
                            .then(() => {
                                resolve(true);
                            })
                            .catch((error) => {
                                console.log('Error', error);
                                resolve(true);
                            });
                    } else {
                        resolve(true);
                    }
                });
        });
    }

    copyEventAndTicktesColumnsSettings(evData: any, evId: string, customerId: string) {
        return new Promise((resolve, reject) => {
            if (evData.columnSettings || evData.ticketSettings) {
                // Promise.all([this]);
                this.Firebase.updateByRef(`customers/${customerId}/eventsData/${evId}`, {
                    columnSettings: evData.columnSettings || null,
                    ticketSettings: evData.ticketSettings || null,
                }).then(() => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    copyEventSetup(evData: any, evId: string, customerId: string, timeZone: string, appId = '') {
        return new Promise((resolve, reject) => {
            if (evData.eventSetup) {
                const eventSetup: any = evData.eventSetup;

                let prepapreEventSetupData: any = {
                    causes: eventSetup.causes || null,
                    registration: eventSetup.registration || null,
                    tickets: eventSetup.tickets || null,
                    eventWebsite: eventSetup.eventWebsite || null,
                    accommodations: eventSetup.accommodations || null,
                    promoCodes: eventSetup.promoCodes || null,
                    messageAttendee: eventSetup.messageAttendee || null,
                };

                if (eventSetup.messageInvitee) {
                    prepapreEventSetupData = {
                        ...prepapreEventSetupData,
                        messageInvitee: {
                            invitation:
                                _.omit(eventSetup.messageInvitee?.invitation, ['scheduledTime', 'sendSchedule']) ||
                                null,
                            invitationReminder:
                                _.omit(eventSetup.messageInvitee?.invitationReminder, [
                                    'scheduledTime',
                                    'sendSchedule',
                                ]) || null,
                            abandon: eventSetup.messageInvitee?.invitationReminder || null,
                        },
                    };
                }

                if (eventSetup?.feedback?.forms) {
                    prepapreEventSetupData = {
                        ...prepapreEventSetupData,
                        feedback: {
                            forms: eventSetup?.feedback?.forms,
                        },
                    };
                }

                this.Firebase.updateByRef(
                    `customers/${customerId}/eventsData/${evId}/eventSetup`,
                    prepapreEventSetupData
                ).then(() => {
                    resolve(true);
                    // if (eventSetup?.feedback?.settings && appId) {
                    //     const feedbackSettings = eventSetup?.feedback?.settings;
                    //     this._copyEventSetupFeedbackSettings(feedbackSettings, timeZone, appId, evId, customerId).then(
                    //         () => {
                    //             resolve(true);
                    //         }
                    //     );
                    // } else {
                    //     resolve(true);
                    // }
                });
            } else {
                resolve(true);
            }
        });
    }

    _copyEventSetupFeedbackSettings(
        feedbackSettings: any,
        timeZone: any,
        appId: string,
        evId: string,
        customerId: string
    ) {
        return new Promise((resolve, reject) => {
            if (feedbackSettings?.sendDateTimeUnix) {
                if (this.checkIfDateAndTimeIsGreaterThanCurrentTime(feedbackSettings?.sendDateTimeUnix, timeZone)) {
                    this.copyScheduleNotification(feedbackSettings?.sendDateTimeUnix, timeZone, evId, appId).then(
                        () => {
                            const remainderPromise: any[] = [];
                            if (feedbackSettings?.reminders) {
                                _.keys(feedbackSettings?.reminders).forEach((reminderKey: string) => {
                                    if (
                                        this.checkIfDateAndTimeIsGreaterThanCurrentTime(
                                            feedbackSettings?.reminders[reminderKey]?.sendDateTimeUnix,
                                            timeZone
                                        )
                                    ) {
                                        remainderPromise.push(
                                            this.copyScheduleRemainder(
                                                appId,
                                                evId,
                                                this.customerId,
                                                { ...feedbackSettings?.reminders[reminderKey], id: reminderKey },
                                                timeZone
                                            )
                                        );
                                    }
                                });
                            }

                            if (remainderPromise.length) {
                                Promise.all(remainderPromise).then((resp: any) => {
                                    if (resp) {
                                        let prepareRemainderData: any = {};
                                        resp.forEach((re: any) => {
                                            prepareRemainderData = {
                                                ...prepareRemainderData,
                                                [re.id]: {
                                                    scheduleId: re.scheduleId,
                                                    sendDateTimeUnix: re.sendDateTimeUnix,
                                                },
                                            };
                                        });

                                        console.log('prepareRemainderData', prepareRemainderData);

                                        this.Firebase.updateByRef(
                                            `customers/${customerId}/eventsData/${evId}/eventSetup/feedback/settings`,
                                            {
                                                sendDateTimeUnix: feedbackSettings?.sendDateTimeUnix,
                                                reminders: prepareRemainderData,
                                            }
                                        ).then(() => {
                                            resolve(true);
                                        });
                                    }
                                });
                            } else {
                                this.Firebase.updateByRef(
                                    `customers/${customerId}/eventsData/${evId}/eventSetup/feedback/settings`,
                                    {
                                        sendDateTimeUnix: feedbackSettings?.sendDateTimeUnix,
                                    }
                                ).then(() => {
                                    resolve(true);
                                });
                            }
                        }
                    );
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        });
    }

    copyScheduleRemainder(appId: string, eventId: string, customerId: string, data: any, timeZone: any) {
        return new Promise((resolve, reject) => {
            this.Firebase.updateByRef(`schedule/events/${appId}_${eventId}_feedback_reminders`, {
                _state: 'feedback_reminder',
                app_id: appId,
                event_id: eventId,
                customer_id: customerId,
            });
            this.Firebase.pushByRef(`schedule/events/${appId}_${eventId}_feedback_reminders`, {
                date: moment(data.sendDateTimeUnix).format('YYYY-MM-DD HH:mm:ss'),
                time_zone: timeZone,
            }).then((key) => {
                data.scheduleId = key;
                resolve(data);
            });
        });
    }

    copyScheduleNotification(data: any, timeZone: string, evId: string, appId: string) {
        return new Promise((resolve, reject) => {
            console.log('DATE', moment(data.sendDateTimeUnix).format('YYYY-MM-DD HH:mm:ss'));
            this.db
                .object(`schedule/events/${appId}_${evId}_feedback`)
                .set({
                    _state: 'feedback_notification',
                    app_id: appId,
                    event_id: evId,
                    date: moment(data.sendDateTimeUnix).format('YYYY-MM-DD HH:mm:ss'),
                    time_zone: timeZone,
                    customer_id: this.customerId,
                })
                .then(() => resolve(true));
        });
    }

    checkIfDateAndTimeIsGreaterThanCurrentTime(date: string, timeZone: string) {
        let timeNow = moment.tz(new Date(), timeZone);
        let checkDate = moment.tz(date, timeZone);

        if (timeNow <= checkDate) {
            return true;
        } else {
            return false;
        }
    }

    createUpdateStripeCustomer() {
        // return new Promise((resolve) => {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        return this.getCustomer(customerId).pipe(
            take(1),
            switchMap((customer: any): any => {
                const teamMembersIds = _.keys(customer.teamMembers);
                const companyOwner = teamMembersIds.find((key) => customer.teamMembers[key].toLowerCase() === 'owner');
                console.log('copanyOwner', companyOwner);
                return this.Firebase.getByRef(`users/${companyOwner}`).pipe(
                    switchMap((user: any) => {
                        const prepareData: any = {
                            first_name: user.first_name || '',
                            last_name: user.last_name || '',
                            email: user.email,
                            company_city: customer.address?.addressLocality || '',
                            company_country_code: customer.address?.addressCountry || '',
                            company_address1: customer.address?.streetAddress || '',
                            company_address2: customer.address?.addressLine2 || '',
                            company_zip: customer.address?.postalCode || '',
                            company_province: customer.address?.addressRegion || '',
                            id: companyOwner,
                            companyName: customer.companyName || user.first_name + ' ' + user.last_name || '',
                            currency: customer.defaultCurrency || 'usd',
                            time_zone: customer.defaultTimeZone || 'UTC',
                        };

                        if (user.payment_profile?.customer_id || customer.stripeId) {
                            prepareData.customer = customer.stripeId || user.payment_profile?.customer_id;
                        }

                        return this.stripe.getUpdateCustomer(prepareData).pipe(
                            map((data) => {
                                return data;
                            })
                        );
                    })
                );
            })
        );
        // });
    }

    getAnalyticsData(customerId: string, eventId: string, enabledTools: string[], appId = '') {
        return new Promise((resolve, reject) => {
            this.getEventCapacity(customerId, eventId, appId).then((eventCapacity) => {
                const promises: any = [];
                if (enabledTools.includes('Tickets & Reg')) {
                    promises.push(this.getTicketsReport(customerId, eventId));
                }
                if (enabledTools.includes('Event Feedback')) {
                    promises.push(this.getEventFeedbackReport(customerId, eventId));
                }
                if (enabledTools.includes('Causes')) {
                    promises.push(this.getDonationsReport(customerId, eventId));
                }
                if (enabledTools.includes('Mobile App')) {
                    promises.push(this.getAppDownloadReport(appId, eventId));
                }
                Promise.all(promises).then((res) => {
                    const retObj: any = { eventCapacity };
                    _.forEach(res, (report) => {
                        const key = Object.keys(report)[0];
                        retObj[key] = report[key];
                    });
                    resolve(retObj);
                });
            });
        });
    }

    getEventCapacity(customerId: string, eventId: string, appId: string) {
        return new Promise((resolve, reject) => {
            const eventCapacityRef = `customers/${customerId}/events/${eventId}/eventCapacity`;

            this.Firebase.getByRef(eventCapacityRef)
                .pipe(first())
                .subscribe((eventCapacity) => {
                    // console.log("EVENT CAPACITY IS", eventCapacity);

                    resolve(eventCapacity || 0);

                    //  else {
                    //     const ref = `customers/${customerId}/eventsData/${eventId}/invitees`;
                    //     const appAttendees = `/apps/${appId}/public/events_data/${eventId}/attendees`;
                    //     combineLatest([
                    //         this.Firebase.getByRef(ref),
                    //         appId ? this.Firebase.getByRef(appAttendees) : of(null),
                    //     ])
                    //         .pipe(first())
                    //         .subscribe((res: any) => {
                    //             let totalInviteeAttendee = 0;
                    //             let totalAppAttendee = 0;
                    //             const invitees = res[0];
                    //             const attendees = res[1];
                    //             if (invitees) {
                    //                 _.forOwn(invitees, (invitee, key) => {
                    //                     if (['Attending', 'Checked In', 'Checked Out'].includes(invitee.status)) {
                    //                         totalInviteeAttendee += 1;
                    //                     }
                    //                 });
                    //             }
                    //             if (appId && attendees) {
                    //                 totalAppAttendee = Object.keys(attendees).length;
                    //             }
                    //             if (totalAppAttendee > totalInviteeAttendee) {
                    //                 totalInviteeAttendee = totalAppAttendee;
                    //             }
                    //             resolve(totalInviteeAttendee);
                    //         });
                    // }
                });
        });
    }

    calculateSales(data: any, invitee: any, plan: string) {
        return new Promise((resolve, reject) => {
            let netSales = 0;
            let grossSales = 0;
            let ticketSold = 0;
            let freeTicketCount = 0;
            let paidTicketCount = 0;
            let totalAttendee = 0;
            let totalGuest = 0;
            let totalRegistrant = 0;
            if (data) {
                _.forOwn(data, (attendee, key) => {
                    if (['Attending', 'Checked In', 'Checked Out'].includes(attendee.status)) {
                        totalAttendee += 1;
                        if (attendee.type === 'Guest') {
                            totalGuest += 1;
                        } else if (attendee.type === 'Registrant') {
                            totalRegistrant += 1;
                        }
                    }
                });

                const keys = Object.keys(data);
                for (const key of keys) {
                    if (data[key].freeRegistrant) {
                        if (data[key].purchasedTickets && !invitee?.[data[key]?.inviteeId]?.isSessionAvailable) {
                            // const ticketId = Object.keys(data[key].purchasedTickets)[0];
                            // if (ticketId) {
                            const assigned = Object.keys(data[key].purchasedTickets).length;
                            ticketSold += assigned;
                            freeTicketCount += assigned;
                            // }
                        }
                    } else {
                        if (data[key].purchasedTickets && !invitee?.[data[key]?.inviteeId]?.isSessionAvailable) {
                            // const ticketIds = Object.keys(data[key].purchasedTickets).length;
                            // if (ticketIds) {
                            const assigned = Object.keys(data[key].purchasedTickets).length;
                            ticketSold += assigned;
                            paidTicketCount += assigned;
                            // }
                        }

                        if (+data[key].subTotal === +data[key].total) {
                            const planFees: any = {
                                'enterprise agreement': 3,
                                business: 3.5,
                                'event pro': 6,
                                expert: 3,
                                solo: 7,
                                pro: 5,
                            };

                            const fees =
                                planFees[
                                    plan?.toLowerCase()?.includes('enterprise')
                                        ? 'enterprise agreement'
                                        : plan?.toLowerCase()
                                ];
                            grossSales += +data[key].total || 0;
                            netSales += +data[key].subTotal - (+data[key].subTotal * fees) / 100;
                        } else {
                            netSales += +data[key].subTotal || 0;
                            grossSales += +data[key].total || 0;
                        }

                        netSales = (netSales || 0) - (data[key]?.refundAmount || 0);
                        grossSales = (grossSales || 0) - (data[key]?.refundAmount || 0);
                        if (data[key]?.subTotal === data[key]?.refundAmount) {
                            grossSales = (grossSales || 0) - (data[key]?.serviceFee || 0);
                        }
                        // if (data[key].noOfTickets) {
                        //     const values = data[key].noOfTickets;
                        //     const keys2 = Object.keys(values);
                        //     let currentSum = 0;
                        //     for (const _key of keys2) {
                        //         currentSum += +values[_key];
                        //     }
                        //     ticketSold += currentSum;
                        // }
                    }
                }
            }
            resolve({
                netSales,
                grossSales,
                ticketSold,
                freeTicketCount,
                paidTicketCount,
                totalAttendee,
                totalGuest,
                totalRegistrant,
            });
        });
    }

    countTickets(data: any) {
        return new Promise((resolve, reject) => {
            let totalTickets = 0;
            let paidTickets = false;
            if (data) {
                const keys = Object.keys(data);
                for (const key of keys) {
                    totalTickets += +data[key].availableQuantity || 0;
                    if (data[key].type === 'PAID') {
                        paidTickets = true;
                    }
                }
                resolve({ totalTickets, paidTickets });
            } else {
                resolve({ totalTickets, paidTickets });
            }
        });
    }

    getCustomerSubPlan(customerId: string) {
        return new Promise((resolve) => {
            this.Firebase.getByRef(`customers/${customerId}/subscription`).subscribe((plan: any) => {
                if (plan?.type?.toLowerCase().includes('enterprise')) {
                    resolve({ plan: 'enterprise agreement' });
                } else {
                    this.Firebase.getByRef(`customers/${customerId}/subscriptions`).subscribe((res) => {
                        if (res) {
                            const subplan: string = res[Object.keys(res)[0]]?.plan?.toLowerCase();
                            const teamMember = { business: 10, pro: 3, solo: 1 };
                            if (subplan === 'business' || subplan === 'pro' || subplan === 'solo') {
                                const member = teamMember[subplan];
                                console.log(member);
                                console.log('===== subscription res =====', subplan);
                                resolve({ plan: subplan, teamMember: member });
                            } else {
                                resolve({ plan: subplan });
                            }
                        } else {
                            resolve({ plan: '' });
                        }
                    });
                }
            });
        });
    }

    getTicketsReport(customerId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            const ticketsCountRef = `customers/${customerId}/eventsData/${eventId}/eventSetup/tickets`;

            this.getCustomerSubPlan(customerId).then((res: any) => {
                this.Firebase.getByRef(ticketsCountRef)
                    .pipe(first())
                    .subscribe((ticketData: any) => {
                        this.countTickets(ticketData).then((ticketCountData: any) => {
                            if (ticketCountData.paidTickets) {
                                const ref = `customers/${customerId}/eventsData/${eventId}/tickets`;
                                const inviteeref = `customers/${customerId}/eventsData/${eventId}/invitees`;
                                // this.Firebase.getByRef(ref)
                                combineLatest([this.Firebase.getByRef(ref), this.Firebase.getByRef(inviteeref)])
                                    .pipe(first())
                                    .subscribe((value: any) => {
                                        ticketData = value[0];

                                        this.calculateSales(ticketData, value[1], res.plan).then((salesData: any) => {
                                            resolve({ ticketReport: { ...salesData, ...ticketCountData } });
                                        });
                                    });
                            } else {
                                const ref = `customers/${customerId}/eventsData/${eventId}/tickets`;
                                this.Firebase.getByRef(ref)
                                    .pipe(first())
                                    .subscribe((attendees: any) => {
                                        let totalAttendee = 0;
                                        let totalGuest = 0;
                                        let totalRegistrant = 0;
                                        if (attendees) {
                                            _.forOwn(attendees, (attendee, key) => {
                                                if (attendee.status) {
                                                    if (
                                                        ['Attending', 'Checked In', 'Checked Out'].includes(
                                                            attendee.status
                                                        )
                                                    ) {
                                                        totalAttendee += 1;
                                                        if (attendee.type === 'Guest') {
                                                            totalGuest += 1;
                                                        } else if (attendee.type === 'Registrant') {
                                                            totalRegistrant += 1;
                                                        }
                                                    }
                                                } else {
                                                    console.log(
                                                        'ATTENDEE WITHOUT THE STATUS IS =========== 🅱️ 🅱️ 🅱️ 🅱️ 🅱️ 🅱️ 🅱️🅱️ 🅱️🅱️🅱️🅱️🅱️🅱️🅱️🅱️🅱️🅱️ ',
                                                        attendee
                                                    );
                                                }
                                            });
                                        }
                                        resolve({
                                            ticketReport: {
                                                totalAttendee,
                                                totalGuest,
                                                totalRegistrant,
                                                ...ticketCountData,
                                            },
                                        });
                                    });
                            }
                        });
                    });
            });
        });
    }

    getEventFeedbackReport(customerId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            const ticketsCountRef = `customers/${customerId}/eventsData/${eventId}/eventSetup/feedback/submitted`;
            this.Firebase.getByRef(ticketsCountRef)
                .pipe(first())
                .subscribe((data: any) => {
                    console.log('getEventFeedbackReport() SUBMITTED DATA', data);
                    let feedbackReport = 0;
                    if (data) {
                        feedbackReport = Object.keys(data).length;
                    }
                    resolve({ feedbackReport });
                });
        });
    }

    getAppDownloadReport(appId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            // const usersRef = `apps/${appId}/public/users_events`;
            const attendeesRef = `apps/${appId}/public/events_data/${eventId}/attendees`;
            const speakersRef = `apps/${appId}/public/events_data/${eventId}/speakers`;

            combineLatest([this.Firebase.getByRef(attendeesRef), this.Firebase.getByRef(speakersRef)])
                .pipe(take(1))
                .subscribe((values) => {
                    let appReport = 0;
                    if (values[0] || values[1]) {
                        const totalUsers = [..._.keys(values[0]), ..._.keys(values[1])];

                        if (totalUsers?.length) {
                            const firstAccessUsers = totalUsers.filter(
                                (id: string) => values?.[0]?.[id]?.first_accessed || values?.[1]?.[id]?.first_accessed
                            );

                            appReport = firstAccessUsers?.length;
                        }
                    }

                    resolve({ appReport });
                });
            // Promise.all([this.Firebase.getByRef(attendeesRef), this.Firebase.getByRef(speakersRef)]);
            // this.Firebase.getByRefWhere(usersRef, eventId, true).then((data: any) => {
            //     let appReport = 0;
            //     if (data) {
            //         appReport = Object.keys(data).length;
            //     }
            //     resolve({ appReport });
            // });
        });
    }

    getDonationsReport(customerId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            const causesRef = `customers/${customerId}/eventsData/${eventId}/eventSetup/causes`;
            this.Firebase.getByRef(causesRef)
                .pipe(first())
                .subscribe((res) => {
                    if (!res) {
                        resolve({ causeReport: null });
                    } else {
                        let collected = 0;
                        let target = 0;
                        let totalDonations = 0;
                        if (res) {
                            _.forOwn(res, (cause: any, key) => {
                                totalDonations += cause.contributions ? +cause.contributions : 0;
                                if (cause.target) {
                                    target += +cause.target;
                                }
                                if (cause.collected) {
                                    collected += cause.collected;
                                }
                            });
                        }
                        resolve({ causeReport: { collected, target, totalDonations } });
                    }
                });
        });
    }

    getCurrency(customerId: string): Promise<string> {
        const ref = `customers/${customerId}`;
        return new Promise((resolve, reject) => {
            (this.Firebase.getByRef(ref) as Observable<Customer>).pipe(take(1)).subscribe((val) => {
                console.log('val.defaultCurrency', val.defaultCurrency);
                // if()
                this.utilityService
                    .getCurrencySymbol(
                        val.defaultCurrency === 'ZA' || val.defaultCurrency === 'US'
                            ? val.defaultCurrency === 'ZA'
                                ? 'ZAR'
                                : 'USD'
                            : val.defaultCurrency
                    )
                    .then((data) => {
                        resolve(data as string);
                    });
            });
        });
    }

    getCurrencyName(customerId: string) {
        const ref = `customers/${customerId}`;
        return new Promise((resolve, reject) => {
            (this.Firebase.getByRef(ref) as Observable<Customer>).pipe(take(1)).subscribe((val) => {
                resolve(val.defaultCurrency || 'ZAR');
                // this.utilityService.getCurrencySymbol(val.defaultCurrency).then((data) => {
                //     resolve(data);
                // });
            });
        });
    }

    updateEventCapacity(customerId: string, eventId: string, data: { eventCapacity: number }) {
        const ref = `customers/${customerId}/events/${eventId}`;
        return this.Firebase.updateByRef(ref, data);
    }
    _createEventTitle(title: string) {
        return `${title} copy ${moment().format('YYYY-MM-DD HH:mm:ss')}`;
    }

    addBankDetails(data: any, customerId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.pushByRef(`customers/${customerId}/bankDetails`, data)
                .then((key) => {
                    if (eventId) {
                        console.log('key', key);
                        const ref = `customers/${customerId}/events/${eventId}`;
                        this.Firebase.updateByRef(ref, {
                            bankId: key,
                        })
                            .then(() => {
                                resolve(true);
                            })
                            .catch((error) => {
                                console.log('error', error);
                                resolve(true);
                            });
                    } else {
                        resolve(key);
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                    resolve(true);
                });
        });
    }

    updateBankDetails(customerId: string, id: string, data: any) {
        return new Promise((resolve) => {
            this.http
                .put(`${environment.fcfApiUrl}/v2payments/paystack/updatesubAcc/${data.subaccount_code}`, {
                    active: true,
                })
                .subscribe((resp) => {
                    this.Firebase.updateByRef(`customers/${customerId}/bankDetails/${id}`, {
                        active: true,
                        business_name: data.friendlyName,
                    }).then(() => {
                        resolve(true);
                    });
                });
        });
    }

    checkIsBankDetailExists(settlement_bank: string, account_number: string, customerId: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRefWhere(`customers/${customerId}/bankDetails`, 'account_number', account_number).then(
                (res: any) => {
                    if (res) {
                        const resData = Object.entries(res);
                        const data = resData.find((bank: any) => bank[1].settlement_bank === settlement_bank);
                        if (resData.length > 0) {
                            resolve(data);
                        } else {
                            reject(false);
                        }
                    }
                }
            );
        });
    }

    getEventLevelCurrency(customerId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            if (this.currency) {
                resolve(this.currency);
            } else {
                if (eventId) {
                    combineLatest([
                        this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/bankId`),
                        this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/app_id`),
                        this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/currency`),
                    ])
                        // this.Firebase.getByRef(`customers/${customerId}/events/${eventId}`)
                        //     .pipe(take(1))
                        .subscribe((values: any) => {
                            const ev = {
                                bankId: values[0] || null,
                                app_id: values[1] || null,
                                currency: values[2] || null,
                            };

                            if (ev.bankId) {
                                this.Firebase.getByRef(`customers/${customerId}/bankDetails/${ev.bankId}`)
                                    .pipe(take(1))
                                    .subscribe((acc: any) => {
                                        if (acc && acc.accType === 'stripe') {
                                            if (!acc.isOnBoardingCompleted) {
                                                this.stripe.getStripeAcc(acc?.accId).subscribe((accInfo: any) => {
                                                    if (accInfo?.charges_enabled || accInfo?.details_submitted) {
                                                        const prepareData = {
                                                            customerAddress:
                                                                accInfo?.business_profile?.support_address || null,
                                                            customerPhone:
                                                                accInfo?.business_profile?.support_phone || null,
                                                            customerWebSiteURL: accInfo?.business_profile?.url || null,
                                                            defaultCurrency: accInfo?.default_currency,
                                                            country: accInfo?.country,
                                                            email: accInfo?.email,
                                                            isOnBoardingCompleted: true,
                                                            businessName: accInfo?.business_profile?.name || null,
                                                        };

                                                        this.Firebase.updateByRef(
                                                            `customers/${customerId}/bankDetails/${ev.bankId}`,
                                                            prepareData
                                                        )
                                                            .then(() => {
                                                                this.checkCustomerAndEventLevelCurrency(
                                                                    customerId,
                                                                    ev
                                                                ).then((resp: any) => {
                                                                    this.currency = resp;
                                                                    resp.bankAcc = acc;
                                                                    this.getConvertedServiceFee(resp).then(
                                                                        (res: any) => {
                                                                            this.getTicketFeePercentage(
                                                                                ev.app_id || null,
                                                                                customerId,
                                                                                eventId
                                                                            ).then((serviceFeePercentage: any) => {
                                                                                res.serviceFeePercentage =
                                                                                    serviceFeePercentage;
                                                                                resolve(res);
                                                                            });
                                                                        }
                                                                    );
                                                                });
                                                            })
                                                            .catch((error) => {
                                                                console.log('Error', error);
                                                            });
                                                    } else {
                                                        this.checkCustomerAndEventLevelCurrency(customerId, ev).then(
                                                            (resp: any) => {
                                                                this.currency = resp;
                                                                resp.bankAcc = acc;
                                                                this.getConvertedServiceFee(resp).then((res: any) => {
                                                                    this.getTicketFeePercentage(
                                                                        ev.app_id || null,
                                                                        customerId,
                                                                        eventId
                                                                    ).then((serviceFeePercentage: any) => {
                                                                        res.serviceFeePercentage = serviceFeePercentage;
                                                                        resolve(res);
                                                                    });
                                                                });
                                                            }
                                                        );
                                                    }
                                                });
                                            } else {
                                                this.checkCustomerAndEventLevelCurrency(customerId, ev).then(
                                                    (resp: any) => {
                                                        this.currency = resp;
                                                        resp.bankAcc = acc;
                                                        this.getConvertedServiceFee(resp).then((res: any) => {
                                                            this.getTicketFeePercentage(
                                                                ev.app_id || null,
                                                                customerId,
                                                                eventId
                                                            ).then((serviceFeePercentage: any) => {
                                                                res.serviceFeePercentage = serviceFeePercentage;
                                                                resolve(res);
                                                            });
                                                        });
                                                    }
                                                );
                                            }
                                        } else {
                                            this.currency = {
                                                name: 'South African Rand',
                                                code: 'ZAR',
                                                symbol: 'R',
                                            };

                                            this.getConvertedServiceFee(this.currency).then((res: any) => {
                                                this.getTicketFeePercentage(
                                                    ev.app_id || null,
                                                    customerId,
                                                    eventId
                                                ).then((serviceFeePercentage: any) => {
                                                    res.serviceFeePercentage = serviceFeePercentage;
                                                    resolve(res);
                                                });
                                            });
                                            // resolve({
                                            //     name: 'South African Rand',
                                            //     code: 'ZAR',
                                            //     symbol: 'R',
                                            // });
                                        }
                                    });
                            } else {
                                this.checkCustomerAndEventLevelCurrency(customerId, ev).then((resp: any) => {
                                    this.currency = resp;
                                    this.getConvertedServiceFee(resp).then((res: any) => {
                                        this.getTicketFeePercentage(ev.app_id || null, customerId, eventId).then(
                                            (serviceFeePercentage: any) => {
                                                res.serviceFeePercentage = serviceFeePercentage;
                                                resolve(res);
                                            }
                                        );
                                    });
                                    // resolve(resp);
                                });
                            }
                        });
                }
            }
        });
    }

    checkIfEventIsSoldOutORNot(customerId: string, eventId: string, formId: string) {
        return new Promise((resolve, reject) => {
            combineLatest([
                this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/eventCapacity`),
                this.Firebase.getByRef(
                    `customers/${customerId}/eventsData/${eventId}/eventSetup/registration/${formId}/availableTickets`
                ),
                this.Firebase.getByRef(`customers/${customerId}/eventsData/${eventId}/invitees`),
            ])
                .pipe(take(1))
                .subscribe((values: any) => {
                    const eventCapacity = values[0];
                    const formTicketsId = _.keys(values[1]);
                    const totalInvitees = _.keys(values[2]);

                    // if (eventCapacity === undefined || eventCapacity === null) {
                    const ticket$: any[] = [];
                    formTicketsId.forEach((id) => {
                        ticket$.push(
                            this.Firebase.getByRef(
                                `customers/${customerId}/eventsData/${eventId}/eventSetup/tickets/${id}`
                            )
                        );
                    });

                    combineLatest(ticket$)
                        .pipe(take(1))
                        .subscribe((tickets: any) => {
                            const checkIfFreeTicketAvilable = tickets.some((ticket: any) => ticket.type === 'FREE');

                            if (checkIfFreeTicketAvilable) {
                                if (eventCapacity && eventCapacity <= totalInvitees.length) {
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            } else {
                                const checkIfRemainingTicketAvailable = tickets.some(
                                    (ticket: any) => Number(ticket.remaining) > 0
                                );

                                if (checkIfRemainingTicketAvailable) {
                                    if (eventCapacity && eventCapacity <= totalInvitees.length) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                } else {
                                    resolve(true);
                                }
                            }
                        });
                });
        });
    }

    getConvertedServiceFee(currencyData: any) {
        // console.log('=====currencyData', currencyData);
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`pricing/${currencyData.code}`)
                .pipe(take(1))
                .subscribe((price: any) => {
                    if (price) {
                        if (price.override && !isNaN(price.override)) {
                            // console.log('Override Currency', price.override);
                            currencyData.lowestPriceValue = +price.override;
                            currencyData.actualPrice = +price.override / 100;
                            currencyData.zeroDecimal = price.zeroDecimal ? true : false;
                            resolve(currencyData);
                        } else if (Number(price.multiple) === 1) {
                            // console.log('Multiple of 1');
                            // currencyData.lowestPriceValue = 1 * 100;
                            // currencyData.actualPrice = 1;
                            // currencyData.zeroDecimal = price.zeroDecimal ? true : false;
                            this.getCurrencyChargesBaseOnCountry(currencyData).then((resp: any) => {
                                if (resp && resp.quotes) {
                                    // console.log('Response From the currency API LAYER', price);
                                    currencyData.lowestPriceValue = (
                                        +resp.quotes[`USD${currencyData.code}`] * 100
                                    ).toFixed(0);
                                    currencyData.actualPrice = +(+resp.quotes[`USD${currencyData.code}`]).toFixed(2);
                                    currencyData.zeroDecimal = resp.zeroDecimal ? true : false;
                                    resolve(currencyData);
                                } else {
                                    currencyData.lowestPriceValue = +price.override;
                                    currencyData.actualPrice = +price.override / 100;
                                    currencyData.zeroDecimal = price.zeroDecimal ? true : false;
                                    resolve(currencyData);
                                }
                            });
                            // resolve(currencyData);
                        } else {
                            this.getCurrencyChargesBaseOnCountry(currencyData).then((resp: any) => {
                                if (resp && resp.quotes) {
                                    // console.log('Response From the currency API LAYER', price);
                                    currencyData.lowestPriceValue = (
                                        +resp.quotes[`USD${currencyData.code}`] * 200
                                    ).toFixed(0);
                                    currencyData.actualPrice = +(+resp.quotes[`USD${currencyData.code}`] * 2).toFixed(
                                        2
                                    );
                                    currencyData.zeroDecimal = resp.zeroDecimal ? true : false;
                                    resolve(currencyData);
                                } else {
                                    currencyData.lowestPriceValue = +price.override;
                                    currencyData.actualPrice = +price.override / 100;
                                    currencyData.zeroDecimal = price.zeroDecimal ? true : false;
                                    resolve(currencyData);
                                }
                            });
                        }
                    } else {
                        resolve(null);
                    }
                });
        });
    }

    // GET PLAN AND DECIDE TICKET FEE PERCENTAGE
    getTicketFeePercentage(appId = null, customerId: string, eventId: string) {
        let planFees: any = {
            'enterprise agreement': 3,
            business: 3.5,
            'event pro': 6,
            expert: 3,
            solo: 7,
            pro: 5,
        };
        return new Promise((resolve, reject) => {
            combineLatest([
                // this.Firebase.getByRef(`pricePlan`),
                this.stripe.getProducts(),
            ])
                .pipe(take(1))
                .subscribe((values: any) => {
                    const plans = values[0] || [];
                    this.checkoutService.checkEventPlanAndSubscription(appId, eventId, customerId).then((resp: any) => {
                        // console.log('====resp', resp);
                        // console.log('====plan', plans);
                        if (
                            !resp ||
                            (resp?.status !== 'active' && resp?.status !== 'trialing') ||
                            resp.needToRedirectCustomerPortal
                        ) {
                            resolve(planFees['solo']);
                        } else {
                            const planId = resp?.plan?.product;
                            // console.log('=====', planId);
                            const plan: any = plans.find((p: any) => p.id === planId)?.name?.toLowerCase();
                            // console.log('=====plan', plan);
                            if (plan) {
                                resolve(planFees[plan?.includes('enterprise') ? 'enterprise agreement' : plan]);
                            } else {
                                resolve(planFees['solo']);
                            }
                        }
                    });
                });
        });
    }

    setPricePlan() {
        const prepareData = {
            flex: {
                HelloCrowdPrice: 499,
                customBrandedAppPrice: 1499,
                serviceFeePercentage: 6,
            },
            pro: {
                HelloCrowdPrice: 199,
                customBrandedAppPrice: 499,
                serviceFeePercentage: 3,
            },
            enterprise: {
                serviceFeePercentage: 2.5,
            },
        };

        return new Promise((resolve, reejct) => {
            this.Firebase.updateByRef(`pricePlan`, prepareData);
        });
    }

    getCurrencyChargesBaseOnCountry(currencyData: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${environment.fcfApiUrl}/v2api/get-converted-currency`, { currency: currencyData.code })
                .subscribe((price: any) => {
                    console.log('===price', price);
                    resolve(price);
                });
            // this.http
            //     .get(
            //         `${environment.currencyEndPoint}?access_key=${environment.currencyAccessKey}&currencies=${currencyData.code}&source=USD`,
            //         {
            //             headers: {
            //                 'Access-Control-Allow-Headers':
            //                     'Content-Type, Authorization, Content-Length, X-Requested-With',
            //                 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            //                 'Access-Control-Allow-Origin': '*',
            //             },
            //         }
            //     )
            //     .subscribe((price: any) => {
            //         resolve(price);
            //         // if (price) {
            //         //     console.log('Response From the currency API LAYER', price);
            //         //     currencyData.lowestPriceValue = (+price.quotes[`USD${currencyData.code}`] * 100).toFixed(0);
            //         //     currencyData.actualPrice = +price.quotes[`USD${currencyData.code}`];
            //         //     currencyData.zeroDecimal = price.zeroDecimal ? true : false;
            //         //     resolve(currencyData);
            //         // }
            //     });
        });
    }

    checkCustomerAndEventLevelCurrency(customerId: any, ev: any) {
        return new Promise((resolve, reject) => {
            if (ev.currency) {
                // console.log('EVENT LEVEL CURRENCY');

                // const regex =
                //     /[A-Za-z]f[A-Za-z][A-Za-z][A-Za-z][A-Za-z]\s[A-Za-z][A-Za-z][A-Za-z][A-Za-z][A-Za-z][A-Za-z][A-Za-z]\s\([A-Za-z][A-Za-z][A-Za-z]\)/i;
                // console.log(regex.test(ev.currency));
                // if (regex.test(ev.currency)) {
                //     const currency =
                //         ev.currency
                //             .replace(/[{()}]/g, '')
                //             .split(' ')
                //             .pop()
                //             .toLowerCase() || 'usd';

                //     this.utilityService.getCurrencySymbol(currency, false).then((currency: any) => {
                //         console.log('currency', currency);
                //         // this.currency = currency;
                //         resolve(currency);
                //     });
                // } else {
                // }
                // resolve(ev.cu)
                this.utilityService.getCurrencySymbol(ev.currency, false).then((currency: any) => {
                    // console.log('currency', currency);
                    // this.currency = currency;
                    resolve(currency);
                });
            } else {
                // console.log('NEED TO CHECK CUSTOMER LEVEL CURRENCY');
                this.Firebase.getByRef(`customers/${customerId}/defaultCurrency`)
                    .pipe(take(1))
                    .subscribe((defaultCurrency: any) => {
                        // console.log('DEFAULR CURRENCY ====', defaultCurrency);
                        if (defaultCurrency) {
                            if (defaultCurrency === 'ZA' || defaultCurrency === 'US') {
                                // this.currency = {
                                //     name: 'South African Rand',
                                //     code: 'ZAR',
                                //     symbol: 'R',
                                // };

                                if (defaultCurrency === 'ZA') {
                                    resolve({
                                        name: 'South African Rand',
                                        code: 'ZAR',
                                        symbol: 'R',
                                    });
                                } else {
                                    resolve({
                                        name: 'United States dollar',
                                        code: 'USD',
                                        symbol: '$',
                                    });
                                }
                            } else {
                                this.utilityService.getCurrencySymbol(defaultCurrency, false).then((currency: any) => {
                                    // console.log('currency', currency);
                                    // this.currency = currency;
                                    resolve(currency);
                                });
                            }
                        } else {
                            this.utilityService.getCurrencySymbol('USD', false).then((currency: any) => {
                                // console.log('currency', currency);
                                resolve(currency);
                            });
                        }
                    });
            }
        });
    }

    getCustomerLogo(customerId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/companyLogo`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val || '');
                });
        });
    }

    getCustomerLogoDark(customerId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/companyLogoDark`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val || '');
                });
        });
    }

    getCustomerCountry(customerId: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/defaultCountry`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val);
                });
        });
    }

    getCustomerCompany(customerId: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/companyName`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val);
                });
        });
    }

    getCustomerTimeZone(customerId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/defaultTimeZone`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val as string);
                });
        });
    }

    getCustomerLevelCurrency(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/defaultCurrency`).pipe(
            take(1),
            switchMap((currency: any): any => {
                if (currency) {
                    if (currency === 'ZA' || currency === 'US') {
                        if (currency === 'ZA') {
                            return of({
                                name: 'South African Rand',
                                code: 'ZAR',
                                symbol: 'R',
                            });
                        } else {
                            return of({
                                name: 'United States dollar',
                                code: 'USD',
                                symbol: '$',
                            });
                        }
                    } else {
                        return from(
                            this.utilityService.getCurrencySymbol(currency, false).then((currency: any) => {
                                return currency;
                            })
                        );
                    }
                } else {
                    return from(
                        this.utilityService.getCurrencySymbol('USD', false).then((currency: any) => {
                            return currency;
                        })
                    );
                }
            })
        );
    }

    getAllEloquaData(customerId: string) {
        // const elouqasData: any = [];
        return this.Firebase.getByRef(`customers/${customerId}/integrations/eloquasData`).pipe(
            map((resp: any) => {
                const elouqasData: any = [];
                if (resp) {
                    const keys = _.keys(resp);

                    keys.forEach((k: string) => {
                        // console.log('k', k);
                        resp[k].id = k;
                        if (!resp[k].title) {
                            resp[k].title = 'Oracle Eloqua';

                            elouqasData.push(resp[k]);
                        } else {
                            elouqasData.push(resp[k]);
                        }
                    });
                    return elouqasData;
                } else {
                    return [];
                }
            })
        );
    }

    getEloquaDataById(customerId: string, id: any) {
        const eloquaId = id ? id : '';
        console.log('======', eloquaId);
        if (id) {
            return this.Firebase.getByRef(`customers/${customerId}/integrations/eloquasData/${eloquaId}`).pipe(
                // take(1),
                map((resp: any) => {
                    if (resp) {
                        resp.id = id;
                        return resp;
                    } else {
                        return null;
                    }
                })
            );
        } else {
            return of(null);
        }
    }

    generateEloquaId(customerId: string) {
        return new Promise((resolve) => {
            const ref = this.db.database.ref();
            const newRef = ref.child(`customers/${customerId}/integrations/eloquasData`).push();
            const key = newRef.key as string;
            resolve(key);
            // this.db.database
            //     .ref()
            //     .child(`customers/${customerId}/integrations/eloquasData/${key}`)
            //     .once('value', (data: any) => {
            //         if (data.val()) {
            //             resolve(data.val());
            //         } else {
            //             resolve(key);
            //         }
            //     });
        });
    }

    getEloquaById(customerId: string, id: string) {
        console.log('getEloqua Id Called');
        this.db.database
            .ref()
            .child(`customers/${customerId}/integrations/eloquasData/${id}`)
            .on('value', (data: any) => {
                this.$connectedEloqua.next(data.val() || false);
            });
    }

    disConnectEloquaRef(customerId: string, id: string) {
        this.db.database.ref().child(`customers/${customerId}/integrations/eloquasData/${id}`).off();
    }

    checkIfBankAccountAdded(customerId: string, eventId: string, isCheckForPageMenu = false) {
        return combineLatest([
            this.Firebase.getByRef(`/customers/${customerId}/eventsData/${eventId}/eventSetup/tickets`),
            this.Firebase.getByRef(`/customers/${customerId}/eventsData/${eventId}/eventSetup/causes`),
            this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/bankId`),
        ]).pipe(
            take(1),
            switchMap((values: any): any => {
                const ticketKeys = _.keys(values[0]);
                const checkIfPaidORDonationTicketAvailabel = ticketKeys.some(
                    (k: any) => values[0][k].type === 'PAID' || values[0][k].type === 'DONATION'
                );
                if (!isCheckForPageMenu) {
                    if (checkIfPaidORDonationTicketAvailabel || values[1]) {
                        if (!values[2]) return of(false);
                        else {
                            return (
                                this.Firebase.getByRef(
                                    `/customers/${customerId}/bankDetails/${values[2]}`
                                ) as Observable<any>
                            ).pipe(
                                map((backAcc): any => {
                                    if (backAcc?.accType === 'stripe') {
                                        return !backAcc?.isOnBoardingCompleted ? false : true;
                                    } else {
                                        // console.log('PAY STACK ACCOUNT');
                                        return true;
                                    }
                                })
                            );
                        }
                    } else {
                        return of(true);
                    }
                } else {
                    if (checkIfPaidORDonationTicketAvailabel || values[1]) {
                        return of(true);
                    } else {
                        return of(false);
                    }
                }
            })
        );
    }

    checkIsUserOwner() {
        return new Promise((resolve, reject) => {
            if (this.isOwner !== undefined) {
                resolve(this.isOwner);
            }
            if (!this.userId) this.userId = localStorage.getItem('user_id') || '';

            if (!this.customerId) {
                this.customerId = localStorage.getItem('user_customer_id')!;
            }
            const customerId = localStorage.getItem('user_customer_id');
            const ref = `customers/${customerId}/teamMembers`;
            this.Firebase.getByRef(ref)
                .pipe(first())
                .subscribe((data: any) => {
                    if (data) {
                        this.isOwner = data[this.userId] ? data[this.userId].toLowerCase() === 'owner' : false;
                        resolve(this.isOwner);
                    } else {
                        this.isOwner = false;
                        resolve(this.isOwner);
                    }
                });
        });
    }

    getAllActiveBanks(customerId = '') {
        return new Promise((resolve, reject) => {
            // this.customerId = localStorage.getItem('')
            const cId = localStorage.getItem('customer_id') || this.Cookies.get('customer_id');
            const ref = `customers/${customerId || cId}/bankDetails`;

            // console.log('=============this.customerId', this.customerId);
            this.Firebase.getByRef(ref)
                .pipe(first())
                .subscribe((data: any) => {
                    const retArr: any = [];
                    if (data) {
                        console.log(' ================== bank data ==============>>>', data);

                        _.forOwn(data, (bank, key) => {
                            // if (bank.active) {
                            if (bank.accType === 'stripe') {
                                console.log(' ======= bank stripe');

                                if (bank.isOnBoardingCompleted) {
                                    console.log(' ======================== >>>>>>>push bank  ', bank);

                                    bank.currency = bank.defaultCurrency;
                                    retArr.push({ ...bank, id: key });
                                }
                            } else if (bank.active) {
                                retArr.push({ ...bank, id: key });
                            }
                            // }
                        });
                    }
                    resolve(retArr);
                });
        });
    }

    getBankDetails(customerId = '') {
        const ref = `customers/${customerId || this.customerId}/bankDetails`;
        return this.Firebase.getByRef(ref);
    }

    validAccountToDelete(bankId: string, customerId = '') {
        return new Promise((resolve, reject) => {
            const ref = `customers/${customerId || this.customerId}/events`;
            this.Firebase.getByRefWhere(ref, 'bankId', bankId).then((data: any) => {
                if (!data) {
                    resolve(true);
                } else {
                    let canDelete = true;
                    _.forOwn(data, (event, eventId) => {
                        console.log('EVENT END DATE VALUEOF', moment(event.end_date_time_unix).valueOf());
                        console.log('CURRENT DATE VALUEOF', moment().valueOf());
                        console.log('DIFFENRECE', moment(event.end_date_time_unix).diff(moment().valueOf()));

                        if (moment(event.end_date_time_unix).diff(moment().valueOf()) > 0) {
                            canDelete = false;
                        }
                    });
                    resolve(canDelete);
                }
            });
        });
    }

    deleteBankAccount(data: any, customerId: string) {
        return new Promise((resolve) => {
            if (data?.accId) {
                // console.log(' ======= stripe details ==============', data?.accId);
                this.http
                    .delete(`${environment.fcfApiUrl}/v2payments/stripe/delete/${data?.accId}`)
                    .subscribe((resp) => {
                        // console.log('===== deleted stripe account ', resp);
                        this.Firebase.removeByRef(`customers/${customerId}/bankDetails/${data.id}`).then(() => {
                            resolve(true);
                        });
                    });
            } else {
                this.http
                    .put(`${environment.fcfApiUrl}/v2payments/paystack/updatesubAcc/${data.subaccount_code}`, {
                        active: false,
                    })
                    .subscribe((resp) => {
                        this.Firebase.updateByRef(`customers/${customerId}/bankDetails/${data.id}`, {
                            active: false,
                        }).then(() => {
                            resolve(true);
                        });
                    });
            }
        });
    }

    checkHaveRecievedPaymenets(eventId: string): Promise<boolean> {
        const donationsRef = `customers/${this.customerId}/eventsData/${eventId}/donations`;
        const transactionsRef = `customers/${this.customerId}/eventsData/${eventId}/transactions`;
        return new Promise((resolve, reject) => {
            combineLatest([this.Firebase.getByRef(donationsRef), this.Firebase.getByRef(transactionsRef)])
                .pipe(first())
                .subscribe((res) => {
                    if (res[0] || res[1]) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
    }

    updateBrandKit(data: any) {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const eventData = this.prepareBrandKitData(data);
        const listRef = `customers/${customerId}/brandKit`;
        return new Promise((resolve, reject) => {
            this.db
                .object(listRef)
                .update(eventData)
                .then(() => {
                    resolve({ data: data });
                })
                .catch((e) => {
                    console.log('ERROR', Error);
                    reject(e);
                });
        });
    }

    prepareBrandKitData(data: any) {
        return {
            colourScheme: data.colourScheme || '#cccccc',
            // colourSchemeSecondary: data.colourScheme || '#00b4ce',
            icon: data.icon || null,
            iconSmall: data.iconSmall || null,
            splashScreen: data.splashScreen || null,
            splashScreenSmall: data.splashScreenSmall || null,
            artworksType: data.artworksType || 'standard',
            uploadedArtwork: data.uploadedArtwork || null,
            uploadedArtworkTransparent: data.uploadedArtworkTransparent || null,
            colours: data.colours || null,
            style: data.style || null,
            headerFonts: data.headerFonts || null,
            bodyFonts: data.bodyFonts || null,
            lightCustomerLogo: data.lightCustomerLogo || null,
            darkCustomerLogo: data.darkCustomerLogo || null,
            headerFontFile: data.headerFontFile || null,
            bodyFontFile: data.bodyFontFile || null,
        };
    }

    getBrandKitData(cId = '') {
        const customerId = cId || localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const listRef = `customers/${customerId}/brandKit`;
        return this.Firebase.getByRef(listRef) as Observable<BrandKit>;
    }

    getBasePlanSubscriptions(customerId = '') {
        return new Promise((resolve) => {
            this.Firebase.getByRefWhere(`customers/${customerId || this.customerId}/subscriptions`, 'appId', null).then(
                (suRes: any) => {
                    if (suRes) {
                        const sub = _.values(suRes)[0];
                        this.stripe
                            .getSubData(sub.subscriptionId)
                            .pipe(take(1))
                            .subscribe((res: any) => {
                                if (res?.status === 'active' || res?.status === 'trialing') {
                                    resolve({
                                        data: sub,
                                        status: 'ACTIVE',
                                    });
                                } else {
                                    resolve({
                                        data: sub,
                                        status: 'INACTIVE',
                                    });
                                }
                            });
                    } else {
                        resolve({
                            status: 'UNSUBSCRIBED',
                        });
                    }
                }
            );
        });
    }

    getStoreAndUpdateAudience(customerId: string, prepareData: any, id = '') {
        const cId = customerId || localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        return new Promise((resolve, reject) => {
            if (prepareData?.email) {
                this.Firebase.getByRefWhere(`customers/${cId}/audience`, 'email', prepareData.email).then(
                    (val: any) => {
                        if (val) {
                            const audienceId = _.keys(val);
                            let audienceData: any = {};
                            let matchingId: string;

                            const findMatchDataId = audienceId.filter(
                                (id: string) => val[id].attendeeType === prepareData.attendeeType
                            )[0];

                            if (findMatchDataId) {
                                matchingId = findMatchDataId;
                                audienceData = val[findMatchDataId];
                            } else {
                                const findWithoutTypeId = audienceId.filter(
                                    (id: string) => !val[id].attendeeType && prepareData.attendeeType === 'Attendee'
                                )[0];

                                matchingId = findWithoutTypeId;

                                audienceData = val[findWithoutTypeId];
                            }

                            if (matchingId && audienceData) {
                                if (prepareData.firstAccessed) {
                                    prepareData.accessEvents = {
                                        ...audienceData.accessEvents,
                                        [prepareData.appId]: {
                                            ...audienceData.accessEvents?.[prepareData.appId],
                                            [prepareData.eventId]: prepareData.firstAccessed,
                                        },
                                    };
                                }

                                const finalData = _.omit(prepareData, ['eventId', 'firstAccessed', 'appId']);
                                console.log('AUDIUNCE FOUND!!:::::::', matchingId);
                                this.Firebase.updateByRef(`customers/${cId}/audience/${matchingId}`, finalData).then(
                                    () => {
                                        console.log('USER SUCCESSFULLY UPDATED!!');
                                        resolve(true);
                                    }
                                );
                            } else {
                                if (prepareData.firstAccessed) {
                                    prepareData.accessEvents = {
                                        [prepareData.appId]: {
                                            [prepareData.eventId]: prepareData.firstAccessed,
                                        },
                                    };
                                }

                                const finalData = _.omit(prepareData, ['eventId', 'firstAccessed', 'appId']);

                                this.Firebase.pushByRef(`customers/${cId}/audience`, finalData).then(() => {
                                    console.log('User Successfully Created !!');
                                    resolve(true);
                                });
                            }

                            // const audienceId = _.keys(val)[0];
                            // const audienceData = _.values(val)[0];
                            // if (prepareData.firstAccessed) {
                            //     prepareData.accessEvents = {
                            //         ...audienceData.accessEvents,
                            //         [prepareData.appId]: {
                            //             ...audienceData.accessEvents?.[prepareData.appId],
                            //             [prepareData.eventId]: prepareData.firstAccessed,
                            //         },
                            //     };
                            //     // if (audienceData.accessEvents) {
                            //     // } else {
                            //     // }
                            // }

                            // const finalData = _.omit(prepareData, ['eventId', 'firstAccessed', 'appId']);
                            // console.log('AUDIUNCE FOUND!!:::::::', audienceId);
                            // this.Firebase.updateByRef(`customers/${cId}/audience/${audienceId}`, finalData).then(() => {
                            //     console.log('USER SUCCESSFULLY UPDATED!!');
                            //     resolve(true);
                            // });
                        } else {
                            if (prepareData.firstAccessed) {
                                prepareData.accessEvents = {
                                    [prepareData.appId]: {
                                        [prepareData.eventId]: prepareData.firstAccessed,
                                    },
                                };
                            }

                            const finalData = _.omit(prepareData, ['eventId', 'firstAccessed', 'appId']);

                            if (id) {
                                this.Firebase.updateByRef(`customers/${cId}/audience/${id}`, finalData).then(() => {
                                    console.log('User Successfully Created !!');
                                    resolve(true);
                                });
                            } else {
                                this.Firebase.pushByRef(`customers/${cId}/audience`, finalData).then(() => {
                                    console.log('User Successfully Created !!');
                                    resolve(true);
                                });
                            }
                        }
                    }
                );
            } else {
                resolve(true);
            }
        });
    }

    getAllCRMData(pageName: string) {
        const cId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        return new Promise((resolve, reject) => {
            if (pageName === 'attendee' && this.attendeeList.length) {
                resolve(this.attendeeList);
            } else if (pageName === 'speaker' && this.speakerList.length) {
                resolve(this.speakerList);
            } else {
                // console.log('======this.customerId', cId);
                this.Firebase.getByRef(`customers/${cId}/audience`)
                    .pipe(first())
                    .subscribe((audience: any) => {
                        const list: any = [];
                        if (audience) {
                            const ids = _.keys(audience);
                            ids.forEach((id: string) => {
                                let preparedAudience: any = {};
                                const data = audience[id];
                                if (data.company) {
                                    preparedAudience.company = data.company;
                                }

                                if (data.email) {
                                    preparedAudience.email_address = data.email;
                                }

                                if (data.firstName) {
                                    preparedAudience.firstName = data.firstName;
                                }

                                if (data.jobTitle) {
                                    preparedAudience.sub_title = data.jobTitle;
                                }

                                if (data.lastName) {
                                    preparedAudience.lastName = data.lastName;
                                }
                                if (data.mobilePhone) {
                                    try {
                                        preparedAudience.mobile_number = parsePhoneNumber(
                                            data.mobilePhone
                                        )?.formatInternational();
                                    } catch (err) {
                                        //
                                    }
                                }

                                if (data.title) {
                                    preparedAudience.title = data.title;
                                }

                                if (!data.title && (data.firstName || data.lastName)) {
                                    preparedAudience.title = (data.firstName + ' ' + data.lastName)?.trim();
                                }

                                if (data.imgUrl) {
                                    preparedAudience.icon = data.imgUrl;
                                }

                                if (data.title && (!data.firstName || !data.lastName)) {
                                    preparedAudience.firstName = data.title.split(' ')[0].trim();
                                    preparedAudience.lastName = data.title.split(' ')[1]?.trim() || null;
                                }
                                if (data.website) {
                                    preparedAudience.website_address = data.website;
                                }

                                if (data.description) {
                                    preparedAudience.description = data.description;
                                }

                                preparedAudience.attendeeType = data.attendeeType;

                                preparedAudience.id = id;

                                // const socialMediaData = this._.omit(data, [
                                //     'company',
                                //     'website_address',
                                //     'title',
                                //     'lastName',
                                //     'mobile_number',
                                //     'sub_title',
                                //     'firstName',
                                //     'email_address',
                                //     'company',
                                //     'id',
                                //     'page_id',
                                //     'show_email',
                                //     'description',
                                //     'icon',
                                //     'app_rating',
                                //     'designation',
                                //     'first_accessed',
                                //     'icon_small',
                                //     'facebook_account',
                                //     'instagram_account',
                                //     'linkedin_account',
                                //     'twitter_account',
                                //     'youtube_account',
                                // ]);

                                if (pageName === 'attendee') {
                                    let transformData: any = this.attendeeTrasnformer.transformSingle(preparedAudience);

                                    transformData.socialProfiles = [];
                                    if (data.socialMediaData) {
                                        const keys = Object.keys(data.socialMediaData);
                                        for (const key of keys) {
                                            transformData.socialProfiles.push({
                                                profileName: key,
                                                profileId: data.socialMediaData[key].profileId,
                                                sanitize: data.socialMediaData[key].sanitize,
                                            });
                                        }
                                    }

                                    transformData = {
                                        ...transformData,
                                        ...data.socialMediaData,
                                    };

                                    list.push(transformData);
                                }

                                if (pageName === 'speaker') {
                                    preparedAudience.socialProfiles = [];
                                    if (data.socialMediaData) {
                                        const keys = Object.keys(data.socialMediaData);
                                        for (const key of keys) {
                                            preparedAudience.socialProfiles.push({
                                                profileName: key,
                                                profileId: data.socialMediaData[key].profileId,
                                                sanitize: data.socialMediaData[key].sanitize,
                                            });
                                        }
                                    }

                                    preparedAudience = {
                                        ...preparedAudience,
                                        ...data.socialMediaData,
                                    };
                                    list.push(preparedAudience);
                                }
                            });

                            if (pageName === 'attendee') this.attendeeList = list;
                            if (pageName === 'speaker') this.speakerList = list;

                            resolve(list);
                        } else {
                            resolve(list);
                        }
                    });
            }
        });
    }

    addSubAccounts(item: any) {
        return new Promise((resolve) => {
            const subAccountRef = `customers/${this.customerId}/subAccounts`;
            this.Firebase.pushByRef(subAccountRef, {
                name: item.name,
            }).then((subAccountKey) => {
                item.id = subAccountKey;
                resolve(item);
            });
        });
    }

    getCustomersSubAccounts(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/subscription`).pipe(
            take(1),
            map((subcription: any): any => {
                if (subcription?.type === 'enterprise') {
                    return true;
                } else {
                    return null;
                }
            }),
            switchMap((value): any => {
                if (value) {
                    return this.Firebase.getByRef(`customers/${customerId}/subAccounts`).pipe(
                        take(1),
                        map((resp: any): any => {
                            if (resp) {
                                const returnData: SelectItem[] = [];
                                _.forOwn(resp, (value: any, key) => {
                                    returnData.push({
                                        value: value.name,
                                        label: value.name,
                                        id: key,
                                    });
                                });

                                return {
                                    subAccounts: returnData,
                                    isEnterPriseCustomer: true,
                                };
                            } else {
                                return {
                                    subAccounts: [],
                                    isEnterPriseCustomer: true,
                                };
                            }
                        })
                    );
                } else {
                    return of(null);
                }
            })
        );
    }

    getTeammemberSubAccount(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/subscription`).pipe(
            take(1),
            map((subcription: any): any => {
                if (subcription?.type === 'enterprise') {
                    return true;
                } else {
                    return null;
                }
            }),
            switchMap((value): any => {
                if (value) {
                    return this.Firebase.getByRef(`customers/${customerId}/teamMembersSubAccounts`).pipe(
                        take(1),
                        map((resp: any): any => {
                            console.log('=====reps', resp);
                            return resp;
                            // if (resp) {
                            //     const returnData: SelectItem[] = [];
                            //     _.forOwn(resp, (value: any, key) => {
                            //         returnData.push({
                            //             value: value.name,
                            //             label: value.name,
                            //             id: key,
                            //         });
                            //     });
                            //     return {
                            //         subAccounts: returnData,
                            //         isEnterPriseCustomer: true,
                            //     };
                            // } else {
                            //     return {
                            //         subAccounts: [],
                            //         isEnterPriseCustomer: true,
                            //     };
                            // }
                        })
                    );
                } else {
                    return of(null);
                }
            })
        );
    }

    getCustomDictionaryData(isNeedToCalledDefaultData = true, customerId = '') {
        const standardFields = [
            'Prefix (Mr, Mrs, Dr, Prof)',
            'Suffix',
            'Birthday',
            'Mobile phone',
            'Home phone',
            'Home Address',
            'Shipping Address',
            'Website',
            'Job Title',
            'Company / Organisation',
            'Work Address',
            'Work Phone',
            'VAT / Tax Number',
            'First name',
            'Last name',
            'Email Address',
        ];

        const questionIcon: any = {
            TEXT: 'text',
            NUMBER: 'number',
            DATE: 'calendar',
            'PHONE NUMBER': 'phone',
            'WEBSITE URL': 'website-link',
            'EMAIL ADDRESS': 'email',
            COUNTRY: 'country',
            DROPDOWN: 'dropdown',
        };

        const editQuestionType: any = {
            TEXT: 'Text',
            NUMBER: 'Number',
            DATE: 'Date',
            'PHONE NUMBER': 'Phone Number',
            'WEBSITE URL': 'Website URL',
            'EMAIL ADDRESS': 'Email Address',
            COUNTRY: 'Country',
            DROPDOWN: 'Dropdown',
        };

        // console.log('==============this.customerId', this.customerId);
        // console.log('=====dsbsjdb============', customerId);
        const cId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        // console.log('=======', cId);

        return this.Firebase.getByRef(`customers/${cId || this.customerId}/customDictionary`).pipe(
            take(1),
            map((customDictionary: any) => {
                if (isNeedToCalledDefaultData) {
                    if (customDictionary) {
                        let standardList: any[] = [];
                        const customFields: any[] = [];
                        if (customDictionary.standardFieldsWithCustomTitle) {
                            const keys = _.keys(customDictionary.standardFieldsWithCustomTitle);

                            keys.forEach((k) => {
                                if (k === 'company') {
                                    standardList.push({
                                        standardName: 'Company / Organisation',
                                        customName: customDictionary.standardFieldsWithCustomTitle[k],
                                    });
                                } else if (k === 'vatTaxNumber') {
                                    standardList.push({
                                        standardName: 'VAT / Tax Number',
                                        customName: customDictionary.standardFieldsWithCustomTitle[k],
                                    });
                                } else if (k === 'prefix') {
                                    standardList.push({
                                        standardName: 'Prefix (Mr, Mrs, Dr, Prof)',
                                        customName: customDictionary.standardFieldsWithCustomTitle[k],
                                    });
                                } else {
                                    const result = k.replace(/([A-Z])/g, ' $1');
                                    standardList.push({
                                        standardName:
                                            result
                                                .replace(/([A-Z])/g, ' $1')
                                                .charAt(0)
                                                .toUpperCase() + result.slice(1),
                                        customName: customDictionary.standardFieldsWithCustomTitle[k],
                                    });
                                }
                            });
                        } else {
                            standardFields.forEach((field: string) => {
                                standardList.push({
                                    standardName: field,
                                    customName: '',
                                });
                            });
                        }

                        if (customDictionary.customFields) {
                            _.forOwn(customDictionary.customFields, (val, key) => {
                                const customFieldOptions: any[] = [];
                                if (val.options) {
                                    _.forOwn(val.options, (option: any, id: string) => {
                                        customFieldOptions.push({
                                            title: option.title,
                                            id: id,
                                        });
                                    });

                                    val.options = customFieldOptions;
                                }

                                customFields.push({
                                    ...val,
                                    id: key,
                                    customField: val.title,
                                    fieldType: editQuestionType[val.type],
                                    icon: questionIcon[val.type],
                                });
                            });
                        }

                        return {
                            standardList: standardList,
                            customList: customFields.length ? customFields : null,
                        };
                    } else {
                        const standardList: any[] = [];
                        standardFields.forEach((field: string) => {
                            standardList.push({
                                standardName: field,
                                customName: '',
                            });
                        });

                        return {
                            standardList: standardList,
                        };
                    }
                } else {
                    if (customDictionary) {
                        const standardList: any[] = [];
                        const customFields: any[] = [];
                        if (customDictionary.standardFieldsWithCustomTitle) {
                            const keys = _.keys(customDictionary.standardFieldsWithCustomTitle);

                            keys.forEach((k) => {
                                if (k === 'company') {
                                    standardList.push({
                                        standardName: 'Company / Organisation',
                                        customName: customDictionary.standardFieldsWithCustomTitle[k],
                                    });
                                } else if (k === 'vatTaxNumber') {
                                    standardList.push({
                                        standardName: 'VAT / Tax Number',
                                        customName: customDictionary.standardFieldsWithCustomTitle[k],
                                    });
                                } else if (k === 'prefix') {
                                    standardList.push({
                                        standardName: 'Prefix (Mr, Mrs, Dr, Prof)',
                                        customName: customDictionary.standardFieldsWithCustomTitle[k],
                                    });
                                } else {
                                    const result = k.replace(/([A-Z])/g, ' $1');
                                    standardList.push({
                                        standardName:
                                            result
                                                .replace(/([A-Z])/g, ' $1')
                                                .charAt(0)
                                                .toUpperCase() + result.slice(1),
                                        customName: customDictionary.standardFieldsWithCustomTitle[k],
                                    });
                                }
                            });
                        }

                        if (customDictionary.customFields) {
                            _.forOwn(customDictionary.customFields, (val, key) => {
                                const customFieldOptions: any[] = [];
                                if (val.options) {
                                    _.forOwn(val.options, (option: any, id: string) => {
                                        customFieldOptions.push({
                                            title: option.title,
                                            id: id,
                                        });
                                    });

                                    val.options = customFieldOptions;
                                }

                                customFields.push({
                                    ...val,
                                    id: key,
                                    customField: val.title,
                                    fieldType: editQuestionType[val.type],
                                    icon: questionIcon[val.type],
                                });
                            });
                        }

                        return {
                            standardList: standardList,
                            customList: customFields.length ? customFields : null,
                        };
                    } else {
                        return null;
                    }
                }
            })
        );
    }

    updateCustomDictionaryStandardField(data: any, customerId = '') {
        return this.Firebase.updateByRef(`customers/${customerId}/customDictionary`, {
            standardFieldsWithCustomTitle: data,
        });
    }

    createUpdateAndStoreCustomField(data: any, customerId: string) {
        return new Promise((resolve, reject) => {
            if (data.id) {
                this.updateCustomField(data, customerId).then((resp) => {
                    resolve(resp);
                });
            } else {
                this.createCustomField(data, customerId).then((reps) => {
                    resolve(reps);
                });
            }
        });
    }

    removeCustomField(id: any, customerId: string) {
        return this.Firebase.removeByRef(`customers/${customerId}//customDictionary/customFields/${id}`);
    }

    createCustomField(data: any, customerId: string) {
        return new Promise((resolve) => {
            if (data.options.length) {
                const options = data.options;
                let prepareOptions = {};
                const optionsPromise: any[] = [];

                this.Firebase.pushByRef(
                    `customers/${customerId}/customDictionary/customFields`,
                    _.omit(data, ['options'])
                ).then((key) => {
                    data.id = key;

                    options.forEach((op: any) => {
                        if (op.title) {
                            optionsPromise.push(this.createOptions(op, data.id, customerId));
                        }
                    });

                    Promise.all(optionsPromise).then((op: any) => {
                        op.forEach((o: any) => {
                            prepareOptions = {
                                ...prepareOptions,
                                [o.id]: {
                                    title: o.title,
                                },
                            };
                        });

                        data.options = prepareOptions;

                        resolve(data);
                    });
                });
            } else {
                this.Firebase.pushByRef(`customers/${customerId}/customDictionary/customFields`, _.omit(data)).then(
                    (key) => {
                        data.id = key;
                        resolve(data);
                    }
                );
            }
        });
    }

    updateCustomField(data: any, customerId: string) {
        return new Promise((resolve, reject) => {
            const id = data.id;
            if (data.options.length) {
                const options = data.options;
                let prepareOptions = {};
                const optionsPromise: any[] = [];

                options.forEach((op: any) => {
                    if (op.id) {
                        prepareOptions = {
                            ...prepareOptions,
                            [op.id]: {
                                title: op.title,
                            },
                        };
                    } else {
                        if (op.title) {
                            optionsPromise.push(this.createOptions(op, data.id, customerId));
                        }
                    }
                });

                Promise.all(optionsPromise).then((op: any) => {
                    op.forEach((o: any) => {
                        prepareOptions = {
                            ...prepareOptions,
                            [o.id]: {
                                title: o.title,
                            },
                        };
                    });

                    data.options = prepareOptions;

                    this.Firebase.updateByRef(
                        `customers/${customerId}/customDictionary/customFields/${data.id}`,
                        _.omit(data, ['id'])
                    ).then(() => {
                        data.id = id;
                        resolve(data);
                    });
                });
            } else {
                this.Firebase.updateByRef(
                    `customers/${customerId}/customDictionary/customFields/${data.id}`,
                    _.omit(data, ['id'])
                ).then(() => {
                    data.id = id;
                    resolve(data);
                });
            }
        });
    }

    createOptions(option: any, fieldId: string, customerId: string) {
        return new Promise((resolve, reject) => {
            (
                this.Firebase.pushByRef(`customers/${customerId}/customDictionary/customFields/${fieldId}/options`, {
                    title: option.title,
                }) as Promise<string>
            ).then((key: string) => {
                resolve({
                    title: option.title,
                    id: key,
                });
            });
        });
    }

    getTeamMembers() {
        this.customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        let teamMembersId: any;
        let _teamMembers: any;
        return this.Firebase.getByRef(`customers/${this.customerId}/teamMembers`).pipe(
            take(1),
            map((teamMembers: any): any => {
                // console.log('=======teamMembers', teamMembers);
                // customerData = customer;
                _teamMembers = teamMembers;
                teamMembersId = _.keys(teamMembers);
                const users$: Observable<User>[] = [];
                teamMembersId.forEach((tId: string) => {
                    users$.push(this.Firebase.getDbUserByUserId(tId));
                });

                return users$;
            }),
            switchMap((users: any): any => {
                return combineLatest(users).pipe(
                    take(1),
                    map((user: any) => {
                        const roles: any = {
                            owner: 'Account Owner',
                            admin: 'Admin',
                            publisher: 'Publisher',
                            contibutor: 'Contibutor',
                        };
                        user.forEach((u: User) => {
                            u.name = (u.first_name || '') + ' ' + (u.last_name || '');
                            u.role = _teamMembers?.[u.id];
                        });

                        return {
                            user,
                        };
                    })
                );
            })
        );
    }

    storeSenderSignatureData(data: any) {
        return new Promise((resolve, reject) => {
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            const ref = `customers/${customerId}/senderSignatures`;
            this.Firebase.updateByRef(ref, data).then((id) => {
                resolve(id);
            });
        });
    }

    removeSenderSignatureData(domainId: number | undefined) {
        return new Promise((resolve, reject) => {
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            const ref = `customers/${customerId}/senderSignatures`;
            this.Firebase.setByRef(ref, null).then((id) => {
                console.log(domainId, customerId);
                this.Firebase.setByRef(`/schedule/verifyDKIM/${domainId}_${customerId}`, null);
                this.Firebase.setByRef(`/schedule/verifyReturnPathDomain/${domainId}_${customerId}`, null);
                resolve(id);
            });
        });
    }

    getSenderSignatureData(): Observable<DomainSettings> {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const ref = `customers/${customerId}/senderSignatures`;
        return this.Firebase.getByRef(ref);
    }

    storeCustomDomain(data: any) {
        return new Promise((resolve, reject) => {
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            const ref = `customers/${customerId}/customDomain`;
            this.Firebase.updateByRef(ref, data).then((id) => {
                resolve(id);
            });
        });
    }

    checkCustomDomain(data: any) {
        return new Promise((resolve) => {
            const ref = `customDomains`;
            this.Firebase.getByRef(ref)
                .pipe(take(1))
                .subscribe((domainList) => {
                    const domainKey = data.replace(/\./g, '_');
                    domainList = _.keys(domainList);

                    const isExist = domainList.includes(domainKey);
                    resolve(isExist);
                });
        });
    }

    removeFromCustomDomain(domain: string) {
        this.Firebase.removeByRef(`customDomains/${domain}`);
    }
    storeTermsAndPolicies(data: any) {
        return new Promise((resolve, reject) => {
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            const ref = `customers/${customerId}/termsAndPolicies`;
            this.Firebase.updateByRef(ref, data).then((id) => {
                resolve(id);
            });
        });
    }

    removeCustomDomainData() {
        return new Promise((resolve, reject) => {
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            const ref = `customers/${customerId}/customDomain`;
            this.Firebase.setByRef(ref, null).then((id) => {
                resolve(id);
            });
        });
    }
    removeCustomDomainSchedule(domainName: string) {
        return new Promise((resolve, reject) => {
            const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
            const ref = `/schedule/verifyCustomDomains/${domainName}_${customerId}`;
            console.log(ref);
            this.Firebase.setByRef(ref, null).then((id) => {
                resolve(id);
            });
        });
    }

    getCustomDomainData(): Observable<DomainSettings> {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const ref = `customers/${customerId}/customDomain`;
        return this.Firebase.getByRef(ref);
    }
    getTermsAndPolicies(): Observable<DomainSettings> {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const ref = `customers/${customerId}/termsAndPolicies`;
        return this.Firebase.getByRef(ref);
    }

    addSenderSignature(domain: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2mail/add-custom-domain`, {
            domain: domain,
        });
    }

    verifyDomain1(domainName: string, customerId: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2api/verify-custom-domain`, {
            domainName: domainName,
            customerId: customerId,
        });
    }

    //verify Dkim recoed based on domain id
    verifyDkim(domainId: number, customerId: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2mail/verify-dkim`, {
            domainId,
            customerId,
        });
    }

    verifyReturnPath(domainId: number, customerId: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2mail/verify-return-path`, {
            domainId,
            customerId,
        });
    }

    deleteDomain(domainId: number) {
        return this.http.delete(`${environment.fcfApiUrl}/v2mail/delete-domain`, {
            body: { domainId },
        });
    }
    sendDomainRequest(domainName: string, operation: string) {
        return this.http.post(`${environment.fcfApiUrl}/v2mail/sent-domain-request`, {
            domainName,
            operation,
        });
    }

    addFooterLink(data: any) {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const ref = `customers/${customerId}/senderSignatures/footerLinks`;
        return this.Firebase.pushByRef(ref, data, true);
    }
    addCustomerFooterLink(data: any) {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const ref = `customers/${customerId}/customDomain/footerLinks`;
        return this.Firebase.pushByRef(ref, data, true);
    }

    updateFooterLink(data: any) {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const ref = `customers/${customerId}/senderSignatures/footerLinks/${data.id}`;
        return this.Firebase.updateByRef(ref, data);
    }
    updateCustomerFooterLink(data: any) {
        const customerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');
        const ref = `customers/${customerId}/customDomain/footerLinks/${data.id}`;
        return this.Firebase.updateByRef(ref, data);
    }

    checkSubscriptionStatus(customerId: string) {
        if (!this.cacheSubscription$) {
            if (customerId) {
                const userId = localStorage.getItem(`user_id`) || this.Cookies.get('user_id');
                return combineLatest([
                    this.Firebase.getByRef(`customers/${customerId}/teamMembers`).pipe(take(1)),
                    this.Firebase.getByRef(`customers/${customerId}/subscriptions`),
                ]).pipe(
                    switchMap((values: any): any => {
                        const sub = values[1];
                        const teamMembers = _.keys(values[0]);
                        if (sub && teamMembers.includes(userId)) {
                            const subId = _.values(sub)?.[0]?.subscriptionId;
                            // return of(subId);
                            const timer$ = timer(0, REFRESH_INTERVAL);
                            this.cacheSubscription$ = timer$.pipe(
                                switchMap((_) => this.stripe.getSubData(subId)),
                                shareReplay(CACHE_SIZE)
                            );
                            // this.cacheSubscription$ = timer$.pipe(this.stripe.getSubData(subId).pipe(,shareReplay(CACHE_SIZE));
                            return this.cacheSubscription$;
                        } else {
                            return of(null);
                        }
                    })
                );
            } else {
                return of(null);
            }
        } else {
            return this.cacheSubscription$;
        }
    }

    get subscriptionStatus() {
        return this.cacheSubscription$;
    }

    checkIfCustomerHasOutSiteOnceOffPayments(customerId: string) {
        return new Promise((resolve) => {
            this.Firebase.getByRef(`customers/${customerId}/outSiteOnceOffpayments`)
                .pipe(take(1))
                .subscribe((payments: any) => {
                    if (payments) {
                        resolve(payments);
                    } else {
                        resolve(null);
                    }
                    // if (payments) {
                    //     let soloPlan: any[] = [];
                    //     let proPlan: any[] = [];
                    //     let businessPlan: any[] = [];
                    //     _.keys(payments).forEach((pId) => {
                    //         if (payments[pId].sortName === 'solo') {
                    //             soloPlan.push({
                    //                 ...payments[pId],
                    //                 id: pId,
                    //             });
                    //         } else if (payments[pId].sortName === 'pro') {
                    //             proPlan.push({
                    //                 ...payments[pId],
                    //                 id: pId,
                    //             });
                    //         } else {
                    //             businessPlan.push({
                    //                 ...payments[pId],
                    //                 id: pId,
                    //             });
                    //         }
                    //     });
                    //     if (!appId) {
                    //         if (soloPlan.length) {
                    //             resolve(soloPlan[0]);
                    //         } else if (proPlan.length) {
                    //             resolve(proPlan[0]);
                    //         } else {
                    //             resolve(businessPlan[0]);
                    //         }
                    //     } else if (appId === 'HELLOCROWD') {
                    //         if (proPlan.length) {
                    //             resolve(proPlan[0]);
                    //         } else {
                    //             resolve(businessPlan[0]);
                    //         }
                    //     } else {
                    //         if (businessPlan.length) {
                    //             resolve(businessPlan[0]);
                    //         } else {
                    //             resolve(null);
                    //         }
                    //     }
                    // } else {
                    //     resolve(null);
                    // }
                });
        });
    }

    createSamlSetup(data: Object) {
        return this.http
            .post(`${environment.fcfApiUrl}/v2auth/create-saml`, { ...data })
            .pipe(catchError(this.handleError));
    }
    private handleError(error: HttpErrorResponse) {
        console.log('==== error================', error);
        return of({ error: error.error.message });
    }

    updateSamlData(data: Object) {
        return this.http
            .put(`${environment.fcfApiUrl}/v2auth/update-saml`, { ...data })
            .pipe(catchError(this.handleError));
    }
    disableSamlEmailPassWord(tenantId: string, enable: boolean) {
        return this.http
            .put(`${environment.fcfApiUrl}/v2auth/disableSamlPasswordLogin`, { tenantId, enable })
            .pipe(catchError(this.handleError));
    }

    storeSamlSsoData(customerID: string, data: Object) {
        return new Promise((resolve) => {
            this.Firebase.setByRef(`customers/${customerID}/tenantandSamlSso`, data)
                .then((res) => {
                    if (res) {
                        resolve(res);
                    }
                })
                .catch((error) => {
                    console.error(' ============ error', error);
                });
        });
    }
    storeIdPlatform(tenantId: string, customerID: string) {
        return new Promise((resolve) => {
            this.Firebase.setByRef(`customers/${customerID}/idPlatform`, tenantId)
                .then((res) => {
                    if (res) {
                        console.log(' store tenantID resssssss', res);

                        resolve(true);
                    }
                })
                .catch((error) => {
                    console.error(' ============ error', error);
                });
        });
    }
    changeStatus(customerID: string) {
        return new Promise((resolve) => {
            this.Firebase.updateByRef(`customers/${customerID}/tenantandSamlSso`, { status: 'configured' }).then(
                (res) => {
                    if (res) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            );
        });
    }

    storeOnlinEventUrl(link: string, customerId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.updateByRef(`customers/${customerId}/events/${eventId}`, { eventOnlineUrl: link }).then(
                (res) => {
                    console.log(res);

                    resolve(res);
                }
            );
        });
    }
}
