import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { UserService } from '../service/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    loginURL = '/auth/login';
    constructor(private router: Router, private userService: UserService, private Cookies: CookieService) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // console.log('====this.route', this.router.url);
        // console.log('========', state.url);
        if (this.router.url === '/' && state.url === '/CVDST/promote') {
            return this.router.navigate(['/GYXJ6/CVDST/promote']);
        } else {
            // console.log('========', state.url);
            return this.checkAuthentication(route, state);
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // console.log('====this.route', this.router.url);
        // console.log('========', state.url);
        if (this.router.url === '/' && state.url === '/CVDST/promote') {
            return this.router.navigate(['/GYXJ6/CVDST/promote']);
        } else {
            // console.log('========', state.url);
            return this.checkAuthentication(route, state);
        }
    }

    async checkAuthentication(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isLoggedIn = this.userService.getAuthToken();

        // console.log('isLoggedIn', isLoggedIn);
        // console.log('user_id', localStorage.getItem('user_id'), this.Cookies.get('user_id'));
        // console.log('customer_id', localStorage.getItem('customer_id'), this.Cookies.get('customer_id'));

        if (
            isLoggedIn &&
            (localStorage.getItem('user_id') || this.Cookies.get('user_id')) &&
            // (localStorage.getItem('customer_id') || this.Cookies.get('customer_id')) &&
            (localStorage.getItem('user_hash') || this.Cookies.get('user_hash'))
        ) {
            return true;
        }

        // console.log('else execute');

        this.Cookies.set(
            'user_id',
            '',
            new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
            '/',
            environment.cookie_domain,
            true,
            environment.cookie_same_site as any
        );
        localStorage.removeItem('user_id');
        this.Cookies.set(
            'user_hash',
            '',
            new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
            '/',
            environment.cookie_domain,
            true,
            environment.cookie_same_site as any
        );
        localStorage.removeItem('user_hash');
        this.Cookies.set(
            'customer_id',
            '',
            new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
            '/',
            environment.cookie_domain,
            true,
            environment.cookie_same_site as any
        );
        localStorage.removeItem('customer_id');
        this.Cookies.set(
            'user_customer_id',
            '',
            new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
            '/',
            environment.cookie_domain,
            true,
            environment.cookie_same_site as any
        );
        localStorage.removeItem('user_customer_id');
        this.Cookies.set(
            'access_token',
            '',
            new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
            '/',
            environment.cookie_domain,
            true,
            environment.cookie_same_site as any
        );
        localStorage.removeItem('access_token');
        this.Cookies.set(
            'sa_access_token',
            '',
            new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
            '/',
            environment.cookie_domain,
            true,
            environment.cookie_same_site as any
        );
        localStorage.removeItem('sa_access_token');
        const returnUrl = state.url;
        const queryParams: any = {};
        if (returnUrl.length > 1) {
            queryParams.returnUrl = returnUrl;
        }
        this.router.navigate([this.loginURL], { queryParams });
        return false;
    }
}
