/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FirebaseService } from './firebase.service';
import { StripeService } from './stripe.service';
import { Observable, combineLatest, first, take } from 'rxjs';
import { App } from '../model/app.modal';
import { User } from '../model/user.modal';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Pricing } from '../model/pricing.modal';
import { EventService } from './event.service';

@Injectable({
    providedIn: 'root',
})
export class CheckoutService {
    chargeObj!: any;
    products: any;
    constructor(
        private Cookies: CookieService,
        private firebase: FirebaseService,
        private stripe: StripeService // private eventService: EventService
    ) {}

    preview(app_id: string, event_id: string, is_upgrade: boolean, appData = null) {
        console.log('[CHECKOUT: preview]', app_id, event_id, is_upgrade);
        const appId = app_id;
        const eventId = event_id;
        const userId = localStorage.getItem('user_id') || this.Cookies.get('user_id');
        const isUpgrade = is_upgrade;
        return new Promise((resolve, reject) => {
            if (!eventId) {
                reject('Insufficient data');
            }
            if (appId) {
                combineLatest([
                    this.firebase.getByRef('pricing/products') as Observable<Pricing>,
                    this.firebase.getByRef(`apps/${appId}/config`) as Observable<App>,
                    this.firebase.getByRef(`apps/${appId}/public/events_list/${eventId}`) as Observable<any>,
                    // this.firebase.getByRef(`apps/${appId}/private/events/${eventId}/coupon_code`),
                    // Coupon.getAll(),
                    this.stripe.getProducts(),
                    this.firebase.getByRef(`users/${userId}`) as Observable<User>,
                ])
                    .pipe(first())
                    .subscribe(
                        (values) => {
                            const [pricing, app, event, products, user] = values;
                            this.products = products;
                            // const [app, products, user] = values;
                            // console.log('called again !!', values);
                            app.id = appId;

                            console.log('products', products);
                            const currency =
                                user.currency && user.currency.length ? user.currency.toLowerCase() : 'usd';
                            const currency_symbol =
                                user.currency_symbol && user.currency_symbol.length ? user.currency_symbol : '$';

                            this.get(
                                pricing,
                                // currency,
                                currency_symbol,
                                app,
                                event,
                                products,
                                isUpgrade,
                                currency
                            ).then((checkout: any) => {
                                console.log('=====Checkout-service======', checkout);
                                const response: any = {
                                    product_type: checkout.product_type,
                                    email: user.email,
                                };

                                // (this.stripe.getCustomer() as Observable<any>).subscribe((customer) => {
                                // 	if (customer) {
                                // 		response.customer = {
                                // 			id: customer.id,
                                // 			card: customer.last4,
                                // 			card_type: customer.card_type,
                                // 		};
                                // 	}

                                // 	if (products) {
                                // 		response.products = checkout.product;
                                // 		response.plan = checkout.plan;
                                // 	}

                                // 	resolve(response);
                                // });

                                if (products) {
                                    response.products = checkout.product;
                                    response.plan = checkout.plan;
                                    response.price = checkout.price;
                                }

                                resolve(response);
                            });
                        },
                        (error) => {
                            console.log('error', error);
                            reject(error);
                        }
                    );
            } else {
                combineLatest([
                    this.firebase.getByRef('pricing/products') as Observable<Pricing>,
                    // this.firebase.getByRef(`apps/${appId}/private/events/${eventId}/coupon_code`),
                    // Coupon.getAll(),
                    this.stripe.getProducts(),
                    this.firebase.getByRef(`users/${userId}`) as Observable<User>,
                ])
                    .pipe(first())
                    .subscribe(
                        (values) => {
                            const [pricing, products, user] = values;
                            // const [app, products, user] = values;
                            // console.log('called again !!', values);
                            // app.id = appId;

                            console.log('products', products);
                            const currency =
                                user.currency && user.currency.length ? user.currency.toLowerCase() : 'usd';
                            const currency_symbol =
                                user.currency_symbol && user.currency_symbol.length ? user.currency_symbol : '$';

                            this.get(
                                pricing,
                                // currency,
                                currency_symbol,
                                appData,
                                null,
                                products,
                                isUpgrade,
                                currency
                            ).then((checkout: any) => {
                                console.log('====Checkout======', checkout);
                                const response: any = {
                                    product_type: checkout.product_type,
                                    email: user.email,
                                };
                                if (products) {
                                    response.products = checkout.product;
                                    response.plan = checkout.plan;
                                    response.price = checkout.price;
                                }

                                resolve(response);
                            });
                        },
                        (error) => {
                            console.log('error', error);
                            reject(error);
                        }
                    );
            }
        });
    }

    get(pricing: Pricing, currency_symbol: any, app: any, event: any, products: any, isUpgrade: any, currency: any) {
        return new Promise((resolve) => {
            let product_title = 'HelloCrowd Event';
            let price: any = 0;
            let price_usd = 0;
            let price_per_month = 0;
            let product;
            let plan;
            let product_type;
            if (app.id === 'HELLOCROWD') {
                product_type = 'HelloCrowdEvent';
                product = products.filter((p: any) => p.name.indexOf('HelloCrowd') !== -1)[0];
                price =
                    !event || event.is_new_event
                        ? (pricing.PROFESSIONAL[
                              `new_price_${currency.toLowerCase()}` as keyof typeof pricing.PROFESSIONAL
                          ] as number)
                        : (pricing.PROFESSIONAL[
                              `price_${currency.toLowerCase()}` as keyof typeof pricing.PROFESSIONAL
                          ] as number);
                price_usd =
                    !event || event.is_new_event ? pricing.PROFESSIONAL.new_price_usd : pricing.PROFESSIONAL.price_usd;
            } else if (app.app_type === 'SINGLE') {
                product_type = app.app_type;
                if (isUpgrade) {
                    product_title = 'Multi-Event Application';
                    price = pricing.MULTI[`price_${currency.toLowerCase()}` as keyof typeof pricing.MULTI];
                    price_usd = pricing.MULTI.price_usd;
                    let billing = 'annually';
                    if (app.billing && ['annually', 'quarterly'].indexOf(app.billing) !== -1) {
                        billing = app.billing;
                    }
                    product = _.find(products, { interval: billing });

                    if (product) {
                        product_title = product.name;
                        plan = _.find(product.plans, { currency: currency.toLowerCase() });
                        if (plan) {
                            price = plan.amount / 100;
                            if (billing === 'annually') {
                                price_per_month = price / 12;
                            } else if (billing === 'quarterly') {
                                price_per_month = price / 3;
                            }
                        }
                    }
                } else {
                    console.log('else executed !!');
                    price = pricing.SINGLE[`price_${currency.toLowerCase()}` as keyof typeof pricing.SINGLE];
                    price_usd = pricing.SINGLE.price_usd;
                    product_title = 'Single Event Application';
                    product = products.filter((p: any) => p.name.indexOf('Custom Branded Event App (PAYG)') !== -1)[0];

                    if (product) {
                        product_title = product.name;
                        plan = _.find(product.plans, { interval_count: 1 });
                    }
                }
            } else {
                product_title = 'Multi-Event Application';
                product_type = app.app_type;
                if (app.status === 'LIVE') {
                    price = 0;
                } else {
                    price = pricing.MULTI[`price_${currency.toLowerCase()}` as keyof typeof pricing.MULTI];
                    price_usd = pricing.MULTI.price_usd;
                }

                const billingInterval: any = {
                    annually: 1,
                    quarterly: 3,
                };

                let billing = billingInterval['annually'];
                if (app.billing && ['annually', 'quarterly'].indexOf(app.billing) !== -1) {
                    billing = billingInterval[app.billing];
                }
                product = products.filter((p: any) => p.name.indexOf('Custom Branded Event App (Unlimited)') !== -1)[0];

                if (product) {
                    product_title = product.name;
                    plan = _.find(product.plans, { interval_count: billing });

                    if (plan) {
                        price = plan.amount / 100;
                        if (billing === 'annually') {
                            price_per_month = price / 12;
                        } else if (billing === 'quarterly') {
                            price_per_month = price / 3;
                        }
                    }
                }
            }

            const total_price = price;
            const total_price_usd = price_usd;

            const response: any = {
                price,
                price_usd,
                price_per_month,
                currency: currency_symbol,
                product_title,
                total: total_price,
                total_usd: total_price_usd,
                product_type,
            };
            if (product) {
                response.product = product;
                response.plan = plan;
            }
            resolve(response);
        });
    }

    // process(
    //     app_id: string | null,
    //     event_id: string | null,
    //     _token: string | null,
    //     customer_id: string | null,
    //     is_upgrade = false,
    //     is_invoice = false
    // ) {
    //     return new Promise((resolve, reject) => {
    //         const appId = app_id;
    //         const eventId = event_id;
    //         const token = _token || false;
    //         const customerId = customer_id || null;
    //         const userId = localStorage.getItem('user_id') || this.Cookies.get('user_id');
    //         const isUpgrade = is_upgrade;
    //         const isInvoice = is_invoice;

    //         if (!appId || !eventId) {
    //             reject('Insufficient data');
    //         }
    //         // if (!token && !customerId && !isInvoice) {
    //         // 	reject('Please provide credit card');
    //         // }

    //         combineLatest([
    //             this.firebase.getByRef('pricing/products') as Observable<Pricing>,
    //             this.firebase.getByRef(`apps/${appId}/config`) as Observable<App>,
    //             this.firebase.getByRef(`apps/${appId}/public/events_list/${eventId}`) as Observable<any>,
    //             this.stripe.getProducts(),
    //             this.firebase.getDbUserByUserId(userId) as Observable<User>,
    //             // this.stripe.getChargeObj(),
    //         ])
    //             .pipe(first())
    //             .subscribe((values) => {
    //                 const [pricing, app, event, products, user] = values;

    //                 // const [products, app, chargeObj] = values;

    //                 if (appId) {
    //                     app.id = appId;
    //                 }

    //                 console.log('===app-type====', app.type);
    //                 // if (chargeObj) this.chargeObj = chargeObj;

    //                 // (this.firebase.getDbUserByUserId(userId) as Observable<>).then((user) => {
    //                 // user.id = userId;
    //                 const currency = user.currency && user.currency.length ? user.currency.toLowerCase() : 'usd';
    //                 const currency_symbol =
    //                     user.currency_symbol && user.currency_symbol.length ? user.currency_symbol : '$';

    //                 this.get(pricing, currency_symbol, app, event, products, isUpgrade, currency)
    //                     .then((checkout: any) => {
    //                         console.log('appId', app.type);
    //                         if (appId === 'HELLOCROWD' && eventId) {
    //                             this._charge(appId, eventId, checkout, user)
    //                                 .then((response) => {
    //                                     // this.firebase.setByRef(
    //                                     //     `customers/${customerId}/events/${eventId}/status`,
    //                                     //     'LIVE'
    //                                     // );
    //                                     return resolve(response);
    //                                 })
    //                                 .catch((error) => reject(error));
    //                         } else if (app.type === 'SINGLE') {
    //                             // if (isUpgrade) {
    //                             console.log('single isUpgrade');
    //                             // create subscription
    //                             this.stripe.getSubscriptions().subscribe((response: any) => {
    //                                 // console.log('response', response);
    //                                 // if (isUpgrade) {
    //                                 // console.log('single isUpgrade');
    //                                 if (response?.subscription) {
    //                                     this.firebase
    //                                         .createStripeSubscription({
    //                                             app_id: appId,
    //                                             created: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    //                                             subscription_id: response.subscription,
    //                                         })
    //                                         .then((sub: any) => {
    //                                             this.firebase
    //                                                 .updateByRef(`apps/${appId}/config`, {
    //                                                     status: 'LIVE',
    //                                                     app_type: 'SINGLE',
    //                                                     subscription_id: sub.subscription_id,
    //                                                     published: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    //                                                 })
    //                                                 .then(async () => {
    //                                                     // this.addInvoiceBranded(
    //                                                     //     user,
    //                                                     //     checkout,
    //                                                     //     checkout.product_title,
    //                                                     //     response.subscription
    //                                                     // );

    //                                                     this._setEventLive(appId as string, eventId as string).then(
    //                                                         () => {
    //                                                             // this.firebase.setByRef(
    //                                                             //     `customers/${customerId}/events/${eventId}/status`,
    //                                                             //     'LIVE'
    //                                                             // );
    //                                                             resolve({
    //                                                                 success: true,
    //                                                             });
    //                                                         }
    //                                                     );
    //                                                 });
    //                                         })
    //                                         .catch((error) => {
    //                                             console.log('error', error);
    //                                             reject(error);
    //                                         });
    //                                 } else {
    //                                     // this.addInvoiceBranded(
    //                                     //     user,
    //                                     //     checkout,
    //                                     //     checkout.product_title,
    //                                     //     response?.subscription
    //                                     // );

    //                                     this._setEventLive(appId as string, eventId as string).then(() => {
    //                                         // this.firebase.setByRef(
    //                                         //     `customers/${customerId}/events/${eventId}/status`,
    //                                         //     'LIVE'
    //                                         // );
    //                                         resolve({
    //                                             success: true,
    //                                             message: 'Subscription was created',
    //                                         });
    //                                     });
    //                                     // });
    //                                 }
    //                                 // }
    //                                 //     else {
    //                                 //         console.log('single isUpgrade 0');
    //                                 //         this._charge(appId as string, eventId as string, checkout, user)
    //                                 //             .then(async (response) => {
    //                                 //                 // set event as live
    //                                 //                 // await App.screenshotSave(req, appId).then((links) => console.log("=====links=====", links)).catch((err) => console.error("====err====", err));
    //                                 //                 // if (functions.config().main.environment === 'production') {
    //                                 //                 //     Transactions.storeTransaction(appId, eventId, {
    //                                 //                 //         price: checkout.total_usd,
    //                                 //                 //         coupon_code: coupon_code || '',
    //                                 //                 //     })
    //                                 //                 //         .then(() => console.log(`transaction saved`))
    //                                 //                 //         .catch((error) => console.error(error));
    //                                 //                 // }
    //                                 //                 this.firebase
    //                                 //                     .updateByRef(`apps/${appId}/config`, {
    //                                 //                         status: 'LIVE',
    //                                 //                         published: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    //                                 //                     })
    //                                 //                     .then(() => {
    //                                 //                         resolve(response);
    //                                 //                     });
    //                                 //             })
    //                                 //             .catch((error) => {
    //                                 //                 console.log('error', error);
    //                                 //                 reject(error);
    //                                 //             });
    //                                 //     }
    //                             });
    //                             // }
    //                         } else {
    //                             console.log('CREATE REGULAR SUBSCRIPTION');
    //                             this.stripe.getSubscriptions().subscribe(
    //                                 (response: any) => {
    //                                     this.firebase
    //                                         .createStripeSubscription({
    //                                             app_id: appId,
    //                                             subscription_id: response.subscription,
    //                                             created: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    //                                         })
    //                                         .then((sub: any) => {
    //                                             console.log('sub', sub);
    //                                             this.firebase
    //                                                 .updateByRef(`apps/${appId}/config`, {
    //                                                     status: 'LIVE',
    //                                                     subscription_id: sub.subscription_id,
    //                                                     published: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    //                                                 })
    //                                                 .then(async () => {
    //                                                     // this.addInvoiceBranded(
    //                                                     //     user,
    //                                                     //     checkout,
    //                                                     //     checkout.product_title,
    //                                                     //     response.subscription
    //                                                     // );

    //                                                     console.log('appId', appId);
    //                                                     console.log('eventId', eventId);
    //                                                     this._setEventLive(appId as string, eventId as string).then(
    //                                                         () => {
    //                                                             // this.firebase.setByRef(
    //                                                             //     `customers/${customerId}/events/${eventId}/status`,
    //                                                             //     'LIVE'
    //                                                             // );
    //                                                             resolve({
    //                                                                 success: true,
    //                                                                 message: 'Subscription was created',
    //                                                             });
    //                                                         }
    //                                                     );
    //                                                     // resolve({
    //                                                     //     success: true,
    //                                                     //     message: 'Subscription was created',
    //                                                     // })
    //                                                     // );
    //                                                 });
    //                                         });
    //                                 },
    //                                 (error: any) => {
    //                                     console.log('Error', error);
    //                                     reject(error);
    //                                 }
    //                             );
    //                         }
    //                     })
    //                     .catch((err: any) => reject(err));
    //             });
    //     });
    // }

    _charge(appId: string, eventId: string, checkout: any, user: User | null = null) {
        return new Promise((resolve) => {
            this.stripe.getChargeObj().subscribe((chargeObj: any) => {
                this.chargeObj = chargeObj;
                console.log('==chargeObj=====', chargeObj);
                console.log(user);
                this._setEventLive(appId, eventId).then(() => {
                    // if (user) {
                    //     this.addInvoice(user, checkout, this.chargeObj, checkout.product_title, this.chargeObj?.id);
                    // }
                    resolve({
                        success: true,
                        message: 'Charged',
                    });
                });
            });
        });
    }

    addInvoiceBranded(user: any, checkout: any, title: any, id: any) {
        return new Promise((resolve, reject) => {
            this.firebase
                .getByRef('invoice_number')
                .pipe(first())
                .subscribe((response: any) => {
                    const invoiceNumber = this._newInvoiceNumber(response || '0000');
                    const invoice = {
                        product: checkout.product ? checkout.product.name : '',
                        product_title: title,
                        price: checkout.price,
                        total: checkout.total,
                        // discount: checkout.discount,
                        currency: checkout.currency,
                        number: invoiceNumber,
                        date: Math.floor(Date.now() / 1000),
                        subscription_id: id || '',
                    };
                    this.firebase.pushByRef(`users/${user.id}/payment_profile/invoices`, invoice).then(() => {
                        this.firebase.setByRef('invoice_number', invoiceNumber).then(() => {
                            resolve(true);
                        });
                    });
                });
        });
    }

    _setEventLive(appId: string, eventId: string) {
        // this.firebase.updateByRef()
        return this.firebase.updateByRef(`apps/${appId}/public/events_list/${eventId}`, {
            is_banner_advertising_enabled: true,
            is_banner_advertising_paid: true,
            is_professional_enabled: true,
            is_professional_paid: true,
            is_attendees_enabled: true,
            is_attendees_paid: true,
            published: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            status: 'LIVE',
        });
    }

    addInvoice(user: User, checkout: any, charge: any, title: string, id: any) {
        console.log('description', charge);
        return new Promise((resolve, reject) => {
            (this.firebase.getByRef('invoice_number') as Observable<string>).pipe(first()).subscribe((response) => {
                const invoiceNumber = this._newInvoiceNumber(response || '0000');

                this.firebase
                    .pushByRef(`users/${user.id}/payment_profile/invoices`, {
                        product: charge?.description || '',
                        product_title: title,
                        price: checkout.price,
                        total: checkout.total,
                        // discount: checkout.discount,
                        currency: checkout.currency,
                        number: invoiceNumber,
                        date: Math.floor(Date.now() / 1000),
                        charge_id: id || '',
                    })
                    .then(() => {
                        this.firebase.setByRef('invoice_number', invoiceNumber).then(() => {
                            console.log('DONE');
                            resolve(true);
                        });
                    })
                    .catch((err) => reject(err));
            });
        });
    }

    _newInvoiceNumber(str: string) {
        const val = (parseInt(str, 10) + 1).toString();
        const diff = 5 - val.length;
        if (diff) {
            let prepend = '';
            for (let i = 0; i < diff; i++) {
                prepend += '0';
            }
            return prepend + val;
        }
        return val;
    }

    checkoutEnterpriseAppOREvent(event: any, customerId: string, eventId: string) {
        console.log('Event', event);
        console.log('customerId', customerId);
        console.log('eventId', eventId);
        return new Promise((resolve, reject) => {
            if (event.status === 'DRAFT' && event.isPublished !== false) {
                this.resetEventDataOnPublish(customerId, eventId);
            }
            if (event.app_id) {
                let promises: any[] = [];
                if (event.app_id === 'HELLOCROWD') {
                    promises = [
                        this._setEventLive(event.app_id, eventId),
                        this.firebase.updateByRef(`/customers/${customerId}/events/${eventId}`, {
                            status: 'PUBLISHED',
                        }),
                    ];
                } else {
                    promises = [
                        // this.firebase.updateByRef(`apps/${event.app_id}/config`, {
                        //     status: 'LIVE',
                        //     published: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        // }),
                        this._setEventLive(event.app_id, eventId),
                        this.firebase.updateByRef(`/customers/${customerId}/events/${eventId}`, {
                            status: 'PUBLISHED',
                        }),
                    ];
                }
                Promise.all(promises)
                    .then((resp: any) => {
                        console.log('resp', resp);
                        resolve(resp);
                    })
                    .catch((error) => {
                        console.log('Error', error);
                        reject(error);
                    });
            } else {
                const ref = `customers/${customerId}/events/${eventId}`;
                this.firebase
                    .updateByRef(ref, {
                        status: 'PUBLISHED',
                    })
                    .then(() => {
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log('Error', error);
                        reject(error);
                    });
            }
        });
    }

    checkEventPlanAndSubscription(appId: string | null, eventId: string, customerId: string) {
        return new Promise((resolve, reject) => {
            // if (appId) {
            this.firebase
                .getByRef(`customers/${customerId}/subscriptions`)
                .pipe(take(1))
                .subscribe((plans: any) => {
                    if (plans) {
                        const checkIfBussinessOREnterPricePlanAvailabled = _.keys(plans).filter(
                            (id: string) =>
                                plans[id]?.plan?.toLowerCase() === 'business' ||
                                plans[id]?.plan?.toLowerCase()?.includes('enterprise')
                        );

                        const checkIfSoloPlan = _.keys(plans).filter(
                            (id: string) => plans[id]?.plan?.toLowerCase() === 'solo'
                        )?.[0];

                        const checkProPlanSubscription = _.keys(plans).filter(
                            (id: string) => plans[id]?.plan?.toLowerCase() === 'pro'
                        )?.[0];

                        if (appId === 'HELLOCROWD' || !appId) {
                            const sub$: any[] = [];

                            if (checkIfBussinessOREnterPricePlanAvailabled.length) {
                                checkIfBussinessOREnterPricePlanAvailabled.forEach((planId: string) => {
                                    if (plans?.[planId]?.subscriptionId) {
                                        const subId = plans[planId].subscriptionId;
                                        sub$.push(this.stripe.getSubData(subId));
                                    }
                                });

                                combineLatest(sub$)
                                    .pipe(take(1))
                                    .subscribe((subscriptions: any) => {
                                        const activeSubscription = subscriptions?.filter(
                                            (sub: any) => sub.status === 'active' || sub?.status === 'trialing'
                                        )?.[0];

                                        activeSubscription
                                            ? resolve(activeSubscription)
                                            : resolve({ needToRedirectCustomerPortal: true });
                                    });
                            } else {
                                // const checkProPlanSubscription = _.keys(plans).filter(
                                //     (id: string) => plans[id]?.appId === 'HELLOCROWD'
                                // )?.[0];

                                if (checkProPlanSubscription) {
                                    const subId = plans[checkProPlanSubscription].subscriptionId;
                                    this.stripe
                                        .getSubData(subId)
                                        .pipe(take(1))
                                        .subscribe((subscription: any) => {
                                            resolve(subscription);
                                        });
                                } else {
                                    if (appId === 'HELLOCROWD') {
                                        resolve({
                                            needToRedirectCustomerPortal: true,
                                        });
                                    } else {
                                        // const checkIfSoloPlan = _.keys(plans).filter(
                                        //     (id: string) => plans[id]?.appId === null
                                        // )?.[0];

                                        if (checkIfSoloPlan) {
                                            const subId = plans[checkIfSoloPlan].subscriptionId;
                                            this.stripe
                                                .getSubData(subId)
                                                .pipe(take(1))
                                                .subscribe((subscription: any) => {
                                                    resolve(subscription);
                                                });
                                        } else {
                                            resolve({
                                                needToRedirectCustomerPortal: true,
                                            });
                                        }
                                    }
                                }
                            }
                        } else {
                            const planId = _.keys(plans).filter(
                                (id: string) =>
                                    plans[id]?.plan?.toLowerCase() === 'business' ||
                                    plans[id]?.plan?.toLowerCase().includes('enterprise')
                            )[0];
                            if (plans?.[planId]?.subscriptionId) {
                                const subId = plans[planId].subscriptionId;
                                this.stripe
                                    .getSubData(subId)
                                    .pipe(take(1))
                                    .subscribe((subscription: any) => {
                                        resolve(subscription);
                                    });
                            } else {
                                resolve({
                                    needToRedirectCustomerPortal: true,
                                });
                            }
                        }
                    } else {
                        resolve(null);
                    }
                });
            // this.firebase.getByRefWhere(`customers/${customerId}/subscriptions`, 'appId', appId).then((plan: any) => {
            //     if (plan) {
            //         const planId = _.keys(plan)[0];
            //         console.log('PLANID', planId);

            //         if (plan[planId]?.subscriptionId) {
            //             this.stripe
            //                 .getSubData(plan[planId].subscriptionId)
            //                 .pipe(take(1))
            //                 .subscribe((subscription: any) => {
            //                     resolve(subscription);
            //                 });
            //         } else {
            //             resolve(null);
            //         }
            //     } else {
            //         resolve(null);
            //     }
            // });
        });
    }

    // resetEventDataOnPublish(customerId: string, eventId: string) {
    //     return new Promise((resolve, reject) => {
    //         const customerEventRef = `customers/${customerId}/events/${eventId}`;
    //         this.firebase
    //             .getByRef(customerEventRef)
    //             .pipe(first())
    //             .subscribe((event) => {
    //                 console.log('RESET EVENT DATA ON PUBLISH========', event);
    //                 if (event && event.isPublished !== false) {
    //                     const custAttendeeRef = `customers/${customerId}/eventsData/${eventId}/tickets`;
    //                     const custInviteeRef = `customers/${customerId}/eventsData/${eventId}/invitees`;
    //                     const custTransRef = `customers/${customerId}}/eventsData/${eventId}/transactions`;
    //                     const deletePromises: any = [];

    //                     deletePromises.push(this.firebase.setByRef(custAttendeeRef, null));
    //                     deletePromises.push(this.firebase.setByRef(custInviteeRef, null));
    //                     deletePromises.push(this.firebase.setByRef(custTransRef, null));
    //                     if (event.app_id) {
    //                         const appAttendeeRef = `apps/${event.app_id}/public/events_data/${eventId}/attendees`;
    //                         deletePromises.push(this.firebase.setByRef(appAttendeeRef, null));
    //                     }

    //                     Promise.all(deletePromises).then(() => {
    //                         resolve(true);
    //                         console.log('DELETED ALL DATA BEFORE PUBLISH');
    //                     });
    //                 } else {
    //                     resolve(true);
    //                 }
    //             });
    //     });
    // }
    resetEventDataOnPublish(customerId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            const customerEventRef = `customers/${customerId}/events/${eventId}`;
            const eventSetupRef = `customers/${customerId}/eventsData/${eventId}/eventSetup`;
            combineLatest([this.firebase.getByRef(customerEventRef), this.firebase.getByRef(eventSetupRef)])
                .pipe(first())
                .subscribe((res) => {
                    const event = res[0];
                    const eventSetup = res[1];
                    console.log('RESET EVENT DATA ON PUBLISH========', event);
                    if (event && event.isPublished !== false) {
                        const custAttendeeRef = `customers/${customerId}/eventsData/${eventId}/tickets`;
                        const custInviteeRef = `customers/${customerId}/eventsData/${eventId}/invitees`;
                        const custTransRef = `customers/${customerId}/eventsData/${eventId}/transactions`;
                        const donationRef = `customers/${customerId}/eventsData/${eventId}/donations`;
                        const deletePromises: any = [];
                        deletePromises.push(this.firebase.setByRef(custAttendeeRef, null));
                        deletePromises.push(this.firebase.setByRef(custInviteeRef, null));
                        deletePromises.push(this.firebase.setByRef(custTransRef, null));
                        deletePromises.push(this.firebase.setByRef(donationRef, null));
                        if (event.app_id) {
                            const appAttendeeRef = `apps/${event.app_id}/public/events_data/${eventId}/attendees`;
                            deletePromises.push(this.firebase.setByRef(appAttendeeRef, null));
                        }
                        if (eventSetup) {
                            if (eventSetup.causes) {
                                _.forOwn(eventSetup.causes, (cause, causeId) => {
                                    const causeChildRef = `${eventSetupRef}/causes/${causeId}`;
                                    deletePromises.push(
                                        this.firebase.updateByRef(causeChildRef, {
                                            collected: null,
                                            contributions: null,
                                        })
                                    );
                                });
                            }
                            if (eventSetup.tickets) {
                                _.forOwn(eventSetup.tickets, (ticket, ticketId) => {
                                    const ticketChildRef = `${eventSetupRef}/tickets/${ticketId}`;
                                    const remaining = ticket.availableQuantity ? ticket.availableQuantity : null;
                                    deletePromises.push(
                                        this.firebase.updateByRef(ticketChildRef, {
                                            remaining,
                                            sold: 0,
                                        })
                                    );
                                });
                            }
                            if (eventSetup.promoCodes) {
                                _.forOwn(eventSetup.promoCodes, (promoCode, promoId) => {
                                    const promoCodeChildRef = `${eventSetupRef}/promoCodes/${promoId}`;
                                    deletePromises.push(
                                        this.firebase.updateByRef(promoCodeChildRef, {
                                            useCount: 0,
                                        })
                                    );
                                });
                            }
                            if (eventSetup.accommodations) {
                                _.forOwn(eventSetup.accommodations, (accom, accoId) => {
                                    const promoCodeChildRef = `${eventSetupRef}/accommodations/${accoId}`;
                                    deletePromises.push(
                                        this.firebase.updateByRef(promoCodeChildRef, {
                                            occupiedRoom: null,
                                        })
                                    );
                                });
                            }
                        }

                        Promise.all(deletePromises).then(() => {
                            resolve(true);
                            console.log('DELETED ALL DATA BEFORE PUBLISH');
                        });
                    } else {
                        resolve(true);
                    }
                });
        });
    }
}
