import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { UtilityService } from './utility.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PairingService {
    constructor(
        private Firebase: FirebaseService,
        private Utilities: UtilityService,
        private db: AngularFireDatabase,
        private http: HttpClient
    ) {}

    create(appId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            this.generatePairingId().then((id) => {
                console.log('ids', id);
                this.Firebase.setByRef(`pairing/${id}`, {
                    app_id: appId,
                    event_id: eventId,
                }).catch((error) => {
                    reject(error);
                    console.log('Parring Key generaton error', error);
                });

                resolve(id);
            });
        });
    }
    generatePairingId() {
        return new Promise((resolve) => {
            // (this
            const url = `${environment.firebaseConfig.databaseURL}/pairing.json?auth=${environment.firebaseConfig.secret}&shallow=true`;

            this.http.get(url).subscribe((items: any) => {
                const ids = Object.keys(items);
                let pairingId = this.Utilities.generateUID(7);
                while (ids.indexOf(pairingId) !== -1) {
                    pairingId = this.Utilities.generateUID(7);
                }
                resolve(pairingId.toLowerCase());
            });
            // const pairing = `apps/pairing`
            // this.db.database.ref(`${pairing}`).once('value', (snapshot) => {
            // 	const paringList: string[] = [];
            // 	snapshot.forEach((itemSnapshot: DataSnapshot) => {
            // 		paringList.push(itemSnapshot.key as string);
            // 	});

            // 	let pairingId = this.Utilities.generateUID(7);

            // 	while (paringList.indexOf(pairingId) !== -1) {
            // 		pairingId = this.Utilities.generateUID(7);
            // 	}
            // 	resolve(pairingId.toLowerCase());
            // })
        });
    }
}
