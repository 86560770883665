import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { CookieService } from 'ngx-cookie-service';
import { Subject, combineLatest, first } from 'rxjs';
import * as moment from 'moment';

declare const Intercom: any;
@Injectable({
    providedIn: 'root',
})
export class IntercomService {
    constructor(private userService: UserService, private Cookies: CookieService) {
        //
    }
    APP_ID = 'dkmxrkav';

    isCapchaTrigger = new Subject<boolean>();
    boot() {
        combineLatest([
            this.userService.getCurrent(),
            this.userService.setCurrent(localStorage.getItem('user_id') as string),
        ])
            .pipe(first())
            .subscribe(async (res) => {
                const user_id = localStorage.getItem('user_id') || this.Cookies.get('user_id');
                const user = res[0]!;
                const company: any = res[1];
                let user_hash: string | null = await this.userService.getUserHash();
                // console.log("Boot_HAsh", user_hash)
                while (!user_hash) {
                    user_hash = localStorage.getItem('user_hash');
                }
                let intercomSettings: any = {
                    app_id: this.APP_ID,
                    alignment: 'right',
                    horizontal_padding: 30,
                    vertical_padding: 30,
                    name: user.first_name + ' ' + user.last_name || '',
                    email: user.email || '',
                    user_id: user_id,
                    user_hash: user_hash,
                    // company: {
                    //     company_id: user?.customerId,
                    //     name: company.companyName,
                    //     created_at: company.createdAt || moment().unix(),
                    // },
                    // customer_id: user.customerId,
                };

                if (company) {
                    intercomSettings = {
                        ...intercomSettings,
                        company: {
                            company_id: user?.customerId,
                            name: company.companyName,
                            created_at: company.createdAt || moment().unix(),
                        },
                    };
                }

                if (user.customerId) {
                    intercomSettings = {
                        ...intercomSettings,
                        customer_id: user.customerId,
                    };
                }
                // Intercom('shutdown')

                Intercom('boot', intercomSettings);
                Intercom('update', {
                    hide_default_launcher: false,
                });
                // Intercom('show');
            });
        // Intercom('showMessages')
    }

    shutdown() {
        Intercom('shutdown');
    }

    hideLauncher() {
        Intercom('update', {
            hide_default_launcher: true,
        });
    }

    getCapcha(isTrigger: boolean) {
        this.isCapchaTrigger.next(isTrigger);
    }
}
