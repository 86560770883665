import { Injectable } from '@angular/core';
import * as lodash from "lodash"
import { ScheduleService } from './schedule.service';
import { FirebaseService } from './firebase.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class SessionModalService {
	_ = lodash
	constructor(private scheduleSession: ScheduleService, private Firebase: FirebaseService) { }

	_scheduleSessions(appId: string, eventId: string, sessions: any) {
		return new Promise((resolve) => {
			const promises: any = [];
			this._.keys(sessions).forEach((sessionId) => {
				const session = sessions[sessionId];
				session.id = sessionId;
				promises.push(this.scheduleSession.session(appId, eventId, session));
			});
			Promise.all(promises).then(() => {
				console.log('----- All sessions were scheduled');
				resolve(true);
			});
		});
	}

	getAllSession(appId: string, eventId: string) {
		return new Promise((resolve) => {
			(this.Firebase.getByRef(`apps/${appId}/public/events_data/${eventId}/sessions`)).subscribe((data) => {
				const sessions: any[] = [];
				if (data) {
					this._.forOwn(data, (val: any) => {
						val.start_date = moment(val.start_date_time_unix).toDate();
						val.end_date = moment(val.start_date_time_unix)
							.add(val.duration, 'second')
							.toDate();
						sessions.push(val);
					});
					resolve(sessions.sort((a, b) => a.start_date - b.start_date));
				} else {
					resolve(sessions);
				}
			})
			// this.Firebase.getByRef(`apps/${appId}/public/events_data/${eventId}/sessions`).then((data) => {

			// });
		});
	}
}
