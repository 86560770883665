/* eslint-disable @typescript-eslint/no-explicit-any */
import * as lodash from 'lodash';

export class Transformer {
    _ = lodash;
    pageType = '';
    // constructor() { }

    prepareURL(data: any) {
        if (data) {
            if (data.indexOf('http') !== -1 || data.indexOf('https') !== -1) {
                return data;
            } else {
                return 'http://' + data;
            }
        } else {
            return null;
        }
    }

    transformSingle(item: any) {
        console.log('items', item);
    }

    makeResponse(data: any[], params = {}) {
        // const per_page = params.per_page || 30;
        // const total_items = data.length;
        const transformedData: any = Array.isArray(data)
            ? data.map((item) => this.transformSingle(item))
            : this.transformSingle(data);
        const filteredData = this.filterData(transformedData, params);
        // const slicedData = this.sliceData(filteredData, params, total_items, per_page);
        // console.log('filteredData', filteredData);
        return filteredData;
    }

    filterData(data: any[], params: any) {
        let filtered = data;
        if (params && typeof params.q !== 'undefined' && params.q.length) {
            filtered = this._.filter(
                filtered,
                (item: any) => item.title.toLowerCase().indexOf(params.q.toLowerCase()) !== -1
            );
        }
        if (params && typeof params.order !== 'undefined' && params.order.length) {
            const orderBy = params.order.split('|')[0],
                order = params.order.split('|')[1];
            filtered = this._.sortBy(filtered, (f: any) => f[orderBy]);
            if (order === 'desc') {
                filtered = filtered.reverse();
            }
        }
        return filtered;
    }

    calculateStatus(entity: any) {
        const extraFields = ['title'];
        if (['sponsor', 'exhibitor'].some((e) => e === this.pageType)) {
            extraFields.push(...['category', 'icon', 'description']);
        } else {
            extraFields.push(...['subtitle']);
        }
        if (extraFields.every((e) => entity[e]) && entity.email) {
            entity.status = 2;
            entity.statusClass = 'fa-circle md-accent md-hue-1';
        } else if (extraFields.some((e) => entity[e]) && entity.email) {
            entity.status = 1;
            entity.statusClass = 'fa-dot-circle-o md-warn md-hue-1';
        } else {
            entity.status = 0;
            entity.statusClass = 'fa-circle-o md-primary md-hue-1';
        }
    }
}
