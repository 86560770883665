import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
// import { PromoteResolver } from './resolvers/promote.resolver';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    },

    {
        path: 'app-connect',
        loadChildren: () => import('./app-connect/app-connect.module').then((m) => m.AppConnectModule),
    },

    {
        path: '',
        // resolve: [PromoteResolver],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

            {
                path: '',
                loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
            },

            {
                path: 'edit-account',
                loadChildren: () => import('./edit-account/edit-accout.module').then((m) => m.EditAccoutModule),
            },

            {
                path: ':eventId',
                loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule),
            },
            {
                path: ':eventId',
                loadChildren: () => import('./tickets/tickets.module').then((m) => m.TicketsModule),
            },
            {
                path: ':eventId',
                loadChildren: () => import('./event-feedback/event-feedback.module').then((m) => m.EventFeedbackModule),
            },
            {
                path: ':eventId',
                loadChildren: () => import('./causes/causes.module').then((m) => m.CausesModule),
            },
            {
                path: ':eventId',
                loadChildren: () => import('./invitee/invitee.module').then((m) => m.InviteeModule),
            },
            {
                path: ':eventId',
                loadChildren: () => import('./report/report.module').then((m) => m.ReportModule),
            },
            // {
            //     path: ':appId/:eventId',
            //     loadChildren: () => import('./report/report.module').then((m) => m.ReportModule),
            // },
            {
                path: ':eventId/basic-info',
                loadChildren: () => import('./basic-info/basic-info.module').then((m) => m.BasicInfoModule),
            },
            {
                path: ':customerId/:eventId',
                loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule),
            },
            {
                path: ':appId/:eventId',
                loadChildren: () => import('./build/build.module').then((m) => m.BuildModule),
            },
        ],
    },

    // {
    //     path: ':eventId',
    //     canActivate: [AuthGuard],
    //     loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule),
    // },

    {
        path: ':customerId/:eventId',
        // resolve: [PromoteResolver],
        loadChildren: () => import('./register/register.module').then((m) => m.RegisterModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
