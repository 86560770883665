import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../service/user.service';

@Injectable()
export class RequestHeaderInterceptor implements HttpInterceptor {
    constructor(private userService: UserService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.userService.getAuthToken() && !request.url.includes('assets')) {
            const token: any = 'JWT ' + this.userService.getAuthToken();
            // console.log('token====', token)
            const clonedRequest = request.clone({ headers: request.headers.append('Authorization', token) });
            return next.handle(clonedRequest);
        }
        return next.handle(request);
    }
}
