import { Injectable } from '@angular/core';
import { Transformer } from '../shared/abstract-components/transformer';
import { Attendee, AttendeeTransFormer } from '../model/attendees.modal';
import * as moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';

@Injectable({
    providedIn: 'root',
})
export class AttendeeTransformerService extends Transformer {
    // makeResponse(data, params = {}) {
    //     let transformedData = (angular.isArray(data)) ?
    //         data.map(item => this.transformSingle(item)) :
    //         this.transformSingle(data);

    //     return this.filterData(transformedData, params);
    // }

    override transformSingle(item: Attendee) {
        if (!item) {
            return;
        }
        const transoformedItem: AttendeeTransFormer = {
            id: item.id,
            title: item.title,
            subtitle: item.sub_title,
            description: item.description,
            icon: item.icon || item.icon_small,
            // icon_small: item.icon_small,
            admin_rights: item.admin_rights,
            email_address: '',
            contact_number: '',
            website_address: '',
            page_id: item.page_id,
            // designation: item.designation,
            socialProfiles: item.socialProfiles,
            firstName: item.firstName,
            lastName: item.lastName,
            company: item.company,
            tags: item.tags,
            is_common: item.is_common || false,
            downloaded: item.downloaded,
        };

        // console.log('====type-of=====')
        if (typeof item.email_address !== 'undefined' && item.email_address) {
            transoformedItem.email = item.email_address.length ? unescape(item.email_address) : '';
        }
        if (item.mobile_number && typeof item.mobile_number !== 'undefined') {
            transoformedItem.contact_number = item.mobile_number.length ? item.mobile_number : '';
        }
        if (item.website_address && typeof item.website_address !== 'undefined') {
            transoformedItem.website_address = item.website_address;
        }
        if (!item.check_in_by_day && typeof item.check_in_by_day !== 'undefined') {
            transoformedItem.check_in_by_day = item.check_in_by_day;
        }
        this.calculateStatus(transoformedItem);
        // this.hasMobile(transoformedItem);
        return transoformedItem;
    }

    prepareUserData(data: AttendeeTransFormer) {
        let returnData: any = {};

        if (data.title) {
            returnData.title = data.title;
        }

        if (data.firstName) {
            returnData.firstName = data.firstName;
        }

        if (data.lastName) {
            returnData.lastName = data.lastName;
        }

        if (data.email) {
            returnData.email_address = data.email.toLowerCase();
        }

        returnData.sub_title = data.subtitle || null;
        returnData.description = data.description || null;
        returnData.icon = data.icon || null;
        returnData.mobile_number = data.contact_number
            ? parsePhoneNumber(data.contact_number)?.formatInternational()
            : null;
        returnData.website_address = data.website_address || null;
        returnData.company = data.company || null;

        return returnData;
    }

    prepareAttendeeData(data: AttendeeTransFormer) {
        return {
            title: data.title || null,
            firstName: data.firstName || null,
            lastName: data.lastName || null,
            email_address: data.email ? data.email.toLowerCase() : null,
            sub_title: data.subtitle || null,
            description: data.description || null,
            icon: data.icon || null,
            page_id: data.page_id || null,
            mobile_number: data.contact_number ? parsePhoneNumber(data.contact_number)?.formatInternational() : null,
            website_address: this.prepareURL(data.website_address),
            company: data.company || null,

            // icon_small: data.icon_small || '',
            admin_rights: data.admin_rights || false,
            tags: data.tags || null,
        };
    }

    setPageType(type: string) {
        this.pageType = type;
    }

    prepareAudienceData(data: any, evId: string, appId: string) {
        let preparedAudience: any = {};

        if (data.email_address) {
            preparedAudience.email = data.email_address;
        }

        if (data.firstName) {
            preparedAudience.firstName = data.firstName;
        }

        if (data.lastName) {
            preparedAudience.lastName = data.lastName;
        }

        preparedAudience = {
            ...preparedAudience,
            company: data.company || null,
            jobTitle: data.sub_title || null,
            mobilePhone: data.contact_number ? parsePhoneNumber(data.contact_number)?.formatInternational() : null,
            title: data.title || null,
            imgUrl: data.icon || data.icon_small || null,
            website: data.website_address || null,
            description: data.description || null,
            lastUpdated: moment().valueOf(),
            attendeeType: 'Attendee',
        };

        // if (data.company) {
        //     preparedAudience.company = data.company;
        // }

        // if (data.sub_title) {
        //     preparedAudience.jobTitle = data.sub_title;
        // }

        // if (data.mobile_number) {
        //     preparedAudience.mobilePhone = data.mobile_number;
        // }

        // if (data.title) {
        //     preparedAudience.title = data.title;
        // }

        if (!data.title && (data.firstName || data.lastName)) {
            preparedAudience.title = (data.firstName + ' ' + data.lastName)?.trim();
        }

        // if (data.icon || data.icon_small) {
        //     preparedAudience.imgUrl = data.icon || data.icon_small;
        // }

        if (data.title && (!data.firstName || !data.lastName)) {
            preparedAudience.firstName = data.title.split(' ')[0].trim();
            preparedAudience.lastName = data.title.split(' ')[1]?.trim() || null;
        }
        // if (data.website_address) {
        //     preparedAudience.website = data.website_address;
        // }

        // if (data.description) {
        //     preparedAudience.description = data.description;
        // }

        if (data.first_accessed) {
            preparedAudience.firstAccessed = data.first_accessed;
            preparedAudience.eventId = evId;
            preparedAudience.appId = appId;
        }

        // preparedAudience.lastUpdated = moment().valueOf();

        // preparedAudience.attendeeType = 'Attendee';

        const socialMediaData = this._.omit(data, [
            'company',
            'website_address',
            'title',
            'lastName',
            'mobile_number',
            'sub_title',
            'firstName',
            'email_address',
            'company',
            'id',
            'page_id',
            'show_email',
            'description',
            'icon',
            'app_rating',
            'designation',
            'first_accessed',
            'icon_small',
            'facebook_account',
            'instagram_account',
            'linkedin_account',
            'twitter_account',
            'youtube_account',
            'tags',
            'downloaded',
            'session',
        ]);

        preparedAudience = {
            ...preparedAudience,
            socialMediaData,
        };

        return preparedAudience;
    }

    prepareImportAudienceData(data: any, evId: string, appId: string) {
        let preparedAudience: any = {};
        if (data.company) {
            preparedAudience.company = data.company;
        }

        if (data.email_address) {
            preparedAudience.email = data.email_address;
        }

        if (data.firstName) {
            preparedAudience.firstName = data.firstName;
        }

        if (data.sub_title) {
            preparedAudience.jobTitle = data.sub_title;
        }

        if (data.lastName) {
            preparedAudience.lastName = data.lastName;
        }

        if (data.mobile_number) {
            preparedAudience.mobilePhone = parsePhoneNumber(data.contact_number)?.formatInternational();
        }

        if (data.title) {
            preparedAudience.title = data.title;
        }

        if (!data.title && (data.firstName || data.lastName)) {
            preparedAudience.title = (data.firstName + ' ' + data.lastName)?.trim();
        }

        if (data.icon || data.icon_small) {
            preparedAudience.imgUrl = data.icon || data.icon_small;
        }

        if (data.title && (!data.firstName || !data.lastName)) {
            preparedAudience.firstName = data.title.split(' ')[0].trim();
            preparedAudience.lastName = data.title.split(' ')[1]?.trim() || null;
        }
        if (data.website_address) {
            preparedAudience.website = data.website_address;
        }

        if (data.description) {
            preparedAudience.description = data.description;
        }

        if (data.first_accessed) {
            preparedAudience.firstAccessed = data.first_accessed;
            preparedAudience.eventId = evId;
            preparedAudience.appId = appId;
        }

        preparedAudience.lastUpdated = moment().valueOf();

        preparedAudience.attendeeType = 'Attendee';

        const socialMediaData = this._.omit(data, [
            'company',
            'website_address',
            'title',
            'lastName',
            'mobile_number',
            'sub_title',
            'firstName',
            'email_address',
            'company',
            'id',
            'page_id',
            'show_email',
            'description',
            'icon',
            'app_rating',
            'designation',
            'first_accessed',
            'icon_small',
            'facebook_account',
            'instagram_account',
            'linkedin_account',
            'twitter_account',
            'youtube_account',
            'tags',
            'downloaded',
            'session',
        ]);

        preparedAudience = {
            ...preparedAudience,
            socialMediaData,
        };

        return preparedAudience;
    }
}
