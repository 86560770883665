import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, combineLatest, first, map, Observable, of, switchMap, take } from 'rxjs';
import { Auth } from '../model/auth.modal';
import { User } from '../model/user.modal';
import { FirebaseService } from './firebase.service';
import * as _ from 'lodash';
import { Customer } from '../model/customer.modal';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { signInWithEmailAndPassword, UserCredential } from '@angular/fire/auth';
import * as moment from 'moment';
import { UtilityService } from './utility.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { getAuth } from 'firebase/auth';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ContributorsService } from './contributors.service';

@Injectable({
    providedIn: 'root',
})
export class UserService implements OnInit {
    constructor(
        private http: HttpClient,
        private Cookies: CookieService,
        private Firebase: FirebaseService,
        private auth: AngularFireAuth,
        private utilities: UtilityService,
        private db: AngularFireDatabase,
        private contributorService: ContributorsService
    ) {}
    user!: User;
    user$ = new BehaviorSubject(this.user);

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle
    ngOnInit(): void {
        console.log('User-service Called !');
    }

    async login(data: Auth, tenantId?: string) {
        if (tenantId) {
            const auth = getAuth();
            auth.tenantId = tenantId;
            const result = (await signInWithEmailAndPassword(auth, data.email, data.password)) as any;
            return result;
        } else {
            return (await this.auth.signInWithEmailAndPassword(data.email, data.password)) as any;
        }
    }

    setCurrent(id: string, isNeededCustomerCall = true) {
        return this.getCurrent(id).pipe(
            take(1),
            switchMap((user): any => {
                // if (!this.Cookies.get('sa_access_token')) {
                // }
                if (user?.role === 'superadmin') {
                    if (this.Cookies.get('access_token')) {
                        this.Cookies.set(
                            'sa_access_token',
                            this.Cookies.get('access_token'),
                            new Date('Thu, 01 Jan 2099 00:00:01 GMT'),
                            '/',
                            environment.cookie_domain,
                            true,
                            environment.cookie_same_site as any
                        );
                    }
                }
                if (user?.customerId) {
                    this.Cookies.set(
                        'user_customer_id',
                        user.customerId,
                        new Date('Thu, 01 Jan 2099 00:00:01 GMT'),
                        '/',
                        environment.cookie_domain,
                        true,
                        environment.cookie_same_site as any
                    );

                    localStorage.setItem('user_customer_id', user.customerId);

                    if (isNeededCustomerCall) {
                        return this.Firebase.getCustomerById(user.customerId).pipe(
                            take(1),
                            map((cutomer) => cutomer)
                        );
                    } else {
                        return of(null);
                    }
                } else {
                    return of(null);
                }
            })
        ) as Observable<Customer>;
    }

    setCurrentUser(user: any) {
        if (user?.role === 'superadmin') {
            if (this.Cookies.get('access_token')) {
                this.Cookies.set(
                    'sa_access_token',
                    this.Cookies.get('access_token'),
                    new Date('Thu, 01 Jan 2099 00:00:01 GMT'),
                    '/',
                    environment.cookie_domain,
                    true,
                    environment.cookie_same_site as any
                );
            }
        }

        if (user?.customerId) {
            this.Cookies.set(
                'user_customer_id',
                user.customerId,
                new Date('Thu, 01 Jan 2099 00:00:01 GMT'),
                '/',
                environment.cookie_domain,
                true,
                environment.cookie_same_site as any
            );

            localStorage.setItem('user_customer_id', user.customerId);
            return combineLatest([
                // this.Firebase.getCustomerOwnApp(user.customerId),
                this.Firebase.getCustomerHelloCrowdEvent(user.customerId),
                this.Firebase.getCustomerEvents(user.customerId),
            ]).pipe(
                take(1),
                map((values: any) => {
                    return values;
                })
            );

            // return this.Firebase.getCustomerById(user.customerId).pipe(
            //     take(1),
            //     map((cutomer) => cutomer)
            // );
        } else {
            return of(null);
        }
    }

    updateUser(data: any, userId: string) {
        return new Promise((resolve, reject) => {
            // if(
            const ref = `users/${userId}`;
            this.Firebase.updateByRef(ref, data)
                .then(() => resolve(true))
                .catch((erorr) => {
                    console.log('error', erorr);
                    reject(erorr);
                });
        });
    }

    async setAuthTokens(res: UserCredential, rememberMe = false) {
        res.user.getIdToken().then((token: string) => {
            // console.log('token', token);
            this.Cookies.set(
                'access_token',
                token,
                new Date('Thu, 01 Jan 2099 00:00:01 GMT'),
                '/',
                environment.cookie_domain,
                true,
                'None'
            );
            // this.Cookies.set('user_hash', res.user_hash);
            localStorage.setItem('access_token_expired', String(86400 * 1000 + new Date().getTime()));
            localStorage.setItem('access_token', token);
            return true;
        });
    }

    getAuthToken() {
        return this.Cookies.get('access_token') || localStorage.getItem('access_token');
    }

    getCurrent(id = ''): Observable<User | null> {
        const userID = ((id || localStorage.getItem('user_id')) as string) || this.Cookies.get('user_id');
        // this.Firebase.updateByRef(`users/${userID}`, {
        //     status: 'Active',
        // });
        return (this.Firebase.getByRef(`users/${userID}`) as Observable<User>).pipe(
            take(1),
            // switchMap((user: any): any => {
            // return this.isSSO(userID).pipe(
            map((user) => {
                if (user) {
                    user.id = userID;
                    user = this.transform(user);
                    return user;
                } else {
                    return null;
                }
            })
            // );
            // })
        );
    }

    getHeaderUsersData(id = ''): Observable<User | null> {
        const userID = ((id || localStorage.getItem('user_id')) as string) || this.Cookies.get('user_id');
        combineLatest([
            this.Firebase.getByRef(`users/${userID}/role`),
            this.Firebase.getByRef(`users/${userID}/icon_url`),
            this.Firebase.getByRef(`users/${userID}/first_name`),
            this.Firebase.getByRef(`users/${userID}/last_name`),
            this.Firebase.getByRef(`users/${userID}/company_name`),
        ]).pipe(
            map((values: any) => {
                if (values?.length) {
                    const user = {
                        role: values[0] || null,
                        icon: values[1] || null,
                        first_name: values[2],
                        last_name: values[3],
                        company_name: values[4],
                    };

                    return user;
                } else {
                    return null;
                }
            })
        );

        return (this.Firebase.getByRef(`users/${userID}`) as Observable<User>).pipe(
            take(1),
            map((user) => {
                if (user) {
                    user.id = userID;
                    user = this.transform(user);
                    return user;
                } else {
                    return null;
                }
            })
        );
    }

    getSaAuthToken() {
        return this.Cookies.get('sa_access_token');
    }

    getUserData() {
        return this.http.get('https://api.ipgeolocation.io/ipgeo?apiKey=6a3ec34047804b3bb5c3dd43a89b8595');
    }

    isSSO(userId: string) {
        return this.Firebase.getByRef('users_pairing').pipe(
            map((linking: any) => {
                const keys = _.keys(linking);
                const values = _.values(linking);
                return (keys.indexOf(userId) !== -1 || values.indexOf(userId) !== -1) as boolean;
            })
        );
    }

    transform(user: any) {
        let initials = user.first_name ? user.first_name.charAt(0).toUpperCase() : '';
        initials += user.last_name ? user.last_name.charAt(0).toUpperCase() : '';

        // role: role;
        // icon: icon_url;
        // first_name: first_name;
        // last_name: last_name;
        // company_name: company_name;
        return {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            initials,
            email: user.email,
            icon: user.icon_url,
            icon_small: user.icon_url_small,
            company_name: user.company_name,
            country: user.country,
            currency: user.currency,
            currency_symbol: user.currency_symbol,
            verified: user.verified,
            verify_token: user.verify_token,
            hadTour: user.hadTour || false,
            tourIndex: user.tourIndex || 0,
            isSSO: user.isSSO,
            role: user.role,
            user_hash: user.user_hash || '',
            customerId: user.customerId || '',
            contribute_apps_events: user.contribute_apps_events || null,
            app: user.apps || null,
            country_code: user.country_code || '',
            timezone: user.timezone || '',
            is_invite_page_seen: user.is_invite_page_seen || undefined,
        };
    }

    hasAccess(appId: string, eventId: string, userId: string) {
        return (this.Firebase.getByRef(`apps/${appId}/config`) as Observable<any>).pipe(
            switchMap((app: any): any => {
                const customerId =
                    localStorage.getItem('customer_id') ||
                    this.Cookies.get('customer_id') ||
                    localStorage.getItem('user_customer_id') ||
                    this.Cookies.get('user_customer_id');
                if (appId === 'HELLOCROWD' || (app && app.users)) {
                    return combineLatest([
                        this.Firebase.getByRef(`apps/${appId}/public/events_list/${eventId}`),
                        // this.Firebase.getByRef(`apps/${appId}/public/events_users/${eventId}`),
                    ]).pipe(
                        take(1),
                        switchMap((values: any): any => {
                            if (!values[0]) {
                                return false;
                            } else {
                                let role = 'user';
                                if (app.admins && _.keys(app.admins).indexOf(userId) !== -1) {
                                    role = 'admin';
                                }
                                if (app.locations_admins) {
                                    _.forOwn(app.locations_admins, (val, locationId) => {
                                        const ids = _.keys(val);
                                        if (ids.indexOf(userId) !== -1) {
                                            role = 'location_admin';
                                        }
                                    });
                                }
                                const ownerId = values[0].user_id.replace('user', '');
                                // const contributors = values[1];
                                // console.log('==============customerId', customerId);

                                return combineLatest([
                                    this.Firebase.getByRef(`customers/${customerId}/teamMembers`),
                                    this.contributorService.getContributore(),
                                    // this.Firebase.getDbUserByUserId(ownerId),
                                ]).pipe(
                                    take(1),
                                    map((values) => {
                                        // const owner = values[1];
                                        const customer: any = { teamMembers: values[0] };
                                        const contributor = values[1];
                                        if (
                                            ownerId === userId ||
                                            // ||
                                            // _.keys(contributors).indexOf(userId) !== -1 ||
                                            role === 'admin' ||
                                            role === 'location_admin' ||
                                            _.keys(customer.teamMembers).indexOf(userId) !== -1 ||
                                            contributor?.[customerId]?.events?.[eventId]
                                        ) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    })
                                );
                            }
                        })
                    );
                } else {
                    return combineLatest([
                        this.Firebase.getByRef(`customers/${customerId}/teamMembers`),
                        this.Firebase.getByRef(`apps/${appId}/users`),
                    ]).pipe(
                        take(1),
                        map((values) => {
                            const contributors = values[1];
                            const customer: any = { teamMembers: values[0] };

                            const owner = app && app.owner ? app.owner : null;
                            if (
                                owner === userId ||
                                _.keys(contributors).indexOf(userId) !== -1 ||
                                _.keys(customer.teamMembers).indexOf(userId) !== -1
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                    );
                }
            })
        );
    }

    // signup(email: string, password: string, user_data: any = {}) {
    //     return new Promise((resolve, reject) => {
    //         email = email.toLowerCase();
    //         this.createUser(email, password)
    //             .then((response: any) => {
    //                 const user = response.user;
    //                 const userId = user.uid;
    //                 // console.log('userUId', userId);
    //                 this.setAuthTokens(response);
    //                 this.createCustomerKey().then((key) => {
    //                     const prepareData = {
    //                         defaultCountry: user_data.country_code2 || user_data.country_code,
    //                         defaultCurrency: user_data.currency.code || 'US',
    //                         defaultTimeZone: user_data.time_zone.name || 'UTC',
    //                         teamMembers: {
    //                             [`${userId}`]: 'Owner',
    //                         },
    //                         ownApp: false,
    //                         createdAt: moment().unix(),
    //                     };
    //                     this.db.database
    //                         .ref()
    //                         .child(`customers/${key}`)
    //                         .update(prepareData)
    //                         .then(() => {
    //                             user_data.customerId = key;
    //                             this.createDbUser(userId, email, '', password, user_data)
    //                                 .then(() => {
    //                                     console.log('userID', userId);
    //                                     this.Cookies.set('user_id', userId);
    //                                     this.Cookies.set('customer_id', user_data.customerId);
    //                                     localStorage.setItem('user_id', userId);
    //                                     localStorage.setItem('customer_id', user_data.customerId);
    //                                     this.getUserHash().then((res: string) => {
    //                                         resolve(true);
    //                                     });
    //                                 })
    //                                 .catch((error) => {
    //                                     console.log(error);
    //                                     reject(error);
    //                                 });
    //                         })
    //                         .catch((error) => {
    //                             console.log('Customer creation errror', error);
    //                         });
    //                 });
    //             })
    //             .catch((error) => {
    //                 console.log('Error', error);
    //                 reject();
    //             });
    //     });
    // }

    createUser(emial: string, password: string, customerId: any = null) {
        return new Promise((resolve, reject) => {
            if (customerId) {
                this.Firebase.getByRef(`customers/${customerId}`)
                    .pipe(take(1))
                    .subscribe((data: any) => {
                        if (data.idPlatform) {
                            // this.auth.tenantId = 'TENANT_ID1';
                            const auth = getAuth();
                            auth.tenantId = data.idPlatform;
                            console.log('====auth====', auth);
                            this.auth.tenantId = data.idPlatform;
                            // this.auth.tenantId.then(value => {
                            //     console.log('valeu');
                            //     value.
                            // })
                            createUserWithEmailAndPassword(auth, emial, password)
                                .then((data) => {
                                    resolve(data);
                                })
                                .catch((error) => {
                                    console.log('Error', error);
                                    reject(error);
                                });
                        } else {
                            console.log('Else CALLED !!');
                            this.auth
                                .createUserWithEmailAndPassword(emial, password)
                                .then((data) => {
                                    resolve(data);
                                })
                                .catch((error) => {
                                    console.log('Error', error);
                                    reject(error);
                                });
                        }
                    });
            } else {
                this.auth
                    .createUserWithEmailAndPassword(emial, password)
                    .then((data) => {
                        resolve(data);
                    })
                    .catch((error) => {
                        console.log('Error', error);
                        reject(error);
                    });
            }
        });
    }

    createDbUser(
        uid: string,
        email: string,
        name: string,
        password: string,
        user: any = {},
        activated = true,
        idPlatform = ''
    ) {
        console.log('User', user);
        return new Promise((resolve, reject) => {
            console.log('createDbUser', uid, email, name, password, user, activated);
            const availableCurrencies = ['ZAR', 'USD', 'GBP', 'EUR'];
            const currencySymbols: any = {
                ZAR: 'R',
                USD: '$',
                GBP: '£',
                EUR: '€',
            };
            const userData: any = {
                timezone: 'UTC',
                location: null,
                country_code: 'US',
                currency: 'USD',
                country_name: null,
                currency_symbol: '$',
                activated,
                user_id: uid,
                email: email.toLowerCase(),
                first_name: name.split(' ')[0] || '',
                last_name: name.split(' ')[1] || '',
                registered: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                customerId: user?.customerId || null,
                idPlatform: idPlatform || null,
            };
            if (!activated) {
                userData.activation_code = this.utilities.generateUID(10).toLowerCase();
            }

            const cc = user && user.country_code2 ? user.country_code2 : userData.country_code;
            console.log('=====cc', cc);
            this.getCountry(cc).then((country: any) => {
                if (user && country) {
                    userData.country_code = country.iso_code || '';
                    userData.country_name = country.name || '';
                    userData.timezone = user.location && user.location.time_zone ? user.location.time_zone : 'UTC';
                    userData.location = '';

                    if (availableCurrencies.indexOf(country.currency_code) !== -1) {
                        userData.currency = country.currency_code;
                    }
                    userData.currency_symbol = currencySymbols[userData.currency];

                    // user
                    userData.remote_address = user && user.ip ? user.ip : null;
                    userData.payment_profile = {
                        company_name: '',
                        currency: userData.currency,
                    };
                    // console.log(userData);
                    this.Firebase.updateByRef(`users/${uid}`, userData)
                        .then(() => {
                            resolve(userData);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    this.Firebase.updateByRef(`users/${uid}`, userData)
                        .then(() => {
                            resolve(userData);
                        })
                        .catch((error) => {
                            console.log('Error', error);
                            reject(error);
                        });
                }
            });
        });
    }

    getOrCreateDbUserFromCredential(data: any, uid: string) {
        return new Promise((resolve, reject) => {
            // this._getLinking(uid)
            //     .then((linking: any) => {
            //         if (linking) {
            //             this.Firebase.getDbUser(linking).then((user: any) => {
            //                 user.id = linking;
            //                 resolve(this.transform(user));
            //             });
            //         } else {
            //         }
            //     })
            //     .catch((error) => {
            //         reject(error);
            //     });

            const email = data.email;
            this.Firebase.getDbUserByUserId(uid)
                .pipe(take(1))
                .subscribe((user: any) => {
                    if (user) {
                        console.log('getUseBy Email', user);
                        user.first_name = data?.displayName?.split(' ')?.[0] || null;
                        user.last_name = data?.displayName?.split(' ')?.[1] || null;
                        this.Firebase.updateByRef(`users/${user.id}`, {
                            first_name: data?.displayName?.split(' ')?.[0] || null,
                            last_name: data?.displayName?.split(' ')?.[1] || null,
                        }).then(() => {
                            resolve(this.transform(user));
                        });
                        // if()

                        // this._createLinking(uid, user.id).then(() => {});
                    } else {
                        console.log('CREATING NEW');
                        this.createCustomerKey().then((key) => {
                            this.getUserData()
                                .pipe(take(1))
                                .subscribe((val: any) => {
                                    console.log('===============val', val);

                                    const prepareData = {
                                        defaultCountry: val?.country_code2 || 'SA',
                                        defaultCurrency: val?.currency?.code || 'ZAR',
                                        defaultTimeZone: val?.time_zone?.name
                                            ? this.tzPrettyString(val?.time_zone?.name)
                                            : '(UTC+02:00) Africa/Johannesburg',
                                        teamMembers: {
                                            [`${uid}`]: 'Owner',
                                        },
                                        ownApp: false,
                                    };

                                    this.db.database
                                        .ref()
                                        .child(`customers/${key}`)
                                        .update(prepareData)
                                        .then(() => {
                                            const newUser: any = {
                                                email: data.email,
                                                first_name: data?.displayName?.split(' ')?.[0] || null,
                                                last_name: data?.displayName?.split(' ')?.[1] || null,
                                                icon_url: data.photoURL || null,
                                                icon_url_small: data.photoURL || null,
                                                role: 'user',
                                                terms_accepted: 1,
                                                verified: 1,
                                                currency: val?.currency?.code || 'USD',
                                                currency_symbol: val?.currency?.symbol || '$',
                                                user_id: uid,
                                                customerId: key,
                                            };
                                            console.log(newUser);
                                            console.log('------');
                                            this.Firebase.setByRef(`users/${uid}`, newUser)
                                                .then(() => {
                                                    newUser.id = uid;
                                                    console.log('RETURNING CREATED USER');
                                                    const response: any = this.transform(newUser);
                                                    response.is_new = true;
                                                    resolve(response);
                                                    // this._createLinking(uid, newUser.id).then(() => {});
                                                })
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        })
                                        .catch((error) => {
                                            console.log('Customer creation errror', error);
                                        });
                                });
                        });
                    }
                });
        });
    }

    tzPrettyString(zone: string) {
        if (!zone?.includes('UTC') && zone) {
            // const changeZone = this.utility.getTimeZoneWithUnderScore(zone);
            return '(UTC' + moment.tz(zone).format('Z') + ') ' + zone.replace(/_/g, ' ');
        }

        return zone;
    }

    _createLinking(uid: any, uid2: any) {
        console.log('_createLinking', uid, uid2);
        return new Promise((resolve, reject) => {
            this.Firebase.updateByRef('users_pairing', { [uid]: uid2 }).then(() => {
                resolve(true);
            });
        });
    }

    _getLinking(uid: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`users_pairing/${uid}`)
                .pipe(first())
                .subscribe(
                    (linking) => {
                        resolve(linking);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    getCountry(code: string) {
        return new Promise((resolve, reject) => {
            if (code) {
                this.db.database
                    .ref()
                    .child('countries')
                    .orderByChild('iso_code')
                    .equalTo(code)
                    .once(
                        'value',
                        (snapshot) => {
                            if (snapshot) {
                                resolve(_.values(snapshot.val())[0]);
                            } else {
                                reject(null);
                            }
                        },
                        (error) => reject(error)
                    );
            } else {
                resolve({
                    name: '',
                });
            }
        });
    }

    createCustomerKey() {
        return new Promise((resolve, reject) => {
            const url = `${environment.firebaseConfig.databaseURL}/customers.json?auth=${environment.firebaseConfig.secret}&shallow=true`;
            this.http.get(url).subscribe((items: any) => {
                let customer = this.utilities.generateUID(5);
                if (!items) {
                    return resolve(customer);
                }

                const ids = Object.keys(items);
                while (ids.indexOf(customer) !== -1) {
                    customer = this.utilities.generateUID(5);
                }
                resolve(customer);
            });
        });
    }

    getUserHash(uid = ''): Promise<string> {
        const url = `${environment.fcfApiUrl}/v2auth/user-hash`;
        return new Promise((resolve, reject) => {
            if (uid) {
                this.Cookies.set(
                    'user_hash',
                    '',
                    new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
                    '/',
                    environment.cookie_domain,
                    true,
                    environment.cookie_same_site as any
                );
                localStorage.removeItem('user_hash');
            }

            const user_hash = localStorage.getItem('user_hash') || this.Cookies.get('user_hash');
            if (user_hash && !uid) {
                resolve(user_hash);
            } else {
                const userId = uid || localStorage.getItem('user_id') || this.Cookies.get('user_id');
                this.http.post<{ user_hash: string }>(url, { userId }).subscribe({
                    next: (res) => {
                        localStorage.setItem('user_hash', res.user_hash);
                        // this.Cookies.delete('user_hash');
                        this.Cookies.set(
                            'user_hash',
                            res.user_hash,
                            new Date('Thu, 01 Jan 2099 00:00:01 GMT'),
                            '/',
                            environment.cookie_domain,
                            true,
                            environment.cookie_same_site as any
                        );
                        resolve(res.user_hash);
                        // setTimeout(() => {
                        // }, 500);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
            }
        });
    }

    getAllDbUser() {
        return this.Firebase.getByRef(`users`);
    }

    removeDbUserById(uid: string) {
        return this.db.database.ref().child(`users/${uid}`).remove();
    }

    getUsersFromUserCollection(data: any) {
        return new Promise((resolve) => {
            this.Firebase.getDbUserByEmail(data?.email).then((res) => {
                resolve(res);
            });
        });
    }
}
