import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Observable, combineLatest, map, of, retry, shareReplay, take } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import * as _ from 'lodash';
const CACHE_SIZE = 1;

@Injectable({
    providedIn: 'root',
})
export class ContributorsService {
    // contributor!: any;
    constructor(private Firebase: FirebaseService, private Cookies: CookieService) {}
    public cacheContributor$!: Observable<any> | null;

    // getContributorById(uId = '') {
    //     const userId = uId || localStorage.getItem('user_id') || this.Cookies.get('user_id');
    //     const userCustomerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');

    //     if (userCustomerId) {
    //         return combineLatest([
    //             this.Firebase.getByRef(`customers/${userCustomerId}/teamMembers`),
    //             this.Firebase.getByRef(`contributors/${userId}`),
    //         ]).pipe(
    //             take(1),
    //             map((value) => {
    //                 const accountTeamMembers: any = value[0];
    //                 const contributor = value[1];
    //                 let returnData: any = null;
    //                 // console.log('=====accountTeamMembers', accountTeamMembers);
    //                 if (
    //                     contributor &&
    //                     (accountTeamMembers?.[userId].toLowerCase() === 'owner' ||
    //                         accountTeamMembers?.[userId].toLowerCase() === 'admin')
    //                 ) {
    //                     const keys = Object.keys(contributor).filter((key: string) => key !== userCustomerId);
    //                     if (keys) {
    //                         keys.forEach((k: any) => {
    //                             returnData = {
    //                                 ...returnData,
    //                                 [k]: contributor[k],
    //                             };
    //                         });

    //                         return returnData;
    //                     } else {
    //                         return contributor;
    //                     }
    //                 } else {
    //                     return contributor;
    //                 }
    //             })
    //         );
    //     } else {
    //         return this.Firebase.getByRef(`contributors/${userId}`).pipe(
    //             take(1),
    //             map((value) => {
    //                 return value;
    //             })
    //         );
    //     }
    // }

    getContributore(uId = '') {
        const userId = uId || localStorage.getItem('user_id') || this.Cookies.get('user_id');
        const userCustomerId = localStorage.getItem('user_customer_id') || this.Cookies.get('user_customer_id');

        if (!this.cacheContributor$) {
            if (userCustomerId) {
                const contributo$ = combineLatest([
                    this.Firebase.getByRef(`customers/${userCustomerId}/teamMembers`),
                    this.Firebase.getByRef(`contributors/${userId}`),
                ])
                    // return this.Firebase.getByRef(`contributors/${userId}`)
                    .pipe(
                        map((value) => {
                            const accountTeamMembers: any = value[0];
                            const contributor = value[1];

                            let returnData: any = null;
                            if (
                                contributor &&
                                (accountTeamMembers?.[userId]?.toLowerCase() === 'owner' ||
                                    accountTeamMembers?.[userId]?.toLowerCase() === 'admin')
                            ) {
                                const keys = Object.keys(contributor).filter((key: string) => key !== userCustomerId);
                                if (keys) {
                                    keys.forEach((k: any) => {
                                        returnData = {
                                            ...returnData,
                                            [k]: contributor[k],
                                        };
                                    });
                                    return returnData;
                                    // this.cacheContributor$ = of(returnData).pipe(shareReplay(1));
                                    // return this.cacheContributor$;
                                } else {
                                    return contributor;
                                    // this.cacheContributor$ = of(contributor).pipe(shareReplay(1));
                                    // return this.cacheContributor$;
                                }
                            } else {
                                return contributor;
                                // this.cacheContributor$ = of(contributor).pipe(shareReplay(1));
                                // return this.cacheContributor$;
                            }
                        })
                    );

                this.cacheContributor$ = contributo$.pipe(shareReplay(CACHE_SIZE));
                return this.cacheContributor$;
            } else {
                this.cacheContributor$ = this.Firebase.getByRef(`contributors/${userId}`).pipe(shareReplay(CACHE_SIZE));
                return this.cacheContributor$;
            }
        } else {
            // console.log('====caches===');
            return this.cacheContributor$;
        }
    }

    updateContributorEventStatus(userId: string) {
        return new Promise((resolve, reject) => {
            this.getContributore(userId)
                .pipe(take(1))
                .subscribe((contributor: any) => {
                    if (contributor) {
                        const promise: any[] = [];
                        _.keys(contributor).forEach((customerId: string) => {
                            // console.log('customerId', customerId);
                            _.keys(contributor[customerId]?.events).forEach((evId: string) => {
                                // console.log(
                                //     '=================contributor[customerId]?.events?.[evId]?.status',
                                //     contributor[customerId]?.events?.[evId]?.status
                                // );
                                if (contributor[customerId]?.events?.[evId]?.status?.toLowerCase() === 'invited') {
                                    promise.push(
                                        this.Firebase.updateByRef(
                                            `contributors/${userId}/${customerId}/events/${evId}`,
                                            {
                                                ...contributor[customerId]?.events?.[evId],
                                                status: contributor[customerId]?.events?.[evId]?.role,
                                            }
                                        )
                                    );
                                }
                            });
                        });

                        Promise.all(promise).then(() => {
                            resolve(true);
                        });
                    } else {
                        resolve(null);
                    }
                });
        });
    }

    updateContributorStatus(userId: string, eventId: string, customerId: string, status: string) {
        // return new Promise((resolve, reject) => {
        return this.Firebase.updateByRef(`/contributors/${userId}/${customerId}/events/${eventId}`, {
            status: status,
        });
        // })
    }

    updateContributor(userId: string, eventId: string, customerId: string, data: any) {
        return this.Firebase.updateByRef(`/contributors/${userId}/${customerId}/events/${eventId}`, data);
    }
}
