import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class CustomerTrasformerService {
    _ = lodash;
    formats = [
        { db: 'd/m/Y', app: 'DD/MM/YYYY' },
        { db: 'm/d/Y', app: 'MM/DD/YYYY' },
        { db: 'Y/m/d', app: 'YYYY/MM/DD' },
        { db: 'H:i', app: 'H:mm' },
        { db: 'h:i a', app: 'h:mm a' },
    ];

    prepareData(data: any) {
        let timeZoneFormatType = 'abbr';
        if (data.time_zone_format) {
            if (data.time_zone_format.includes('/') && !data.time_zone_format.includes('(')) {
                timeZoneFormatType = 'timeZone';
            } else if (
                !data.time_zone_format.includes(' ') &&
                data.time_zone_format.length < 6 &&
                data.time_zone_format.toUpperCase() === data.time_zone_format
            ) {
                timeZoneFormatType = 'abbr';
            } else if (data.time_zone_format.includes('(') && data.time_zone_format.split(' ').length > 1) {
                timeZoneFormatType = 'text';
            } else if (data.time_zone_format.includes('(') && data.time_zone_format.split(' ').length === 1) {
                timeZoneFormatType = 'default';
            } else {
                timeZoneFormatType = 'value';
            }
        }
        const prepared: any = {
            title: data.title || '',
            time_zone: data.time_zone || '',
            start_date_time_unix: moment(data.start_date).toDate() || '',
            end_date_time_unix: moment(data.end_date).toDate() || '',
            type: data.type || '',
            category: data.category || '',
            // description: data.description || '',
            colour_scheme: data.color || '',
            locationType: data.locationType || 'venue',
            time_zone_format: timeZoneFormatType || '',
            subAccounts: data.subAccounts || null,
        };
        // isEventWebSiteEnable: data.isEventWebSiteEnable || false,
        // isRegistrationEnable: data.isRegistrationEnable || false,
        // isTicketAndRegistrationEnable: data.isTicketAndRegistrationEnable || false,
        // isEventFeedbackEnable: data.isEventFeedbackEnable || false,
        // isMarketingEnable: data.isMarketingEnable || false,

        if (data.status) {
            prepared.status = data.status;
        }
        if (data.isMobileAppEnable) {
            data.isMobileAppEnable = data.isMobileAppEnable || false;
        }
        if (data.isEventWebSiteEnable) {
            data.isEventWebSiteEnable = data.isEventWebSiteEnable || false;
        }
        if (data.isRegistrationEnable) {
            data.isRegistrationEnable = data.isRegistrationEnable || false;
        }
        if (data.isTicketAndRegistrationEnable) {
            data.isTicketAndRegistrationEnable = data.isTicketAndRegistrationEnable || false;
        }
        if (data.isEventFeedbackEnable) {
            data.isEventFeedbackEnable = data.isEventFeedbackEnable || false;
        }
        if (data.isMarketingEnable) {
            data.isMarketingEnable = data.isMarketingEnable || false;
        }
        // if (data.virtual && !data.venue.title) {
        //     prepared.locationType = 'online';
        // } else if (data.virtual && data.venue.title) {
        //     prepared.locationType = 'blank';
        // } else {
        //     prepared.locationType = 'venue';
        // }

        if (data.description) {
            prepared.description = data.description || '';
        }

        if (data.location) {
            prepared.location = data.location;
        }

        if (data.summary) {
            prepared.summary = data.summary || '';
        }

        if (data.user_id) {
            prepared.user_id = data.user_id;
        }
        if (data.created_at) {
            prepared.created_at = data.created_at;
        }

        if (data.app_id) {
            prepared.app_id = data.app_id;
        }

        if (data.icon) {
            prepared.app_icon = data.icon;
        }

        if (data.time_format) {
            //find in formats

            const format = this._.find(this.formats, { app: data.time_format });
            if (format) {
                prepared.time_format = format.db;
            } else {
                prepared.time_format = 'H:i';
            }
        }
        if (data.date_format) {
            //find in format
            const format = this._.find(this.formats, { app: data.date_format });
            if (format) {
                prepared.date_format = format.db;
            } else {
                prepared.date_format = 'd/m/Y';
            }
        }

        // if(data.)

        return prepared;
    }

    prepareCopyEventData(data: any) {
        let timeZoneFormatType = 'abbr';
        if (data.time_zone_format) {
            if (data.time_zone_format.includes('/') && !data.time_zone_format.includes('(')) {
                timeZoneFormatType = 'timeZone';
            } else if (
                !data.time_zone_format.includes(' ') &&
                data.time_zone_format.length < 6 &&
                data.time_zone_format.toUpperCase() === data.time_zone_format
            ) {
                timeZoneFormatType = 'abbr';
            } else if (data.time_zone_format.includes('(') && data.time_zone_format.split(' ').length > 1) {
                timeZoneFormatType = 'text';
            } else if (data.time_zone_format.includes('(') && data.time_zone_format.split(' ').length === 1) {
                timeZoneFormatType = 'default';
            } else {
                timeZoneFormatType = 'value';
            }
        }
        const prepared: any = {
            title: data.title || '',
            time_zone: data.time_zone || '',
            start_date_time_unix: moment(data.start_date).toDate() || '',
            end_date_time_unix: moment(data.end_date).toDate() || '',
            type: data.type || '',
            category: data.category || '',
            // description: data.description || '',
            colour_scheme: data.color || '',
            locationType: data.locationType || 'venue',
            time_zone_format: timeZoneFormatType || '',
            subAccounts: data.subAccounts || null,
            status: data.status || 'DRAFT',
            isMobileAppEnable: data.isMobileAppEnable || null,
            isEventWebSiteEnable: data.isEventWebSiteEnable || null,
            isRegistrationEnable: data.isRegistrationEnable || null,
            isTicketAndRegistrationEnable: data.isTicketAndRegistrationEnable || null,
            isEventFeedbackEnable: data.isEventFeedbackEnable || null,
            isMarketingEnable: data.isMarketingEnable || null,
            description: data.description || null,
            location: data.location || null,
            summary: data.summary || null,
            user_id: data.user_id || null,
            bodyFontFile: data.bodyFontFile || null,
            bodyFonts: data.bodyFonts || null,
            colours: data.colours || null,
            darkCustomerLogo: data.darkCustomerLogo || null,
            eventCapacity: data.eventCapacity || null,
            freeEventOgImage: data.freeEventOgImage || null,
            headerFontFile: data.headerFontFile || null,
            headerFonts: data.headerFonts || null,
            app_id: data.app_id || null,
            app_icon: data.icon || null,
            isCausesEnable: data.isCausesEnable || null,
            lightCustomerLogo: data.lightCustomerLogo || null,
            openAI: data.openAI || null,
            paidEventOgImage: data.paidEventOgImage || null,
            style: data.style || null,
            bankId: data.bankId || null,
            currency: data.currency || null,
        };

        // if (data.created_at) {
        //     prepared.created_at = data.created_at;
        // }

        if (data.time_format) {
            //find in formats

            const format = this._.find(this.formats, { app: data.time_format });
            if (format) {
                prepared.time_format = format.db;
            } else {
                prepared.time_format = 'H:i';
            }
        }
        if (data.date_format) {
            //find in format
            const format = this._.find(this.formats, { app: data.date_format });
            if (format) {
                prepared.date_format = format.db;
            } else {
                prepared.date_format = 'd/m/Y';
            }
        }

        // if(data.)

        return prepared;
    }

    transform(event: any, customerId = '', evId = '') {
        // console.log('=====event====', event.date_format)
        return {
            id: event.id || evId,
            title: event.title,
            description: event.description || '',
            date_format: event.date_format,
            time_format: event.time_format,
            time_zone: event.time_zone,
            color: event.colour_scheme,
            created_at: event.created_at,
            user_id: event.user_id,
            location: event.location || '',
            type: event.type || '',
            category: event.category || '',
            isMobileAppEnable: event.isMobileAppEnable || false,
            isEventWebSiteEnable: event.isEventWebSiteEnable || false,
            isRegistrationEnable: event.isRegistrationEnable || false,
            isTicketAndRegistrationEnable: event.isTicketAndRegistrationEnable || false,
            isEventFeedbackEnable: event.isEventFeedbackEnable || false,
            isMarketingEnable: event.isMarketingEnable || false,
            isCausesEnable: event.isCausesEnable || false,
            locationType: event.locationType || false,
            summary: event.summary || '',
            start_date: event.start_date_time_unix,
            end_date: event.end_date_time_unix,
            app_id: event.app_id || '',
            status: event.status || 'DRAFT',
            eventIcon: event.eventIcon || '',
            icon: event.app_icon || '',
            eventCapacity: event.eventCapacity || '',
            bankId: event.bankId || '',
            isPublished: event.isPublished,
            currency: event.currency || null,
            eloquaId: event.eloquaId || null,
            totalAmount: event.totalAmount || null,
            plan: event.plan || null,
            isEmailRemindersSent: event.isEmailRemindersSent || null,
            isSMSRemindersSent: event.isSMSRemindersSent || null,
            colours: event.colours || null,
            headerFontFile: event.headerFontFile || null,
            bodyFontFile: event.bodyFontFile || null,
            headerFonts: event.headerFonts || null,
            bodyFonts: event.bodyFonts || null,
            style: event.style || null,
            openAI: event.openAI || null,
            darkCustomerLogo: event.darkCustomerLogo || null,
            lightCustomerLogo: event.lightCustomerLogo || null,
            time_zone_format: event.time_zone_format || null,
            subAccounts: event.subAccounts || [],
            customerId: customerId || null,
            isEmailInvitationSent: event.isEmailInvitationSent,
            isSMSInvitationSent: event.isSMSInvitationSent,
            isEmailInvitationReminderSent: event.isEmailInvitationReminderSent,
            isSMSInvitationReminderSent: event.isSMSInvitationReminderSent,
            freeEventOgImage: event.freeEventOgImage || null,
            paidEventOgImage: event.paidEventOgImage || null,
            eventOnlineUrl: event?.eventOnlineUrl || '',
        };
    }

    transformSingle(item: any) {
        if (!item) {
            return;
        }
        const transoformedItem = item;
        item.startDatetime = item.start_date
            ? moment(item.start_date.replace(' ', 'T')).format('YYYY-MM-DD HH:mm:ss')
            : undefined;
        if (!item.start_date) {
            item.start_date = null;
        }
        item.endDatetime = item.end_date
            ? moment(item.end_date.replace(' ', 'T')).format('YYYY-MM-DD HH:mm:ss')
            : undefined;
        if (!item.end_date) {
            item.end_date = null;
        }

        if (item.time_format) {
            //find in formats
            // let format = this._.find(this.formats, { db: item.time_format });
            const format = this.formats.find((f) => f.db === item.time_format);
            if (format) {
                transoformedItem.time_format = format.app;
            } else {
                transoformedItem.time_format = 'H:mm';
            }
        }

        if (item.date_format) {
            //find in formats
            // let format = this._.find(this.formats, { db: item.date_format });
            const format = this.formats.find((f) => f.db === item.date_format);
            if (format) {
                transoformedItem.date_format = format.app;
            } else {
                transoformedItem.date_format = 'd/M/yyyy';

                // console.log('')
            }
        }

        if (item.location) {
            transoformedItem.venue = {
                title: item.location.title,
            };
        }

        if (item.status === 'BUILD') {
            transoformedItem.netSales = '0';
            transoformedItem.ticketSold = '0';
            transoformedItem.pageViews = '0';
            transoformedItem.grossSales = '0';
            transoformedItem.ticketPaid = '0';
            transoformedItem.totalTicket = '0';
        }
        return transoformedItem;
    }
}
