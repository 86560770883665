export const environment: any = {
    firebase: {
        projectId: 'dev-hellocrowd',
        appId: '1:88538763451:web:bd5180b6b5d9e2732fd99e',
        databaseURL: 'https://dev-hellocrowd.firebaseio.com',
        storageBucket: 'dev-hellocrowd.appspot.com',
        locationId: 'us-central',
        apiKey: 'AIzaSyC2JRCOsGtzjg7PFJs_ZmwBJQV4Y0yF7z8',
        authDomain: 'v2dev.hellocrowd.net',
        messagingSenderId: '88538763451',
        measurementId: 'G-BBS7GJPMZF',
        dynamikLinkDomain: 'https://e3a8g.app.goo.gl',
        secret: 'x2TLYS2jT3TXdcQROkBjP0raaC8OO6ccQBGR4zHZ',
    },
    production: false,
    fileStackAPIKey: 'AcnVRpZ8aQD6uageVygguz',
    fcfApiUrl: 'https://us-central1-dev-hellocrowd.cloudfunctions.net',
    firebaseConfig: {
        apiKey: 'AIzaSyC2JRCOsGtzjg7PFJs_ZmwBJQV4Y0yF7z8',
        authDomain: 'v2dev.hellocrowd.net',
        databaseURL: 'https://dev-hellocrowd.firebaseio.com',
        projectId: 'dev-hellocrowd',
        storageBucket: 'dev-hellocrowd.appspot.com',
        messagingSenderId: '88538763451',
        appId: '1:88538763451:web:bd5180b6b5d9e2732fd99e',
        measurementId: 'G-BBS7GJPMZF',
        dynamikLinkDomain: 'https://e3a8g.app.goo.gl',
        secret: 'x2TLYS2jT3TXdcQROkBjP0raaC8OO6ccQBGR4zHZ',
    },
    googleMapsApiKey: 'AIzaSyCTEqdrFQA6LQNUZnJdDz4D6xERkLNucn0',
    gtm_id: 'GTM-TGH9BK',
    HCA_URL: 'https://dev.hca.io',
    PROMO_EMAILER_DEFAULT_TEXT:
        'Hi there,\n\nExciting news! Our *|EVENT_TITLE|* event is officially live! Stay up to date with the event schedule, connect with other attendees and engage in new ways. Click on the button below to get the event in the *|APP_TITLE|* app.',
    PROMO_EMAILER_DEFAULT_FOOTER_TEXT: 'See you at the event!\nThe *|APP_TITLE|* Team.',
    payStackAPIKey: 'pk_test_ef57204864fd8f1d4578405ae43720cf9912cbec',
    app_base_url: 'https://v2dev.hellocrowd.net',
    recaptchaEnterpriseKey: '6LfE3qYnAAAAABqaoVtMf6czDzyDUpPKS10XnfXW',
    tncLink: 'https://www.hellocrowd.net/legal/hellocrowd-user-terms-of-service',
    pvcPolicyLink: 'https://www.hellocrowd.net/legal/hellocrowd-privacy-policy',
    cookie_domain: 'v2dev.hellocrowd.net',
    cookie_same_site: 'None',
    stripe_publish_key: 'pk_test_juXJ4krxWUnSJ3jUNGDPUt2i',
    unplash_api_key: 'I1TkRrrq2rpLhpoSR_gsvGUbNuWrLbw_62MQQ1gtF4s',
    attendee_facing_url: 'dev.hellocrowd.events',
    stripe_pricing_tabel_id: 'prctbl_1PX1ODD8tobk0EAHP0ToUnKO',
    hellocrowd_logo_url:
        'https://cdndev.hellocrowd.net/sig/Z3M6Ly9oYy/1pbWFnZS1j/ZG4vSGVsbG/9jcm93ZC1s/b2dvXzE3MT/gxODQ0MTky/NzkucG5n',
    GCS_URL: 'https://cdndev.hellocrowd.net',
    BUCKET_NAME: 'hc-image-cdn',
    domain_ip: '34.36.250.150',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
