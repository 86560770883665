import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Feature } from '../model/page.modal';

@Injectable({
    providedIn: 'root',
})
export class SetupMenuService {
    setUpMenuOptions!: Feature;
    addOption$ = new BehaviorSubject<Feature | null>(this.setUpMenuOptions);
    aboutTitle$ = new BehaviorSubject<string | null>(null);
    optionName$ = new BehaviorSubject<any | null>(null);
    removeOptions$ = new BehaviorSubject<string | null>(null)

    constructor(private route: ActivatedRoute) { }

    // getMenuOtions() {
    //     return this.addOption$;
    // }

    addMenuOptions(options: Feature) {
        this.addOption$.next(options);
    }

    removeMenuOptions() {
        this.addOption$.next(null);
    }

    onOpionRemove(options: string) {
        console.log('options', options)
        this.removeOptions$.next(options)
    }

    onChangeAboutTitle(title: string) {
        this.aboutTitle$.next(title);
    }

    changeOptionsName(session: string, value: string) {
        this.optionName$.next([session, value]) as any;
        // this.setUpMenuOptions.forEach((d: SetUpMenu) => {
        //   if (d.option_name.toLowerCase() === value.oldVal.toLowerCase()) {
        //     d.option_name = value.newVal
        //   }
        // });
        // this.setUpmenuItems.next(this.setUpMenuOptions)
    }
}
