/* eslint-disable @typescript-eslint/no-explicit-any */
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { DatabaseReference } from '@angular/fire/compat/database/interfaces';
import * as _ from 'lodash';
import * as lodash from 'lodash';
import { Category } from 'src/app/model/category.modal';
import { Exhibitor } from 'src/app/model/exhibitor.modal';
import { Sponsor } from 'src/app/model/sponsor.modal';
import { FirebaseService } from 'src/app/service/firebase.service';
export class AbstarckPage {
    appRef!: DatabaseReference;
    publicRef!: DatabaseReference;
    dataRef!: DatabaseReference;
    sessionsRef!: DatabaseReference;
    listRef!: DatabaseReference;
    appId!: string;
    eventId!: string;
    entityPathName = '';
    entityName = '';
    entityType = '';
    Firebase!: FirebaseService;

    _ = lodash;

    constructor(public transformer: any, public db: AngularFireDatabase) {}

    create(data: any) {
        return new Promise((resolve, reject) => {
            const rawData = this.transformer.prepareData(data);
            const newEntityId = this.dataRef.child(this.entityPathName).push().key as string;
            if (this.entityPathName === 'sponsors' || this.entityPathName === 'exhibitors') {
                if (data.email_address) {
                    this.getDataByRefWhere(data).then((val: any) => {
                        if (val) {
                            reject(
                                `${this.entityPathName === 'sponsors' ? 'sponsor' : 'exhibitor'} with email ${
                                    data.email_address
                                } is already present in ${
                                    this.entityPathName === 'sponsors' ? 'sponsors' : 'exhibitors'
                                } list`
                            );
                        } else {
                            this.dataRef
                                .child(this.entityPathName)
                                .child(newEntityId)
                                .update(rawData, () => {
                                    data.id = newEntityId;
                                    this.updateTotal(this.entityPathName);
                                    resolve(data);
                                });
                        }
                    });
                } else {
                    this.dataRef
                        .child(this.entityPathName)
                        .child(newEntityId)
                        .update(rawData, () => {
                            data.id = newEntityId;
                            this.updateTotal(this.entityPathName);
                            resolve(data);
                        });
                }
            } else {
                this.dataRef
                    .child(this.entityPathName)
                    .child(newEntityId)
                    .update(rawData, () => {
                        data.id = newEntityId;
                        this.updateTotal(this.entityPathName);
                        resolve(data);
                    });
            }
        });
    }

    getDataByRefWhere(data: Sponsor | Exhibitor) {
        return new Promise((resolve, reject) => {
            this.dataRef
                .child(this.entityPathName)
                .orderByChild('email_address')
                .equalTo(data.email_address!)
                .on(
                    'value',
                    (snapshot) => {
                        resolve(snapshot.val());
                    },
                    (error) => {
                        console.log('error', error);
                    }
                );
        });
    }

    // getUserByEmail(email: string) {
    //     // let def = this.$q.defer();
    //     return new Promise((resolve) => {
    //         this.publicRef
    //             .child('users')
    //             .orderByChild('email_address')
    //             .equalTo(email.toLowerCase())
    //             .once('value')
    //             .then((snapshot) => {
    //                 const data = snapshot.val();
    //                 const arr: any[] = [];
    //                 if (data) {
    //                     this._.forOwn(data, (val, key) => {
    //                         val.id = key;
    //                         arr.push(val);
    //                     });
    //                     resolve(arr);
    //                 } else {
    //                     resolve(null);
    //                 }
    //             });
    //     });
    //     // return def.promise;
    // }

    updateTotal(pathName: string) {
        this.dataRef
            .child(pathName)
            .once('value')
            .then((snapshot) => {
                const len = this._.size(snapshot.val()) || 0;
                this.listRef.child(`total_${pathName}`).set(len);
            });
    }

    // updateTotal() {
    //     this.dataRef.child('attendees').once('value', (snapshot) => {
    //         let attendees = this._.size(snapshot.val());
    //         this.listRef.child(`total_attendees`).set(attendees, () => {
    //             this.$log.log('Attendees total was updated to:', attendees);
    //         });
    //     });
    // }

    update(data: any) {
        return new Promise((resolve, reject) => {
            const rawData = this.transformer.prepareData(data);
            if (this.entityPathName === 'sponsors' || this.entityPathName === 'exhibitors') {
                if (data.email_address) {
                    this.getDataByRefWhere(data).then((val: any) => {
                        if (val) {
                            const valId = _.keys(val)[0];
                            if (data.id !== valId) {
                                reject(
                                    `${this.entityPathName === 'sponsors' ? 'sponsor' : 'exhibitor'} with email ${
                                        data.email_address
                                    } is already present in ${
                                        this.entityPathName === 'sponsors' ? 'sponsors' : 'exhibitors'
                                    } list`
                                );
                            } else {
                                this.dataRef
                                    .child(this.entityPathName)
                                    .child(data.id)
                                    .update(rawData, () => {
                                        resolve(Object.assign(data, rawData));
                                    });
                            }
                        } else {
                            this.dataRef
                                .child(this.entityPathName)
                                .child(data.id)
                                .update(rawData, () => {
                                    resolve(Object.assign(data, rawData));
                                });
                        }
                    });
                } else {
                    this.dataRef
                        .child(this.entityPathName)
                        .child(data.id)
                        .update(rawData, () => {
                            resolve(Object.assign(data, rawData));
                        });
                }
            } else {
                this.dataRef
                    .child(this.entityPathName)
                    .child(data.id)
                    .update(rawData, () => {
                        resolve(Object.assign(data, rawData));
                    });
            }
        });
    }

    remove(data: any) {
        return new Promise((resolve, reject) => {
            if (data.id) {
                this.dataRef.child(`/${this.entityPathName}/${data.id}`).set(null, () => {
                    this.updateTotal(this.entityPathName);
                    if (this.entityPathName === 'sponsors' || this.entityPathName === 'exhibitors') {
                        if (data.email_address) {
                            this.removeSponsoreAndExhibitoreEventFromTheRoot(data.email_address).then(() => {
                                resolve(true);
                            });
                        } else {
                            resolve(true);
                        }
                    } else {
                        resolve(true);
                    }
                });
            } else {
                reject();
            }
        });
    }

    removeSponsoreAndExhibitoreEventFromTheRoot(email: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRefWhere(`sponsorsAndExhibitors`, 'email', email).then((val: any) => {
                if (val) {
                    const id = this._.keys(val)[0];
                    this.Firebase.updateByRef(`sponsorsAndExhibitors/${id}/events`, {
                        [this.eventId]: null,
                    }).then(() => {
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            });
        });
    }

    getCategories(itemType: string) {
        return new Promise((resolve) => {
            this.dataRef
                .child(`${itemType}_categories`)
                .orderByChild('category_order')
                .once('value')
                .then((snapshot) => {
                    let categories: any[] = [];
                    const rawData = snapshot.val();
                    if (rawData) {
                        this._.forOwn(rawData, (val, key) => {
                            val.id = key;
                            categories.push(val);
                        });
                    }
                    if (categories.length) {
                        categories = this._.sortBy(categories, ['category_order'], ['asc']);
                    }

                    resolve(categories);
                });
        });
        // return def.promise;
    }

    addCategory(item: Category) {
        return new Promise((resolve) => {
            const categoriesRef = this.dataRef.child(`${this.entityType}_categories`);
            categoriesRef.once('value').then((snapshot) => {
                const order = this._.size(snapshot.val()) || 0;
                const newCatRef = categoriesRef.push();
                newCatRef.set(
                    {
                        name: item.name,
                        category_order: order,
                    },
                    () => {
                        item.category_order = order;
                        item.id = newCatRef.key;
                        resolve(item);
                    }
                );
            });
        });
    }

    updateCategory(item: Category, entityType: string) {
        const data = { name: item.name } as Category;
        if (item.category_order !== undefined) {
            data.category_order = item.category_order;
        }
        return this.dataRef
            .child(`${entityType}_categories`)
            .child(item.id as string)
            .update(data);
    }
}
